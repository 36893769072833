import {
  addWeek,
  calculateAmountOfOrderItems,
  db,
  deadlineFormatter,
  getDayIndexFromSeconds,
  getMondaySeconds,
  getTodayTimestamp,
  getWeekArray,
  getWeekSeconds,
  isOrderIgnored,
  scroll2,
  Timestamp,
  useCollectionListener,
  UseOffers,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  OrderInfo,
  OrderMealplan,
  OrderOrder,
  OrderOrderProduct,
} from "@kanpla/types";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import { isEmpty, orderBy } from "lodash";
import moment, { Moment } from "moment";
import { StringParam, useQueryParam } from "next-query-params";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import ConfirmationBox from "../ConfirmationBox";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import Basket from "./kanplaGo/Basket";
import MealplanMeeting from "./meeting";
import Products from "./Products";
import Receipt from "./Receipt";
import { useCategories } from "./useCategories";
import useOrderInfo from "./useOrderInfo";
import NewReceipt from "./kanplaGo/receipt/index";

export interface SelectedCategoryData {
  index: number;
  trusted?: boolean;
}

const ContextState = () => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    schoolId,
    setDate,
    setTimeNavigation,
    moduleId,
    module,
    week,
    dayIndex,
    childId,
    userId,
    dateSeconds,
    activePlugins,
    setInnerAppLoading,
    setDayIndex,
    setWeek,
    child,
  } = useContainer(AppContext);
  const today = getTodayTimestamp({ Timestamp });

  // KanplaGo receipt
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState<OrderOrder>({});
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  /** The initial index is null to not trigger any scroll while entering the page. */
  const [selectedCategoryData, setSelectedCategoryData] =
    useState<SelectedCategoryData>({ index: null });

  const [receiptTime, setReceiptTime] = useState<number>(moment().unix());

  const hasKanplaGo = module?.plugins?.kanplaGo?.active;
  const requiresBillingGo = hasKanplaGo && module?.paymentMethod === "billing";
  const hasPayPerOrder = module?.plugins?.payPerOrder?.active;
  const requiresCredit =
    module?.paymentMethod === "credit" && !hasKanplaGo && !hasPayPerOrder;

  // Show or hide day switch on kanpla Go
  useEffect(() => {
    // if (meetingUI) return;
    setTimeNavigation(hasKanplaGo ? "none" : "todaySwitch");
  }, [hasKanplaGo, moduleId]);

  // Correct time on Kanpla Go
  useEffect(() => {
    const todayDate = getTodayTimestamp({ Timestamp });
    const alreadySet = todayDate.seconds === dateSeconds;
    if (hasKanplaGo && !alreadySet) setDate(todayDate);
  }, [hasKanplaGo, dateSeconds]);

  const allDateSeconds = [dateSeconds];
  const { items, deadlineInfo, holidayDates, mealOptions } = UseOffers({
    schoolId,
    moduleId,
    allDateSeconds,
    groupName: child?.groupName,
    setLoading: setInnerAppLoading,
  });

  const oldDefaultDate = deadlineInfo?.defaultDate;

  // Deadline Jump
  useDeadlineJump({
    defaultDate: oldDefaultDate,
  });

  const noMealplan = items?.length === 0;

  const thisWeek = getWeekArray(
    getMondaySeconds(week?.[dayIndex]?.seconds),
    Timestamp
  );
  const nextWeek = addWeek(thisWeek);

  const futureWeeks = [...thisWeek, ...nextWeek].map((day) => day.seconds);

  const { items: futureItems } = UseOffers({
    schoolId,
    moduleId,
    groupName: child?.groupName,
    allDateSeconds: noMealplan ? futureWeeks : [],
    setLoading: setInnerAppLoading,
  });

  const futureDates = futureItems
    ?.map((item) => parseInt(Object.keys(item.dates) + ""))
    .flat();

  const nextAvailableDate = futureDates
    .sort()
    .find((date) => date >= week?.[dayIndex]?.seconds);

  /** Used as the next closest date */
  const defaultDate = noMealplan
    ? Timestamp.fromMillis(nextAvailableDate * 1000)
    : today;

  // Required product
  const hasRequiredProduct =
    module?.plugins?.requiredProduct?.active &&
    items?.some(
      (p: CombinedOfferItem) =>
        p.productId === module?.plugins?.requiredProduct?.productId
    );

  // Order
  const [allOrders = []] = useCollectionListener<OrderMealplan>(
    childId && userId && moduleId && week[dayIndex]
      ? db
          .collection("orders")
          .where("childId", "==", childId)
          .where("userId", "==", userId)
          .where("dateSeconds", "==", week[dayIndex]?.seconds)
          .where("moduleId", "==", moduleId)
      : null
  );

  const orders = orderBy(allOrders, "createdAtSeconds").filter(
    (o) => calculateAmountOfOrderItems(o.order) > 0
  );

  const [ignoredOrderIds, setIgnoredOrderIds] = useState<Array<string>>([]);
  const [allowedOrderIds, setAllowedOrderIds] = useState<Array<string> | null>(
    null
  );

  const orderDocument =
    orders.find(
      (o) =>
        !isOrderIgnored({
          ignoredOrderIds,
          allowedOrderIds,
          orderId: o.id,
        }) && calculateAmountOfOrderItems(o.order) > 0
    ) || ({} as OrderMealplan);

  const order = orderDocument.order || ({} as OrderOrder);
  const orderInfo = orderDocument.info || ({} as OrderInfo);

  const numberOfItems = Object.values(order).reduce(
    (a: number, c: OrderOrderProduct) => a + c.amount,
    0
  ) as number;
  const hasOrdered = numberOfItems > 0;

  // Deadline
  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    deadlineInfo;
  const deadlineFormatted = deadlineFormatter({
    date: week[dayIndex],
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });
  const pastDate = (week[dayIndex]?.seconds || 0) < (defaultDate?.seconds || 0);

  const isOrderingForToday = week[dayIndex]?.seconds === today.seconds;
  const hasLeftovers = (items || []).some(
    (product) => product?.isLeftover === true
  );

  // Add option with holidays
  let activeHoliday = holidayDates?.[dateSeconds];

  const { newOrderInfo, setNewOrderInfo } = useOrderInfo({ orderInfo });

  /** This is used to add query params to the URL and trigger opening of a product */
  const [productId, setProductId] = useQueryParam("productId", StringParam);

  const todayDayIndex = getDayIndexFromSeconds(today.seconds);
  const isWeekend = todayDayIndex > 4;
  if (isWeekend && activePlugins.kanplaGo) {
    activeHoliday = {
      name: "Weekend",
      design: {
        title: t("mealplan2:holds"),
        text: t("mealplan2:come-back-monday"),
      },
      // Other props (not needed)
      partnerId: null,
      days: [],
      createdAt: null,
      createdBy: "",
      schoolIds: [schoolId],
      moduleIds: [moduleId],
      id: null,
    };
  }

  const handleDateChange = (date: Moment) => {
    const dateSeconds = date.unix();
    const weekSeconds = getWeekSeconds(dateSeconds);
    const weekArray = getWeekArray(weekSeconds, Timestamp);
    const dayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    ) as DayIndex;

    // setDate(date);
    setDayIndex(dayIndex);
    setWeek(weekArray);
  };

  return {
    items,
    orderInfo,
    orderDocument,
    orders,
    ignoredOrderIds,
    setIgnoredOrderIds,
    allowedOrderIds,
    setAllowedOrderIds,
    order,
    hasOrdered,
    noMealplan,

    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineFormatted,
    defaultDate,
    pastDate,

    isOrderingForToday,
    module,
    moduleId,
    requiresCredit,
    hasKanplaGo,
    hasRequiredProduct,
    forceUpdate,

    hasPayPerOrder,

    hasLeftovers,
    requiresBillingGo,
    receiptOpen,
    setReceiptOpen,
    checkoutItems,
    setCheckoutItems,

    receiptTime,
    setReceiptTime,
    activeHoliday,
    numberOfItems,
    mealOptions,
    holidayDates,
    newOrderInfo,
    setNewOrderInfo,

    productId,
    setProductId,
    selectedCategoryData,
    setSelectedCategoryData,

    handleDateChange,
  };
};

export const MealplanContext = createContainer(ContextState);

const Mealplan = () => {
  const { module } = useContainer(AppContext);
  const meetingUI = module.flow === "meeting";

  return (
    <MealplanContext.Provider>
      {meetingUI ? <MealplanMeeting /> : <MealplanMealplan />}
    </MealplanContext.Provider>
  );
};

const MealplanMealplan = () => {
  const { basket, innerAppLoading } = useContainer(AppContext);
  const {
    activeHoliday,
    defaultDate,
    hasOrdered,
    module,
    numberOfItems,
    deadlineFormatted,
    hasKanplaGo,
    selectedCategoryData,
    setSelectedCategoryData,
    hasPayPerOrder,
  } = useContainer(MealplanContext);

  const categories = useCategories();

  /**
   * Issue: On switching into pages if the previous page was scrolled,
   * then on a mobile device the current page is being opened already scrolled.
   */
  useEffect(() => {
    if (innerAppLoading) return;

    scroll2({ top: 0 });
  }, [innerAppLoading]);

  return (
    <>
      {!hasPayPerOrder && (
        <NewReceipt
          hideSkipQueue={module?.plugins?.kanplaGo?.hideSkipQueue ?? false}
        />
      )}
      <NavbarSecondary
        deadlineFormatted={deadlineFormatted}
        timeNavigation={hasKanplaGo ? "none" : "daily"}
        selectedCategoryData={selectedCategoryData}
        setSelectedCategoryData={setSelectedCategoryData}
        categories={categories}
      />
      {activeHoliday ? (
        <CanteenClosed
          defaultDate={defaultDate}
          holidayDesc={activeHoliday.design}
        />
      ) : (
        <div className="wrapper mt-2 lg:mt-12">
          <ConfirmationBox
            hasOrdered={hasOrdered}
            numberOfItems={numberOfItems}
            hidden={!isEmpty(basket)}
          >
            <Receipt />
            <div className="md:pt-1">
              <ModuleDescription align="left" module={module} />
            </div>
            <ModuleLoadingWrapper loading={innerAppLoading}>
              <Products />
            </ModuleLoadingWrapper>
          </ConfirmationBox>
          <Basket />
        </div>
      )}
    </>
  );
};

export default Mealplan;
