import { useTranslation } from "react-i18next";
import { useState } from "react";

const ChooseProduct = ({ subsequentProducts, nextStart, setState }) => {
  const { t } = useTranslation(["subscription"]);
  const [selected, setSelected] = useState({});

  const set = (value) => {
    setSelected(value);
    setState(value);
  };

  return (
    <div>
      <p className="text-text-secondary text-sm text-center -mt-1 mb-2">
        {t("subscription:change-effective-on", { value: nextStart })}
      </p>
      {subsequentProducts.map((product) => (
        <label
          className={`block py-2 px-3 my-1 rounded cursor-pointer ${
            selected["id"] === product.id && "bg-info-light"
          }`}
          key={product.name}
        >
          <input
            type="radio"
            className="form-radio"
            name={product.name}
            value={product.id}
            checked={selected["id"] === product.id}
            onChange={() => set(product)}
          />
          <span className="ml-2">
            {product.name}

            <span className="ml-3 text-text-secondary">
              {product.price - product.subsidies} kr.
            </span>
          </span>
        </label>
      ))}
    </div>
  );
};

export default ChooseProduct;
