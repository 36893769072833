import { deadlineProvider } from "@kanpla/system";
import { SubscriptionPeriod } from "@kanpla/types";
import { DatePanelPicker, ProductSettingsHeader } from "@kanpla/ui";
import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import "react-dates/initialize";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "..";
import { AppContext } from "../../contextProvider";

interface Props {
  periods: Array<SubscriptionPeriod>;
  productId: string;
  startDate: any;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  isChoosingName: boolean;
  setSelectedPeriod: Dispatch<SetStateAction<SubscriptionPeriod>>;
}

const ChooseDate = (props: Props) => {
  const {
    periods,
    productId,
    isChoosingName,
    startDate,
    setStartDate,
    setSelectedPeriod,
  } = props;
  const { t, i18n } = useTranslation(["subscription"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { school } = useContainer(AppContext);
  const { module } = useContext(SubscriptionContext);

  const allAvailablePeriods = periods.filter((period) =>
    period.products.map((p) => p.id).includes(productId)
  );

  const nowSeconds = Date.now() / 1000;
  const { deadline } = deadlineProvider({ module, school });
  const signupSeconds = allAvailablePeriods
    .map((period) =>
      period.signupDates
        .map((d) => d.seconds)
        .filter((s) => s + deadline > nowSeconds)
    )
    .flat()
    .sort((a, b) => a - b);

  const updateStartDate = (seconds: number) => {
    const time = moment.unix(seconds);
    setStartDate(time);
    const targetPeriod = allAvailablePeriods.find((p) =>
      p.signupDates.some((s) => s.seconds === seconds)
    );
    setSelectedPeriod(targetPeriod);
  };

  // Choose first available date by default
  useEffect(() => {
    const firstAvailableDateSeconds = signupSeconds[0];
    if (startDate || !firstAvailableDateSeconds) return;
    updateStartDate(firstAvailableDateSeconds);
  }, [startDate]);

  const validate = (dateTime: Moment) => {
    const utc = moment.utc(dateTime.format("YYYY-MM-DD"));
    const currSeconds = utc.valueOf() / 1000;
    const isSignup = signupSeconds.includes(currSeconds);
    return isSignup ? utc : false;
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="px-2">
        <ProductSettingsHeader
          title={t("subscription:start-date", {
            value: isChoosingName ? "2." : "1.",
          })}
        />
      </div>

      <div className="mx-auto my-8">
        <DatePanelPicker
          value={startDate}
          onChange={(dateTime: Moment) => {
            // Validate time
            const time = validate(dateTime);
            if (!time) return;
            const seconds = time.unix();
            updateStartDate(seconds);
          }}
          disabledDate={(dateTime) => {
            const valid = validate(dateTime);
            return !valid;
          }}
        />
      </div>
    </div>
  );
};

export default ChooseDate;
