import { fb } from '../../../libs/system/src/firebase.config';
import axios, { ResponseType } from 'axios';
import moment from 'moment';
import { ExportOrders, ExportOrdersResponse } from '@kanpla/types';
import { downloadBlob } from '@kanpla/system';

/**
 * Exports orders using given `configs` configuration argument.
 *
 * * Returns formatted JSON if the `configs.returnType` is `json`.
 * * Downloads excel file if `configs.returnType` is 'xls' and returns `void`.
 */
export const exportOrders = async (
  configs: ExportOrders,
): Promise<ExportOrdersResponse | void> => {
  // const url = "http://localhost:5001/kanpla-87be3/europe-west1/orders-exportOrders";
  const url = "https://europe-west1-kanpla-87be3.cloudfunctions.net/orders-exportOrders";
  const { returnType } = configs;

  const token: string = await fb.auth().currentUser.getIdToken(false);

  const responseType: ResponseType = returnType === 'json' && 'json'
    || returnType === 'xls' && 'blob'
    || 'json';

  const res = await axios({
    method: "POST",
    url,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    responseType,
    data: configs,
  });

  if (configs.returnType === 'xls') {
    const responseData = typeof res.data === "object"
      ? res.data
      : Buffer.from(res.data, "utf-8");

    const file: Blob = new Blob([responseData], {
      type: "application/octet-stream",
    });

    const fileName = `export-orders-report-${moment
      .unix(configs.fromSeconds)
      .format("DD.MM.YYYY")}-${moment
      .unix(configs.toSeconds)
      .format("DD.MM.YYYY")}`;

    downloadBlob(file, `${fileName}.xlsx`);
    return;
  }

  // Return JSON by default
  return res.data as ExportOrdersResponse;
};
