import { OrderOrder } from "@kanpla/types";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import React from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";

interface Props {
  children?: JSX.Element; // Settings modal
  text?: string;
  setOpen: (open: boolean) => void;
  full?: boolean;
}

const StandardOrderButton = ({ children = null, setOpen, full }: Props) => {
  const { t } = useTranslation(["translation"]);
  const { standard } = useContainer(FlexContext);
  const hasStandard = Object.values(standard || {}).some(
    (dayStandard: OrderOrder) => !isEmpty(dayStandard)
  );

  const text = hasStandard
    ? t("translation:edit-default-selection")
    : t("translation:set-default-selection");

  return (
    <div className={full ? `w-full` : `pt-8 flex justify-center`}>
      <div className={full ? "w-full" : "max-w-sm w-full"}>
        <div
          className={`flex flex-col items-center justify-center ${
            full ? "" : "pt-6"
          }`}
        >
          <Button size="small" onClick={() => setOpen(true)}>
            {text}
          </Button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default StandardOrderButton;
