import { db, callFunction } from "@kanpla/system";
import { message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChildForm from "./childForm";
import { Child } from "@kanpla/types";

const AddChild = ({ callback = () => null }) => {
  const { t, i18n } = useTranslation(["forms", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { isChildSalesplace, children } = useContainer(AppContext);

  const submit = async (data) => {
    try {
      const child: Child = {
        ...data,
        createdAt: moment().toDate(),
      };

      // Create child
      const childDocument = await callFunction("signup-createChild", {
        name: child.name,
        schoolId: child.schoolId,
        selectors: child.selectors,
        userId: child.userId,
        codes: [],
        isChildSalesplace,
        scope: child.scope,
      });

      if (childDocument) {
        if (typeof callback === "function") callback();
      }

      message.success(t("forms:message.success.additional-user-created"));
    } catch (e) {
      console.error(e);
      message.error(
        e?.message || t("translation:message.error.could-not-create-user")
      );
    }
  };

  return <ChildForm submit={submit} />;
};

export default AddChild;
