import React, { Dispatch, SetStateAction } from "react";
import { Plugins } from "@kanpla/types";
import {
  InvoiceReference,
  ProductSettingsHeader,
  RequiredProduct,
  TextInput,
  TimeSelect,
} from "@kanpla/ui";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Space,
  FormInstance,
  Button,
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import { isEmpty } from "lodash";
import {
  faAddressCard,
  faTruckClock,
} from "@fortawesome/pro-duotone-svg-icons";

interface DefaultProps {
  withIndividualTimeEdit?: boolean;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  initialValues: any;
}

interface PropsWithIndividualTimeEdit extends DefaultProps {
  withIndividualTimeEdit: true;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

type Props = PropsWithIndividualTimeEdit | DefaultProps;

const OrderInfoFields = ({
  withIndividualTimeEdit = false,
  setEditMode,
  form,
  initialValues = {},
}: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "libs"]);

  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });
  const { module, hasRequiredProduct, mealOptions } =
    useContainer(MealplanContext);

  const {
    defaultReference,
    schoolId,
    basket,
    setBasket,
    week,
    dayIndex,
    activePlugins,
  } = useContainer(AppContext);
  const plugins = module?.plugins || ({} as Plugins.Array);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        ...initialValues,
        timeInput:
          activePlugins?.timeInput &&
          (initialValues?.timeInput || plugins?.timeInput?.startAt),
      }}
    >
      <Space direction="vertical">
        {module?.flow === "meeting" && (
          <Space align="baseline">
            <div className="">
              <ProductSettingsHeader title={t("mealplan2:name-meeting")} noBg />
              <Form.Item name="name">
                <Input
                  className="mt-1"
                  placeholder={t("mealplan2:name-meeting")}
                />
              </Form.Item>
            </div>
            <div>
              <ProductSettingsHeader title={t("mealplan2:meeting-date")} noBg />
              <DatePicker
                className="mt-1"
                placeholder={t("mealplan2:meeting-date")}
                disabled={true}
                allowClear={false}
                value={moment(week[dayIndex].toDate())}
                format={(m) => m.format("ll")}
              />
            </div>
          </Space>
        )}
        <div>
          {activePlugins.timeInput && (
            <Form.Item
              name="timeInput"
              label={
                <ProductSettingsHeader
                  className="w-full"
                  title={
                    plugins?.timeInput?.fieldName || t("libs:delivery-time")
                  }
                  subtitle={
                    plugins?.timeInput?.fieldDescription ||
                    t("libs:desired-delivery-time")
                  }
                  icon={faTruckClock}
                  noBg
                />
              }
              rules={[
                {
                  required: true,
                  type: "number",
                  message: isEmpty(i18n)
                    ? "Dette felt er påkrævet"
                    : t("libs:specific-field-required", {
                        fieldName: t("libs:delivery-time"),
                      }),
                },
              ]}
            >
              {/* @ts-ignore */}
              <TimeSelect
                interval={plugins?.timeInput?.interval}
                endAt={plugins?.timeInput?.endAt}
                startAt={plugins?.timeInput?.startAt}
              />
            </Form.Item>
          )}
          {withIndividualTimeEdit && setEditMode && (
            <Button
              onClick={() => setEditMode((mode) => !mode)}
              className="whitespace-normal h-auto"
            >
              {t("mealplan2:edit-delivery-time-for-each-item")}
            </Button>
          )}
        </div>
        {activePlugins.invoiceReference && (
          <Form.Item
            label={
              <ProductSettingsHeader
                title={
                  isEmpty(i18n) ? "Reference til firmakøb" : t("libs:reference")
                }
                subtitle={
                  isEmpty(i18n)
                    ? "Du kan tilføje en reference (f. eks. dit personale ID) til bogholderiet."
                    : t("libs:add-reference")
                }
                icon={faAddressCard}
                noBg
              />
            }
            name="reference"
            rules={[
              {
                required: true,
                message: isEmpty(i18n)
                  ? "Dette felt er påkrævet"
                  : t("libs:specific-field-required", {
                      fieldName: "Reference",
                    }),
              },
            ]}
          >
            <InvoiceReference
              noBg
              module={module}
              defaultReference={defaultReference}
            />
          </Form.Item>
        )}

        {activePlugins.textInput && (
          <TextInput
            noBg
            orderInfo={initialValues}
            settings={plugins?.textInput}
            schoolId={schoolId}
          />
        )}

        {hasRequiredProduct && (
          <RequiredProduct
            settings={plugins?.requiredProduct}
            requiredProductOrder={basket}
            setRequiredProductOrder={(requiredOrder) =>
              setBasket({ ...basket, ...requiredOrder })
            }
            mealOptions={mealOptions}
            module={module}
            order={basket}
          />
        )}

        {activePlugins.orderForAmountOfPeople && (
          <div className="mx-2">
            <ProductSettingsHeader
              title={t("mealplan2:number-of-people")}
              noBg
              // subtitle=""
            />
            <Form.Item name="numberOfPeople" rules={[{ type: "number" }]}>
              <InputNumber
                pattern="[0-9]*"
                inputMode="numeric"
                required={true}
              />
            </Form.Item>
          </div>
        )}
      </Space>
    </Form>
  );
};

export default OrderInfoFields;
