import { faCartCirclePlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateOrderTotal, callFunction, mergeOrders } from "@kanpla/system";
import {
  ActionsAuthenticationModal,
  DataAuthenticationModal,
  Order,
  PaymentProvider,
} from "@kanpla/types";
import {
  ConfirmAnimation,
  DrawerOrModal,
  LottieShoppingCart,
  Modal,
} from "@kanpla/ui";
import { Button, message, Space } from "antd";
import moment from "moment";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../";
import kanplaGoPayment from "../../../lib/payment/kanplaGoPayment";
import useChargeSession from "../../../lib/payment/UseChargeSession";
import { AppContext } from "../../contextProvider";
import useWindowPayment from "../useWindowPayment";
import OrderInfo from "./BasketElements/OrderInfo";
import Payment from "./BasketElements/Payment";
import PriceOverview from "./BasketElements/PriceOverview";
import BasketPreview from "./BasketPreview";
import Slider from "./Slider";
import { BasketList } from "../BasketList";
import { setWith } from "lodash";
import { useForm } from "antd/lib/form/Form";
import AnonymousBasket from "../../anonymous/AnonymousBasket";

const BasketBottomSheet = () => {
  const [form] = useForm();
  const { t, i18n } = useTranslation([
    "mealplan2",
    "translation",
    "modals",
    "payment",
    "anonymous",
  ]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    moduleId,
    setReceiptOpen,
    setCheckoutItems,
    requiresBillingGo,
    module,
    hasKanplaGo,
    setReceiptTime,
    newOrderInfo,
    setNewOrderInfo,
    orderDocument,
  } = useContainer(MealplanContext);
  const {
    card,
    balance,
    week,
    dayIndex,
    basket,
    openBasket,
    setOpenBasket,
    childId,
    schoolId,
    mobile,
    activePlugins,
    setBasket,
    auth,
    setOpenAuthenticationModal,
    setDataAuthenticationModal,
    posoneBalance,
  } = useContainer(AppContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [basketEditMode, setBasketEditMode] = useState(false);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  lottieRef?.current?.setSpeed(0.85);
  const drawerRef = useRef<HTMLDivElement>(null);

  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useLocalstorageState<PaymentProvider>("last-payment-method", "card");

  const [amountShownTakeFirstBanner, setAmountShownTakeFirstBanner] =
    useLocalstorageState("amount-shown-take-first-banner", 0);

  // used to keep track of which products have been selected to be removed
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentProvider>(defaultPaymentMethod);
  const [rememberCard, setRememberCard] = useState<boolean>(true);

  const reset = useCallback(() => {
    /**
     * Rather than to set the basket to a plain object,
     * we will set the basket to the current session state
     */
    setBasket(basket);
    setBasketEditMode(false);
  }, []);

  // Reepay window handler
  const { callbackUrl } = useWindowPayment({
    mode: "order",
    setReceiptOpen,
    setReceiptTime,
    setCheckoutItems,
    callback: () => {
      setBasket({});
      if (!hasKanplaGo) {
        setConfirmationModalOpen(true);
        setTimeout(() => setConfirmationModalOpen(false), 3000);
      }
    },
  });

  useEffect(() => {
    reset();
  }, [moduleId, week[dayIndex]?.seconds]);

  const handleBasketOpening = useCallback(
    (open: boolean) => {
      setOpenBasket(open);
      setBasketEditMode(false);
    },
    [setBasketEditMode, setOpenBasket]
  );

  const handleEventFromWebView = (e) => {
    const { isApplePayCanceledByUser } = JSON.parse(e.data);
    if (isApplePayCanceledByUser) {
      setLoading(false);
      message.warning(t("mealplan2:message.warning.payment-canceled"));
    }
    window.removeEventListener("message", handleEventFromWebView);
  };

  // Open receipt ticket
  const openConfirmation = () => {
    message.success(t("translation:message.success.payment-successful"));
    if (hasKanplaGo) {
      setReceiptOpen(true);
      setReceiptTime(moment().unix());
      setCheckoutItems(basket);
    }

    if (!hasKanplaGo) {
      setConfirmationModalOpen(true);
      setTimeout(() => setConfirmationModalOpen(false), 3000);
    }
    reset();
  };

  const isCredit = module.paymentMethod === "credit";
  const isPosone = module.paymentMethod === "posOne";

  const isOnlyPrepaid = !activePlugins?.kanplaGo && !activePlugins?.payPerOrder;

  const onlyCredit = isCredit && isOnlyPrepaid;
  const onlyPosoneCredit = isPosone && isOnlyPrepaid;

  // submit order
  const finishOrder = async (orderInfo: Order["info"]) => {
    try {
      // Merge when buying from only mealplan (pre-ordering)
      const orderingFunction =
        onlyCredit || onlyPosoneCredit
          ? "ordering-submitOrder"
          : "ordering-submitNewOrder";

      await callFunction(orderingFunction, {
        dateSeconds: week[dayIndex].seconds,
        childId,
        order: onlyCredit
          ? mergeOrders([orderDocument?.order || {}, basket || {}])
          : basket,
        info: orderInfo,
        moduleId,
        paymentProvider: selectedPaymentMethod,
        allowedOrderIds: onlyCredit ? [orderDocument?.id] : [],
      });

      setNewOrderInfo(null);
      // Open receipt ticket
      openConfirmation();
      handleBasketOpening(false);
      setBasket(null);
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  // On successful one time payment
  const { loadChargeSession } = useChargeSession({
    open: openBasket,
    onSuccess: () => {
      openConfirmation();
    },
    setLoading,
    rememberCard,
    onError: (error) => message.error(error.error),
  });

  // total amount of products
  const amountProducts: number = Object.values(basket || {}).reduce(
    (acc: number, curr: { amount: number }) => {
      acc += curr.amount;
      return acc;
    },
    0
  );

  const basketPrice = calculateOrderTotal(basket);
  const hasMoney = balance >= basketPrice || false;
  const payWithSavedCard = !!card && selectedPaymentMethod === "card";

  // submit order
  const onPurchase = async () => {
    try {
      const data = await form.validateFields();

      const newData = Object.entries(data).reduce((acc, [path, value]) => {
        if (!value) return acc;

        const newAcc = setWith(acc, path, value, Object);
        return newAcc;
      }, {});

      setNewOrderInfo(newData);

      setLoading(true);
      setAmountShownTakeFirstBanner(amountShownTakeFirstBanner + 1);

      form.resetFields();

      if (
        !hasMoney &&
        !payWithSavedCard &&
        !requiresBillingGo &&
        !onlyPosoneCredit
      ) {
        const missingMoney = basketPrice - balance;
        const calculatePrice = missingMoney < 0 ? basketPrice : missingMoney;

        const isWindow = true;

        try {
          const dateSeconds = week[dayIndex]?.seconds;

          const sessionId = await kanplaGoPayment({
            amount: calculatePrice,
            isRecurring:
              selectedPaymentMethod === "card" ? rememberCard : false,
            paymentMethod: selectedPaymentMethod,
            order: basket,
            orderInfo: newData,
            callbackUrl,
            schoolId,
            moduleId,
            childId,
            dateSeconds,
          });

          setDefaultPaymentMethod(selectedPaymentMethod);

          await loadChargeSession(
            sessionId,
            isWindow,
            selectedPaymentMethod === "mobilepay"
          );

          if (isWindow && window?.["isRenderedInWebView"]) {
            window.addEventListener("message", handleEventFromWebView);
          }
        } catch (err) {
          console.error(err);
        } finally {
          !isWindow && setLoading(false);
        }
      } else {
        if (onlyPosoneCredit && posoneBalance < basketPrice)
          throw new Error(t("translation:not-enough-credit"));
        finishOrder(newData);
      }
    } catch (e) {
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors?.join(", ") || e?.message);
      console.log(e);
      setLoading(false);
    }
  };

  const showOrderInfo =
    module.flow === "meeting" ||
    activePlugins.timeInput ||
    activePlugins.textInput ||
    activePlugins.orderForAmountOfPeople ||
    activePlugins.reference;

  const canProcessPayment =
    activePlugins?.kanplaGo || activePlugins?.payPerOrder;

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal
  ) => {
    setDataAuthenticationModal(() => {
      const newState: DataAuthenticationModal = {
        title,
        subtitle,
        action,
      };
      return newState;
    });
    setOpenAuthenticationModal(true);
  };

  const authenticatedActions = [
    !requiresBillingGo && canProcessPayment && mobile ? (
      <div className="w-full">
        <Slider
          submit={onPurchase}
          checkoutLoading={loading}
          label={t("mealplan2:swipe-to-pay")}
          disabled={!selectedPaymentMethod}
        />
      </div>
    ) : (
      {
        label: requiresBillingGo
          ? t("translation:approve")
          : canProcessPayment && basketPrice > balance
          ? t("payment:pay-and-checkout", {
              price: basketPrice - balance,
            })
          : t("payment:checkout"),
        onClick: onPurchase,
        type: "primary",
        loading: loading,
        disabled:
          (onlyCredit && basketPrice > balance) ||
          (onlyPosoneCredit && basketPrice > posoneBalance),
      }
    ),
  ];

  const unauthenticatedActions = [
    !auth.user && {
      label: t("translation:log-in"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:welcome-back"),
          t("anonymous:modal.subtitles.info-authenticate-login"),
          "login"
        );
      },
      type: "primary",
      dataCy: "login-anonymous",
    },
    {
      label: t("translation:sign-up"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:sign-up"),
          t("anonymous:modal.subtitles.info-authenticate-signup"),
          "signup"
        );
      },
      type: "ghost",
      dataCy: "signup-anonymous",
    },
  ];

  return (
    <>
      <div className="hidden">{!!basket}</div>
      <div className="w-full md:w-1/3">
        {mobile && (
          <BasketPreview
            totalPrice={basketPrice}
            totalProducts={amountProducts}
            onClick={() => handleBasketOpening(true)}
          />
        )}
        <DrawerOrModal
          open={openBasket}
          setOpen={handleBasketOpening}
          title={mobile ? null : t("mealplan2:complete-the-order")}
          zIndex={40}
          noPadding={mobile}
          // @ts-ignore
          actions={auth?.user ? authenticatedActions : unauthenticatedActions}
        >
          {auth?.user ? (
            <div
              className="px-6 md:px-4 lg:px-0 md:pb-4 text-text-primary select-none"
              ref={drawerRef}
            >
              <Space direction="vertical" size="large" className="w-full">
                {amountShownTakeFirstBanner < 4 &&
                  hasKanplaGo &&
                  !module.plugins.kanplaGo?.hidePickProducts && (
                    <div className="bg-yellow-50 text-yellow-900 border border-yellow-600 rounded-lg p-2 px-3 flex items-center mb-3">
                      <div className="w-8">
                        <Lottie
                          animationData={LottieShoppingCart}
                          lottieRef={lottieRef}
                          style={{ height: "1.5rem" }}
                        />
                      </div>
                      <div className="w-full flex justify-center">
                        <p className="font-semibold">
                          {t("mealplan2:remember-to-take-the-item")}
                        </p>
                      </div>
                    </div>
                  )}
                <BasketList
                  editMode={basketEditMode}
                  setEditMode={setBasketEditMode}
                />

                <Button
                  type="dashed"
                  className="w-full"
                  onClick={() => setOpenBasket(false)}
                >
                  <Space>
                    <FontAwesomeIcon icon={faCartCirclePlus} />
                    {t("mealplan2:add-more-products")}
                  </Space>
                </Button>

                {/* Order info */}
                <OrderInfo
                  form={form}
                  showOrderInfo={showOrderInfo}
                  newOrderInfo={newOrderInfo}
                />

                {/* Price overview */}
                <PriceOverview
                  basketPrice={basketPrice}
                  isCredit={isCredit}
                  hasMoney={hasMoney}
                />

                {/* Payment */}
                <Payment
                  setRememberCard={setRememberCard}
                  rememberCard={rememberCard}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  selectedPaymentMethod={selectedPaymentMethod}
                  basketPrice={basketPrice}
                  hasMoney={hasMoney}
                  requiresBillingGo={requiresBillingGo}
                />
              </Space>
            </div>
          ) : (
            <AnonymousBasket
              amountShownTakeFirstBanner={amountShownTakeFirstBanner as any}
              hasKanplaGo={hasKanplaGo}
              lottieRef={lottieRef}
              basketEditMode={basketEditMode}
              setBasketEditMode={setBasketEditMode}
            />
          )}
        </DrawerOrModal>
      </div>
      {!hasKanplaGo && (
        <Modal
          className="h-96 w-96"
          zMax
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          disableLock
        >
          <ConfirmAnimation mobile={mobile} out={confirmationModalOpen} />
        </Modal>
      )}
    </>
  );
};
export default BasketBottomSheet;
