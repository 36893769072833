import { Image } from "@kanpla/ui";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useBoundingclientrectRef } from "rooks";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
}

const BannerFive = ({ title, imageUrl, subTitle }: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-auto rounded-lg flex flex-col overflow-hidden relative shadow-lg`}
      style={{ minHeight: 192 }}
    >
      <div
        className={`overflow-hidden ${getProportionalHeightPadding(
          width,
          "height",
          true
        )}`}
      >
        <Image
          src={imageUrl}
          alt={title}
          className="relative h-full w-full object-center object-cover"
        />
      </div>
      <div className="flex flex-col p-2 px-3 bg-background-secondary">
        {title && (
          <h3
            className={`h500 text-left ${getProportionalFontSize(
              width,
              "text-lg"
            )}`}
          >
            {title}
          </h3>
        )}
        {subTitle && (
          <p
            className={`text-left text-sm ${getProportionalFontSize(
              width,
              "text-sm"
            )}`}
          >
            {ReactHtmlParser(subTitle)}
          </p>
        )}
      </div>
    </div>
  );
};

export default BannerFive;
