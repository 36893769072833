import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
} from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import OrderOptions from "./OrderOptions";

interface Props {
  orders: OrderMealplan[];
}

const Table = ({ orders }: Props) => {
  const isToday = true;

  return (
    <div className="w-full relative">
      {/* <div className="absolute top-0 left-0 w-full border-t-2 border-primary-main">
        <div className="w-min py-1 px-4 bg-primary-main text-background-primary uppercase rounded-br-lg text-sm whitespace-nowrap">
          i dag
        </div>
      </div> */}
      {orders.map((order, index) => (
        <TableRow order={order} key={order.id} index={index} />
      ))}
    </div>
  );
};

export const TableRow = ({ order, index }) => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const amount = calculateAmountOfOrderItems(order.order);
  const totalPrice = calculateOrderTotal(order.order);
  const time = order?.info?.timeInput;
  const dateMoment = moment.unix(order.dateSeconds);
  const formatDay = dateMoment.format("dddd D. M. Y");
  const orderName =
    order?.info?.name ||
    t("mealplan2:order-to-d", { value: dateMoment.format("D/M Y") });

  return (
    <div className="w-full py-6 px-8 md:px-14 bg-background-primary rounded-lg my-1 border border-divider-main">
      <div className="w-full flex-wrap max-w-6xl mx-auto flex justify-start lg:text-lg text-text-primary">
        <span className="w-full lg:w-3/12">{formatDay}</span>
        <div className="flex items-center w-full lg:w-5/12 pb-4 lg:pb-0">
          <span className="font-semibold">{orderName}</span>
          {time && (
            <span>, {moment.utc(time * 1000 || 0).format("HH:mm")}</span>
          )}
        </div>
        <div className="flex items-center w-1/4 lg:w-1/12">
          <b>{amount}</b>
          <FontAwesomeIcon icon={faShoppingBag} className="ml-2 opacity-70" />
        </div>
        <span className=" w-1/4 lg:w-1/12">{totalPrice} kr.</span>
        <div className=" w-2/4 lg:w-2/12 flex justify-end">
          <OrderOptions order={order} />
        </div>
      </div>
    </div>
  );
};

export default Table;
