import { UseOffers } from "@kanpla/system";
import React, { useEffect } from "react";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ContextState = () => {
  const {
    module,
    setTimeNavigation,
    setInnerAppLoading,
    schoolId,
    moduleId,
    child,
    week,
  } = useContainer(AppContext);

  useEffect(() => {
    setTimeNavigation("calendarWeekly");
  }, []);

  // Offers
  const allDateSeconds = week.map((d) => d.seconds);
  const { items, holidayDates, deadlineInfo } = UseOffers({
    schoolId,
    moduleId,
    allDateSeconds,
    setLoading: setInnerAppLoading,
    groupName: child?.groupName,
  });

  const { defaultDate } = deadlineInfo || {};

  const hasHolidaysAllWeek = !allDateSeconds.some(
    (dateSeconds) => !holidayDates?.[dateSeconds]
  );
  const activeHoliday = hasHolidaysAllWeek
    ? holidayDates?.[allDateSeconds[0]]
    : null;

  const highlightedProductIds = Object.entries(module.products || {})
    .filter(([, value]) => value?.highlighted)
    .map(([productId]) => productId);

  // Show highlighted products, or default to the first one
  const highlightedItems: Array<CombinedOfferItem> =
    highlightedProductIds.length
      ? items.filter((p) => highlightedProductIds.includes(p.productId))
      : items.filter((p, i) => i < 1);

  return { items, highlightedItems, defaultDate, activeHoliday };
};

export const MenuPreviewContext = createContainer(ContextState);

export const MenuPreviewContainer = ({ children }) => {
  return <MenuPreviewContext.Provider>{children}</MenuPreviewContext.Provider>;
};
