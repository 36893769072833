import { faShoppingBasket } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOrderConfigs } from "@kanpla/system";
import { OrderOrder } from "@kanpla/types";
import { Badge } from "@kanpla/ui";
import { Button, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorage } from "rooks";

interface Props {
  basket: OrderOrder;
  onClick: () => void;
  className: string;
}

interface ButtonProps {
  amountProducts: number;
  onClick: () => void;
}

const BasketButton = ({ amountProducts, onClick }: ButtonProps) => {
  const { t } = useTranslation(["design"]);

  return (
    <Badge count={amountProducts}>
      <Button type="primary" onClick={onClick} data-cy="basket-btn">
        <FontAwesomeIcon icon={faShoppingBasket} className="mr-2" />
        {t("design:basket")}
      </Button>
    </Badge>
  );
};

const BasketDisplay = (props: Props) => {
  const { basket, onClick } = props;
  const itemsCount = getOrderConfigs(basket).length;
  const { t } = useTranslation(["mealplan2"]);

  const [hasSeenButtonBefore, setHasSeenButtonBefore] =
    useLocalstorage("seen-basket-button");

  if (itemsCount === 0) return null;

  const amountProducts: number = Object.values(basket).reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  if (!hasSeenButtonBefore || hasSeenButtonBefore === "undefined")
    return (
      <Tooltip
        visible
        title={t("mealplan2:buttons.add-to-basket-help")}
        placement="bottomRight"
        overlayStyle={{
          transform: "translateX(3px) translateY(-10px)",
        }}
      >
        <BasketButton
          amountProducts={amountProducts}
          onClick={() => {
            setHasSeenButtonBefore(true);
            onClick();
          }}
        />
      </Tooltip>
    );

  return <BasketButton amountProducts={amountProducts} onClick={onClick} />;
};

export default BasketDisplay;
