import { faPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Child } from "@kanpla/types";
import { Button, message, Space } from "antd";
import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import OrderButton from "./OrderButton";
import { getModulesByChildSchoolId, sortModules } from "@kanpla/system";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import EditKid from "../modals/EditKid";
import useBasketPreventClick from "../BasketPreventClick";

interface EditProps {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  selectedChild: boolean;
  child: Child;
  EditPopup?: (data: EditProps) => JSX.Element;
}

const ChildActions = ({ selectedChild, child, EditPopup = EditKid }: Props) => {
  const { t } = useTranslation(["translation", "settings"]);
  const { setChildId, modules } = useContainer(AppContext);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const confirm = useBasketPreventClick({});

  const orderingModule = modules.find(
    (m) => m.type === "mealplan" || m.type === "flex" || m.type === "homescreen"
  );
  const subscriptionModule = modules.find((m) => m.type === "subscription");

  return (
    <Space className="flex text-sm">
      {selectedChild ? (
        <>
          <OrderButton
            orderingModule={orderingModule}
            subscriptionModule={subscriptionModule}
            child={child}
          />
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPen} className="mr-2" />
            {t("translation:edit")}
          </Button>
        </>
      ) : (
        <Button
          type="primary"
          onClick={async () => {
            try {
              await confirm();
              setChildId(child.id);
              const modules = await getModulesByChildSchoolId(child);
              const sortedModules = sortModules(modules);
              const redirectToUrl = constructNewUrl(
                child.schoolId,
                sortedModules?.[0]?.id
              );
              router.push(redirectToUrl);
            } catch (e) {
              console.error(e);
              message.error(e?.message);
            }
          }}
        >
          {t("settings:switch-to")}
        </Button>
      )}
      <EditPopup open={open} setOpen={setOpen} child={child} />
    </Space>
  );
};

export default ChildActions;
