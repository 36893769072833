import { selectorsHasOnlyChoice, validateSelectors } from "@kanpla/system";
import { ChildSelector, School, Selector } from "@kanpla/types";
import { ButtonWithLoading, Modal, SelectorsInput } from "@kanpla/ui";
import { Form, message } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const AddClassInfo = () => {
  const { t } = useTranslation(["translation", "modals"]);
  const { child, school } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectors, setSelectors] = useState<ChildSelector>();
  const [selectorText, setSelectorText] = useState("");

  const trigger = (child?.userId || "") + JSON.stringify(school || {});

  const setOnlyChoiceSelector = async (onlyChoice) => {
    await (child.ref || child.child_ref).update({
      selectors: onlyChoice,
      approveSelectors: null,
    });
    setOpen(false);
  };

  useEffect(() => {
    if (!child || !school) return;
    const noSelectors = !school.selectors || school.selectors.length === 0;
    const notRightSchool = child.school_ref?.id !== school?.ref?.id;
    if (noSelectors || notRightSchool) return;

    // const noOld = typeof child.grade !== `number` || !child.classification;
    const noNew =
      isEmpty(child.selectors || {}) ||
      !validateSelectors(child.selectors, school.selectors);

    if (noNew) {
      // If there's only one choice to choose from, automatically assign it
      const onlyChoice = selectorsHasOnlyChoice({ school });
      if (onlyChoice) {
        setOnlyChoiceSelector(onlyChoice);
      } else {
        setOpen(true);
      }
    }
    if (child.approveSelectors) setOpen(true);

    const s: School = school;
    const sels = s?.selectors;
    const allSels = sels.filter((c, i) => i !== sels.length - 1);
    const lastSel = sels?.length > 1 && sels[sels.length - 1];

    setSelectors(child.selectors);

    setSelectorText(
      `${allSels?.map((s: Selector) => s.name.toLowerCase()).join(", ")}${
        lastSel ? ` og ${lastSel.name.toLowerCase()}` : ""
      }`
    );
  }, [trigger]);

  if (!child) return null;

  const submit = async (e = null) => {
    e?.preventDefault?.();
    setLoading(true);

    try {
      const validSelector = validateSelectors(selectors, school.selectors);
      if (!validSelector) throw new Error(t("modals:fill-fields"));

      await (child.ref || child.child_ref).update({
        selectors,
        approveSelectors: null,
      });
      message.success(t("modals:message.success.info-about-user"));
      setLoading(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
      message.error(e.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        message.info(t("modals:message.info.please-add-info-about-user"));
      }}
      title={
        child.approveSelectors
          ? t("translation:welcome-back")
          : t("modals:hi", { value: child.name })
      }
    >
      {child.approveSelectors === "selector_change" && (
        <p className="text-center">
          {t("modals:hi-welcome-back", { childName: child.name, selectorText })}
        </p>
      )}

      {child.approveSelectors === "school_change" && (
        <p className="text-center">
          {t("modals:hi-your-kitchen-has-moved-you", {
            childName: child.name,
            schoolName: child.school_name,
            selectorText,
          })}
        </p>
      )}

      {!child.approveSelectors && (
        <p className="text-center">
          {t("modals:please-state", { value: selectorText })}
        </p>
      )}
      <Form
        className="my-8 m-auto mb-0"
        layout="vertical"
        requiredMark={false}
        onFinish={submit}
        // To catch the submit event on a custom form
        onSubmitCapture={(event) => event.preventDefault()}
      >
        <div className="py-2">
          <SelectorsInput
            selectors={selectors}
            setSelectors={setSelectors}
            school={school}
          />
        </div>
        <Form.Item>
          <ButtonWithLoading
            htmlType="submit"
            loading={loading}
            text={t("translation:confirm")}
            loadingText={t("translation:loading")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClassInfo;
