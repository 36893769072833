/* eslint-disable react-hooks/rules-of-hooks */
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { AllergensDisplay, Labels } from "@kanpla/ui";
import classnames from "classnames";
import { default as React } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  product: CombinedOfferItem;
  dayIndex: DayIndex;
  detailed?: boolean;
}

const Product = ({ product, dayIndex, detailed = false }: Props) => {
  const { customBranding, week } = useContainer(AppContext);
  const dateSeconds = week[dayIndex]?.seconds;
  const menuItem = product.dates?.[dateSeconds]?.menu;

  const shouldHide =
    menuItem?.name === "X" || menuItem?.name === "x" || !menuItem;
  if (shouldHide) return null;

  const productName: string = menuItem?.name || product.name;

  const allergens = {
    ...(menuItem?.allergens || {}),
    ...((product as any)?.allergens || {}),
  };

  const labels = {
    ...((product as any)?.labels || {}),
    ...(menuItem?.labels || {}),
  };

  return (
    <div
      className={classnames([
        detailed && "md:px-6",
        "py-2 inline-block w-full border-divider-main relative",
      ])}
    >
      {detailed && <Labels labels={labels} />}
      <p className="font-semibold text-text-secondary">
        {detailed && productName.trim()}
      </p>
      <p className="text-text-secondary whitespace-pre-line">
        {detailed
          ? menuItem?.description || ""
          : productName || menuItem?.description || ""}
      </p>
      {detailed && (
        <AllergensDisplay
          allergens={allergens}
          hideHeader={true}
          customBranding={customBranding}
        />
      )}
    </div>
  );
};

export default Product;
