import { calculateOrderTotal, callFunction } from "@kanpla/system";
import {
  OrderMealplan,
  OrderOrder,
  OrderInfo as OrderInfoType,
  Plugins,
} from "@kanpla/types";
import { cloneDeep } from "lodash";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BasketList } from "../BasketList";
import { diff } from "just-diff";
import { Button, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { MealplanContext } from "..";
import OrderInfo from "../OrderInfo";
import useSubmit from "../useSubmit";
import classnames from "classnames";
import { ReceiptWrapper } from "@kanpla/ui";
import moment from "moment";

interface Props {
  orderDocument: OrderMealplan;
  ordersAmount?: number;
  index: number;
  editingDisabled?: boolean;
  disabled?: boolean;
  setEditingOrderId: Dispatch<SetStateAction<string>>;
}

const OrderItem: FC<Props> = ({
  orderDocument,
  ordersAmount,
  index,
  editingDisabled,
  disabled,
  setEditingOrderId,
}) => {
  const order = orderDocument.order;
  const orderInfo = orderDocument.info;

  const [isEditing, setIsEditing] = useState(false);
  const [localBasket, setLocalBasket] = useState<OrderOrder>(order);
  const [openEditOrder, setOpenEditOrder] = useState(false);

  const submitOrder = useSubmit();
  const { t } = useTranslation([
    "mealplan2",
    "translation",
    "plugins",
    "flex",
    "plugins",
  ]);
  const { week, dayIndex, isSaving, setIsSaving, childId } =
    useContainer(AppContext);
  const {
    moduleId,
    // orderDocument,
    orders,
    setReceiptTime,
    setReceiptOpen,
    setCheckoutItems,
    module,
    hasOrdered,
    hasKanplaGo,
  } = useContainer(MealplanContext);

  useEffect(() => {
    isEditing && setEditingOrderId(orderDocument.id);
    !isEditing && setEditingOrderId(null);
  }, [isEditing]);

  const orderTotal = calculateOrderTotal(order);
  const basketTotal = calculateOrderTotal(localBasket);

  useEffect(() => {
    setLocalBasket(cloneDeep(order));
  }, [orderTotal]);

  const differences = useMemo(
    () => diff(localBasket, order),
    [basketTotal, orderTotal]
  );

  const needsToBeSaved = differences.length > 0;

  const plugins = module.plugins || ({} as Plugins.Array);
  const hasActivePlugins = Object.values(plugins).some(
    (plugin) => plugin.active
  );

  const submitEditedOrder = async () => {
    try {
      const newOrder = localBasket;
      setIsSaving((s) => s + 1);

      // Don't submit, if the user didn't change anything
      if (!needsToBeSaved) return;

      await callFunction("ordering-submitOrder", {
        dateSeconds: week[dayIndex]?.seconds,
        childId,
        order: newOrder,
        moduleId,
        info: orderInfo,
        allowedOrderIds: [orderDocument?.id],
      });

      message.success(t("mealplan2:message.success.order-updated"));
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving((s) => s - 1);
      setIsEditing(false);
    }
  };

  const submitOrderInfo = async (orderInfo: OrderInfoType) => {
    try {
      const { textInput, timeInput, reference } = orderInfo;

      const infoUpdater = () => {
        return {
          ...(textInput &&
            plugins?.textInput.active && { textInput: textInput }),
          ...(timeInput &&
            plugins?.timeInput?.active && { timeInput: timeInput }),
          ...(reference &&
            plugins?.invoiceReference.active && { reference: reference }),
        };
      };

      await submitOrder({ updater: () => order, infoUpdater });
      message.success(t("mealplan2:message.success.order-info-updated"));
    } catch (err) {
      console.error(err);
    } finally {
      setOpenEditOrder(false);
    }
  };

  const wrapperClassName = classnames({
    "opacity-30 pointer-events-none": disabled,
    "transition-opacity": true,
    "mt-4 border-t pt-2": ordersAmount > 1 && index !== 0,
  });

  if (!orders.length) return null;

  const date = moment.unix(orderDocument.createdAtSeconds);

  return (
    <div className={wrapperClassName}>
      <BasketList
        key={orderDocument?.id}
        editMode={isEditing}
        setEditMode={setIsEditing}
        editType="amount"
        basket={localBasket}
        setBasket={setLocalBasket}
        disableEditing={editingDisabled}
        title={
          ordersAmount > 1 ? (
            <span className="text-text-secondary h200">
              {orderDocument?.info?.name ||
                t("plugins:order-number-kl", {
                  index: `#${index + 1}`,
                  time: date.format(
                    date.isSame(moment(), "day") ? "HH:mm" : "L HH:mm"
                  ),
                })}
            </span>
          ) : (
            t("plugins:you-have-ordered")
          )
        }
        buttonProps={{
          type: needsToBeSaved ? "primary" : "default",
          onClick: () =>
            needsToBeSaved || isEditing
              ? submitEditedOrder()
              : setIsEditing(true),
          loading: isSaving !== 0,
        }}
        buttonText={
          isSaving !== 0
            ? t("flex:message.loading.order-being-saved")
            : needsToBeSaved
            ? t("translation:save")
            : undefined
        }
      />
      <div>
        {isEditing && hasActivePlugins && (
          <Button
            onClick={() => setOpenEditOrder(true)} // open order info modal
            className="mb-2"
            size="small"
          >
            {t("mealplan2:edit-info")}
            <FontAwesomeIcon icon={faPen} className="ml-2" />
          </Button>
        )}
        {hasKanplaGo && hasOrdered && (
          <div className="w-full flex justify-center">
            <Button
              onClick={() => {
                setCheckoutItems(order || {});
                setReceiptOpen(true);
                setReceiptTime(orderDocument.updatedAtSeconds);
              }} // open kanpla go receipt
            >
              {t("mealplan2:show-last-receipt")}
              <FontAwesomeIcon icon={faEye} className="ml-2" />
            </Button>
          </div>
        )}
      </div>
      <ReceiptWrapper.InfoDisplay orderInfo={orderInfo} module={module} />
      <OrderInfo
        open={openEditOrder}
        setOpen={setOpenEditOrder}
        onSubmit={(orderInfo) => submitOrderInfo(orderInfo)}
      />
    </div>
  );
};

export default OrderItem;
