import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  db,
  getCurrentWeek,
  subtractWeek,
  Timestamp,
  useCollectionListener,
} from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import { Input, Space } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "..";
import { MealplanContext } from "../..";
import Table from "../Table";
import Export from "./Export";

const { Search } = Input;

const History = () => {
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { childId, userId } = useContainer(AppContext);
  const { moduleId } = useContainer(MealplanContext);
  const { setCurrentView } = useContext(MealplanMeetingContext);
  const [query, setQuery] = useState("");

  const lastMonday = subtractWeek(getCurrentWeek({ Timestamp }))[0];

  const [allOrders = [], ordersLoading] = useCollectionListener<OrderMealplan>(
    db
      .collection("orders")
      .where("childId", "==", childId)
      .where("userId", "==", userId)
      .where("moduleId", "==", moduleId)
      .where("date", ">=", lastMonday)
      .orderBy("date")
  );

  const orders = allOrders.filter((o) => !o.deleted && !isEmpty(o.order));

  // Search List
  const filteredOrders = useMemo(() => {
    if (query === "" || query.length < 3) return orders;

    const pattern = new RegExp(query, "i");

    const queryOrders = orders.filter((c) => {
      const dateMoment = moment.unix(c?.dateSeconds);

      return (
        pattern.test(dateMoment.format("dddd")) ||
        pattern.test(dateMoment.format("D/M")) ||
        pattern.test(dateMoment.format("D.M.")) ||
        pattern.test(
          c?.info?.name ||
            t("mealplan2:order-to-d", { value: dateMoment.format("D.M.") })
        )
      );
    });

    return queryOrders;
  }, [query, orders]);

  return (
    <div className="flex flex-col w-full">
      <div className="p-8 md:px-14 flex-col">
        <p
          onClick={() => setCurrentView("overview")}
          className="whitespace-nowrap w-min font-regular text-text-secondary hover:opacity-70 cursor-pointer flex items-center"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          {t("translation:back")}
        </p>
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mt-4">
          <h1 className="text-2xl font-semibold h800 mb-4 md:mb-0">
            {t("mealplan2:order-history")}
          </h1>
          <Space className="flex-col md:flex-row items-start">
            <Export />
            <Search
              style={{ maxWidth: "300px" }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("mealplan2:form.placeholders.search-by-name-date")}
            />
          </Space>
        </div>
      </div>
      <div className="mt-8 w-full">
        <Table orders={filteredOrders} />
      </div>
    </div>
  );
};

export default History;
