import { CombinedOfferItem } from '@kanpla/types';
import { Dictionary } from 'lodash';
import { groupBy } from 'lodash';
import { sortBy } from 'lodash';
import { useContainer } from 'unstated-next';
import { MealplanContext } from ".";

/** Returns dictionary of sorted and grouped categories. */
export const useCategories = (): Dictionary<CombinedOfferItem[]> => {
  const { items, hasLeftovers } = useContainer(MealplanContext);

  const sorted = sortBy(items, item => item.category !== (hasLeftovers ? "leftovers" : ""));
  return groupBy(sorted, "category");
};
