import React from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import UIHeader from "../Header";

const FlexLoader = () => {
  const { deadlineFormatted } = useContainer(FlexContext);

  return (
    <div className="pb-6 pt-1 max-w-4xl md:mx-auto mx-2">
      <div className="mb-12 flex justify-between text-center">
        <UIHeader
          deadlineFormatted={deadlineFormatted}
          className="text-center mx-auto w-full"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="loader h-40 rounded-lg" />
        <div className="loader h-40 rounded-lg" />
        <div className="loader h-40 rounded-lg" />
        <div className="loader h-40 rounded-lg" />
      </div>
    </div>
  );
};

export default FlexLoader;
