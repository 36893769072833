import { calculateOrderTotal, mergeOrders } from "@kanpla/system";
import { ReceiptWrapper } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "./..";
import { AppContext } from "../../contextProvider";
import OrderItem from "./OrderItem";

const Receipt = () => {
  const { t } = useTranslation(["mealplan2", "plugins"]);
  const { isSaving, pulseCounter, customBranding } = useContainer(AppContext);
  const {
    hasOrdered,
    pastDate,
    hasKanplaGo,
    ignoredOrderIds,
    orders,
    setAllowedOrderIds,
  } = useContainer(MealplanContext);

  const [editingOrderId, setEditingOrderId] = useState<string>(null);

  const ordersTrigger = orders.map((order) => order?.id).join("-");
  useEffect(() => {
    setAllowedOrderIds(ordersTrigger.split("-"));
    setEditingOrderId(null);
  }, [ordersTrigger]);

  const editingDisabled = pastDate || hasKanplaGo;

  if (!hasOrdered && ignoredOrderIds.length === 0) return null;

  const total = calculateOrderTotal(mergeOrders(orders.map((o) => o.order)));

  const customColor =
    customBranding?.custom?.palette?.receipt?.background || "#ffffff";

  return (
    <ReceiptWrapper
      pulseCounter={pulseCounter}
      isSaving={isSaving > 0}
      svgColor={customColor}
      className="pt-0 md:pt-8"
    >
      {orders.length > 1 && (
        <h2 className="h500">{t("plugins:you-have-ordered")}</h2>
      )}
      {orders?.map((order, index) => (
        <OrderItem
          editingDisabled={
            editingDisabled || (editingOrderId && editingOrderId !== order?.id)
          }
          index={index}
          ordersAmount={orders?.length || 0}
          key={order?.id}
          orderDocument={order}
          disabled={editingOrderId && editingOrderId !== order?.id}
          setEditingOrderId={setEditingOrderId}
        />
      ))}

      <hr className="mt-3" />

      <ReceiptWrapper.Footer total={total}></ReceiptWrapper.Footer>
    </ReceiptWrapper>
  );
};

export default Receipt;
