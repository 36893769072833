import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateConfigTotal,
  configToDisplayString,
  editConfigAmount,
  priceFormatter,
} from "@kanpla/system";
import {
  BasketItemConfig,
  CombinedOfferItem,
  OrderConfig,
  OrderOrderProduct,
  Plugins,
} from "@kanpla/types";
import {
  Badge,
  Image,
  InputAmount,
  TimeInputDisplay,
  TimeSelect,
} from "@kanpla/ui";
import { Button, Tooltip } from "antd";
import classnames from "classnames";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import { BasketContext } from "./BasketList";
import { EditModeType, isEditModeEnabled } from "./BasketListTypes";

interface BasketListItemProps {
  item: BasketItemConfig;
  editMode?: boolean;
  editType?: EditModeType;
}

export const BasketListItem: FC<BasketListItemProps> = ({
  editMode = false,
  editType = "both",
  item: { productId, config, price, name },
}) => {
  const { items, module } = useContainer(MealplanContext);
  const { activePlugins, dateSeconds } = useContainer(AppContext);
  const { basket, setBasket } = useContainer(BasketContext);
  const isAdhoc = productId.startsWith("ad-hoc-");
  const isEditable = editMode && !isAdhoc;
  const plugins = module?.plugins || ({} as Plugins.Array);

  // Original amount is used for pay-per-order
  const [originalAmount] = useState(config.amount);

  const amountEditable = isEditModeEnabled(editType, "amount");
  const timeEditable = isEditModeEnabled(editType, "time");

  const product: CombinedOfferItem = items?.find(
    (p: CombinedOfferItem) => p.productId === productId
  );
  const { photo, name: productName } = product || ({} as CombinedOfferItem);

  const totalConfigPrice = calculateConfigTotal(config);

  const configText = useMemo(() => configToDisplayString(config), [config]);

  const isSignupOfferProduct =
    activePlugins.signupOffer && plugins?.signupOffer?.productId === productId;

  const isPayPerOrder = activePlugins?.payPerOrder;

  let stock = product?.dates?.[dateSeconds]?.stock;
  if (isSignupOfferProduct && stock > 1) stock = 1;

  const changeAmount = useCallback(
    (value: number) =>
      setBasket(
        editConfigAmount({
          order: basket,
          productId,
          choice: config.options,
          amountChange: value,
          replaceAmount: true,
        })
      ),
    [basket, setBasket, productId, config.options]
  );

  const setTime = useCallback(
    (newTimeValue: number) => {
      if (newTimeValue && newTimeValue === config.deliveryTime) return;

      const targetProduct: OrderOrderProduct = basket[productId];
      const productConfigs = targetProduct.config.map((c: OrderConfig) => {
        if (c.uid !== config.uid) return c;

        return {
          ...config,
          deliveryTime: newTimeValue,
        };
      });

      setBasket({
        ...basket,
        [productId]: {
          ...targetProduct,
          config: productConfigs,
        },
      });
    },
    [config, basket, productId, setBasket]
  );

  return (
    <div className={classnames({ "mb-3": editMode })}>
      <div className="flex justify-between items-center text-text-primary">
        <div className="flex items-center py-2 w-full h-full relative">
          <div className="flex items-center md:w-full flex-shrink w-full">
            <Badge count={config.amount} hidden={isEditable}>
              <div
                className="h-16 w-16 rounded-lg bg-background-secondary flex-shrink-0 relative overflow-hidden border border-divider-main"
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {photo ? (
                  <Image
                    src={`${photo}?w=300`}
                    alt={productName || name}
                    className="absolute inset-0 min-w-full min-h-full h-full w-full object-cover object-center shadow-inner"
                  />
                ) : (
                  <div
                    className="absolute w-full h-full bg-primary-main flex items-center justify-center"
                    style={{ backgroundColor: product?.color }}
                  >
                    <FontAwesomeIcon icon={faCameraSlash} />
                  </div>
                )}
              </div>
            </Badge>
            <div className="text-text-secondary w-full mr-16 md:w-auto ml-3">
              <p className="font-medium" title={productName || name}>
                {productName || name}
                {isAdhoc && (
                  <Tooltip title="Købt i kantinen" placement="right">
                    <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-primary-dark">
                      <FontAwesomeIcon icon={faStore} />
                    </span>
                  </Tooltip>
                )}
              </p>
              {config.options && (
                <>
                  {configText && (
                    <p className="text-sm mt-1 text-text-secondary">
                      {configText}
                    </p>
                  )}
                  {config?.deliveryTime &&
                    !(activePlugins.timeInput && timeEditable && editMode) && (
                      <div className="mt-1 text-sm text-text-secondary">
                        <TimeInputDisplay timeInput={config.deliveryTime} />
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="h-full md:w-20 whitespace-nowrap pl-8 flex items-center absolute top-0 right-0">
            <div className="text-primary-dark flex items-center py-1 text-right">
              <p className="text-right">
                {priceFormatter(config.amount * (price + totalConfigPrice))}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isEditable && (
        <div className="flex items-center justify-between">
          <div className="flex items-end">
            {amountEditable && editMode && (
              <InputAmount
                amount={config.amount}
                setAmount={(value: number) => changeAmount(value)}
                minAmount={1}
                maxAmount={
                  isSignupOfferProduct
                    ? 1
                    : isPayPerOrder
                    ? originalAmount
                    : stock
                }
                size="small"
                className="mr-3"
              />
            )}
            {editMode && activePlugins.timeInput && timeEditable && (
              <TimeSelect
                interval={plugins.timeInput?.interval}
                endAt={plugins.timeInput?.endAt}
                startAt={plugins.timeInput?.startAt}
                /** Sometimes the deliveryTime is undefined */
                value={config.deliveryTime || plugins.timeInput?.startAt}
                onChange={setTime}
              />
            )}
          </div>
          {amountEditable && (
            <Button
              className="ml-3"
              type="primary"
              danger
              onClick={() => changeAmount(0)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
