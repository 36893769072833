import { hasAccessToModule } from "@kanpla/system";
import { Module } from "@kanpla/types";
import { ChildView } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import AdminOverview from "../flexBulk/AdminOverview";
import FooterCanteen from "../FooterCanteen";
import Credit from "./credit";
import Kids from "./Kids";
import UserInfo from "./userInfo";

const Settings = ({ backLabel }) => {
  const { t } = useTranslation(["translation", "components"]);
  const { menuOpen, setMenuOpen, child, school, modules } =
    useContainer(AppContext);

  return (
    <ChildView
      open={menuOpen}
      setOpen={setMenuOpen}
      title={t("translation:my-account")}
      backLabel={backLabel}
    >
      {/* <h1 className="text-center py-4 text-text-secondary text-sm">
          {auth && auth.user && auth.user.email}
        </h1> */}
      <div className="grid gap-12">
        {/* FIX: Doesn't build because of kids */}
        <Kids />
        <Credit />
        {modules.some(
          (module: Module) =>
            module.type === "flex" &&
            hasAccessToModule({ module: module, child: child, school: school })
              .bulk
        ) && <AdminOverview />}
        {/* FIX: Doesn't build because of history */}
        <UserInfo />
      </div>
      <FooterCanteen />
    </ChildView>
  );
};

export default Settings;
