import { faEmptySet } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOrderConfigs } from "@kanpla/system";
import { OrderOrder } from "@kanpla/types";
import { Alert, Button, ButtonProps, Empty } from "antd";
import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { BasketListItem } from ".";
import { AppContext } from "../../contextProvider";
import { EditModeType } from "./BasketListTypes";
import classnames from "classnames";

interface StateProps {
  basket: OrderOrder;
  setBasket: Dispatch<SetStateAction<OrderOrder>>;
}

export const BasketState = ({ basket, setBasket }: StateProps) => {
  return { basket, setBasket };
};

export const BasketContext = createContainer(BasketState);

interface WithEditType {
  editType?: EditModeType;
  disableEditing?: boolean;
  title?: string | JSX.Element;
  basket?: OrderOrder;
  setBasket?: Dispatch<SetStateAction<OrderOrder>>;
  buttonProps?: ButtonProps & React.RefAttributes<HTMLElement>;
  buttonText?: string;
  /** Makes the basket blurry */
  anonymous?: boolean;
}

interface AlwaysEditableBasketListProps extends WithEditType {
  alwaysEditable?: true;
  editMode?: boolean;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  /** For anonymous users */
  hideEditButton?: boolean;
}

interface EditableBasketListProps extends WithEditType {
  alwaysEditable?: false;
  editMode?: boolean;
  /** Required when `alwaysEditable` is false. */
  setEditMode: Dispatch<SetStateAction<boolean>>;
  /** For anonymous users */
  hideEditButton?: boolean;
}

interface EditableBasketListPropsWithBasket extends EditableBasketListProps {
  basket: OrderOrder;
  setBasket: Dispatch<SetStateAction<OrderOrder>>;
}

interface EditableBasketListPropsWithBasket extends EditableBasketListProps {
  basket: OrderOrder;
  setBasket: Dispatch<SetStateAction<OrderOrder>>;
}

/** `setEditMode` is required when `alwaysEditable` is false. */
type BasketListProps =
  | EditableBasketListProps
  | EditableBasketListPropsWithBasket
  | AlwaysEditableBasketListProps;

export const BasketList: FC<BasketListProps> = ({
  setEditMode,
  alwaysEditable = false,
  editMode = false,
  editType = "both",
  hideEditButton = false,
  basket: propsBasket,
  setBasket: setPropsBasket,
  disableEditing = false,
  title,
  buttonProps,
  buttonText,
  anonymous,
}) => {
  const {
    basket: contextBasket,
    setBasket: setContextBasket,
    activePlugins,
  } = useContainer(AppContext);
  const basket = propsBasket || contextBasket;
  const setBasket = setPropsBasket || setContextBasket;
  const { t } = useTranslation(["translation", "design", "modals", "plugins"]);
  const configs = useMemo(() => getOrderConfigs(basket), [basket]);

  /** The list can be always editable for popup views. */
  const isEditMode = alwaysEditable || editMode;

  const basketClassName = classnames({
    "w-full": true,
    "filter blur-sm pointer-events-none select-none": anonymous,
  });

  return (
    <BasketContext.Provider initialState={{ basket, setBasket }}>
      <div className={basketClassName}>
        <div className="flex justify-between items-center">
          <h1 className="h500 my-2">{title || t("design:basket")}</h1>
          {!alwaysEditable && !disableEditing && !hideEditButton && (
            <Button
              size="small"
              onClick={() => setEditMode?.((prevState) => !prevState)}
              {...buttonProps}
            >
              {buttonText ||
                (editMode ? t("translation:done") : t("translation:edit"))}
            </Button>
          )}
        </div>
        {!configs.length && (
          <div className="my-6 py-4 text-text-secondary rounded-lg bg-background-secondary ">
            <Empty
              description={t("modals:no-bookings-short")}
              image={<FontAwesomeIcon icon={faEmptySet} className="text-sm" />}
              imageStyle={{ fontSize: 10 }}
            />
          </div>
        )}
        {configs.length && activePlugins.payPerOrder && isEditMode ? (
          <Alert
            className="text-sm my-2"
            showIcon
            type="info"
            message={t("plugins:pay-per-order.receipt-max-limit")}
          />
        ) : null}
        {configs.map((item, index) => (
          <div key={`${item.config.uid}-${item.productId}-${index}`}>
            <BasketListItem
              item={item}
              editMode={isEditMode}
              editType={editType}
            />
          </div>
        ))}
      </div>
    </BasketContext.Provider>
  );
};
