import { fn } from "@kanpla/system";
import { EditStandardOrder } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import { AppContext } from "../contextProvider";

const StandardSettings = () => {
  const { schoolId, childId, userId } = useContainer(AppContext);
  const { items, moduleId, standard, isBulk, settingsOpen, setSettingsOpen } =
    useContainer(FlexContext);

  return (
    <EditStandardOrder
      fn={fn}
      schoolId={schoolId}
      moduleId={moduleId}
      childId={childId}
      userId={userId}
      open={settingsOpen}
      setOpen={setSettingsOpen}
      products={items}
      standard={standard}
      isBulk={isBulk}
    />
  );
};

export default StandardSettings;
