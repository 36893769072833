import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import { NewLogo } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import SettingsItem from "./mealplan/SettingsItem";
import SupportButtons from "./popups/SupportButtons";

const FooterCanteen = () => {
  const { t } = useTranslation(["translation", "components"]);
  const { school, customBranding } = useContainer(AppContext);
  const { contact, uploads } = school || {};

  const bio = contact?.bio || t("components:manufacturer-of-your-food");

  return (
    <div className={`py-1 pt-4 mt-auto`}>
      <div className="px-5 md:px-6 custom-container">
        {/* Info */}
        <div className="flex flex-col items-center text-center">
          {customBranding?.name ? (
            <div className="mx-auto inline-block mb-3">
              <NewLogo
                className="mx-auto my-8 block"
                style={{ maxWidth: 160, maxHeight: 160 }}
              />
              <h4 className="mt-4 font-semibold h400 text-primary-light">
                {contact?.name}
              </h4>
            </div>
          ) : (
            <h2 className="h500 md:text-2xl lg:text-3xl text-text-primary capitalize mb-2">
              {contact?.name}
            </h2>
          )}

          <p className="max-w-prose mx-auto text-sm">{bio}</p>
          {contact?.ecoMark && (
            <img
              src={`/images/eko/oeko-logo-${contact.ecoMark}.svg`}
              className="w-24 my-3 mx-auto"
              alt="Eco mark"
            />
          )}

          {/* Uploads */}
          {uploads && uploads.length > 0 && (
            <div className="mt-8 text-left">
              <hr className="border-divider-main"></hr>
              {uploads.map(({ name, url }, index) => (
                <SettingsItem
                  key={name + index}
                  label={name}
                  icon={faExternalLinkAlt}
                  onClick={() => {
                    const win = window.open(url, "_blank");
                    win.focus();
                  }}
                />
              ))}
            </div>
          )}

          <SupportButtons />

          {!customBranding?.name && (
            <div className="text-text-secondary mt-6">
              <NewLogo
                className="mx-auto my-8 block opacity-70"
                style={{ maxWidth: 120, maxHeight: 120 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterCanteen;
