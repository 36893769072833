import {
  getDayIndexFromSeconds,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
} from "@kanpla/system";
import { DayIndex, OrderConfig, OrderMealplan } from "@kanpla/types";
import { message } from "antd";
import moment from "moment";
import { useRouter } from "next/router";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import OrderForAmountOfPeople from "./OrderForAmountOfPeople";
import History from "./views/History";
import Ordering from "./views/Ordering";
import Overview from "./views/Overview";

interface ContextProps {
  currentView: string;
  setCurrentView: Dispatch<SetStateAction<string>>;
  editingOrder: OrderMealplan;
  setEditingOrder: Dispatch<SetStateAction<OrderMealplan>>;
  isConfirming: boolean;
  setIsConfirming: Dispatch<SetStateAction<boolean>>;
  hasOrderForAmountOfPeoplePlugin: boolean;
}

interface Props {
  moduleId?: string;
}

export interface Views {
  [key: string]: {
    content: ReactNode;
  };
}

export interface BasketProduct {
  amount: number;
  price: number;
  productId: string;
  config?: OrderConfig;
}

export const MealplanMeetingContext = createContext<Partial<ContextProps>>({});

const MealplanMeeting = (props: Props) => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { setTimeNavigation, setDayIndex, setWeek, dayIndex, week } =
    useContainer(AppContext);
  const { setAllowedOrderIds, deadline } = useContainer(MealplanContext);
  const { setBasket, activePlugins } = useContainer(AppContext);
  const [currentView, setCurrentView] = useState<string>("overview");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  // Ignore all other orders for the day (except the one editing, in case)
  const [editingOrder, setEditingOrder] = useState<OrderMealplan>(null);

  const router = useRouter();

  /** Automatic ordering via a link */
  const queryTrigger = JSON.stringify(router.query);
  useEffect(() => {
    const date = router.query?.date;
    if (!date) return;

    const momentDate = moment.parseZone(`${date}`, "DDMMYYYY", i18n.language);

    const currentlySelectedDate = week?.[dayIndex]?.seconds;

    if (moment.unix(currentlySelectedDate).isSame(momentDate, "day")) return;

    if (!momentDate.isValid()) {
      message.warning(t("mealplan2:message.warning.invalid-date"));
      return;
    }

    const dayDeadline = momentDate.clone().startOf("day").add(deadline, "s");
    // After deadline
    if (dayDeadline.isBefore(moment())) {
      message.warning(t("mealplan2:message.warning.too-late"));
      return;
    }

    const dateSeconds = momentDate.unix();
    const weekSeconds = getWeekSeconds(dateSeconds);
    const weekArray = getWeekArray(weekSeconds, Timestamp);
    const localDayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    );

    // Disallow weekend
    if (localDayIndex === 5 || localDayIndex === 6) {
      message.warning(t("mealplan2:message.warning.cant-book-for-weekend"));
      return;
    }

    setDayIndex(localDayIndex as DayIndex);
    setWeek(weekArray);
    setCurrentView("ordering");
    setEditingOrder(null);
  }, [queryTrigger, week, dayIndex]);

  useEffect(() => {
    if (editingOrder) {
      setBasket(editingOrder.order);
      setAllowedOrderIds([editingOrder.id]);
    } else {
      setAllowedOrderIds([]);
      setBasket({});
    }
  }, [editingOrder?.id, currentView]);

  const views: Views = {
    overview: {
      content: <Overview />,
    },
    ordering: {
      content: <Ordering />,
    },
    history: {
      content: <History />,
    },
  };

  useEffect(() => {
    setTimeNavigation("none");
  }, [currentView, setTimeNavigation]);

  useEffect(() => {
    setTimeNavigation("none");
  }, []);

  return (
    <MealplanMeetingContext.Provider
      value={{
        currentView,
        setCurrentView,
        editingOrder,
        setEditingOrder,
        isConfirming,
        setIsConfirming,
      }}
    >
      {views[currentView].content}
      {activePlugins.orderForAmountOfPeople && <OrderForAmountOfPeople />}
    </MealplanMeetingContext.Provider>
  );
};

export default MealplanMeeting;
