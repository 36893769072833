import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { priceFormatter } from "@kanpla/system";

interface BasketResultProps {
  amount: number;
  price: number;
}

/** Draws the basket result: i.e. summary amount of items and summary price. */
export const BasketResult: FC<BasketResultProps> = ({ amount, price }) => (
  <div className="flex justify-between items-center mt-6 border-t-2 border-divider-main py-4 text-text-primary -mx-8 px-8">
    <div className="font-semibold text-lg">
      {amount}
      <FontAwesomeIcon icon={faShoppingBag} className="ml-2" />
    </div>
    <div>
      i alt:
      <b className="text-lg ml-2">{priceFormatter(price)}</b>
    </div>
  </div>
);
