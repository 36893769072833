import {
  db,
  getProductsPerSchool,
  hasAccessToModule,
  sortProducts,
  useCollectionListener,
} from "@kanpla/system";
import { ProductBank } from "@kanpla/types";
import { Print as PrintWrapper } from "@kanpla/ui";
import getMenus from "apps/frontend/lib/flex/getMenus";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import MultipleModulePrint from "../flex/MultipleModulePrint";
import EmployeesOrders from "../modals/EmployeesOrders";
import { Button } from "../settings/userInfo";

const AdminOverview = () => {
  const { t } = useTranslation(["flex-bulk", "translation"]);
  const { modules, partnerId, school, child, week } = useContainer(AppContext);

  const [productBank = []] = useCollectionListener<ProductBank>(
    partnerId
      ? db
          .collection("productBanks")
          .where("partnerId", "==", partnerId)
          .limit(1)
      : null
  );

  // Offering
  const module = modules.find(
    (m) => m.type === "flex" && m.paymentMethod === "billing"
  );
  const products = getProductsPerSchool({
    schoolId: school?.id,
    productBank: productBank[0],
    module,
    db,
  });
  const { menus: allMenus } = getMenus({ products });
  const menus = allMenus.filter((m) => m);

  const [openOrdersHistory, setOpenOrdersHistory] = useState(false);

  const { bulk: hasBulk, individual: hasIndividual } = hasAccessToModule({
    module,
    child,
    school,
  });

  if (!hasBulk && !hasIndividual) return null;
  if (!week && !products && !menus) return null;

  const orderedProducts = sortProducts({
    items: products,
    list: module?.list,
  });

  const flexModules = modules.filter((m) => m.type === "flex");

  return (
    <div>
      <h2 className="h500 border-b-2 border-divider-main pb-2">
        {hasBulk
          ? t("flex-bulk:admin-overview")
          : t("flex-bulk:breakfast-arrangement")}
      </h2>

      {hasBulk && (
        <Button
          onClick={() => setOpenOrdersHistory(true)}
          label={t("translation:employee-orders")}
        ></Button>
      )}

      {flexModules?.length > 1 && (
        <MultipleModulePrint productBanks={productBank} />
      )}

      {flexModules?.length === 1 && (
        <>
          <PrintWrapper menus={menus} products={orderedProducts} week={week}>
            <Button label={t("translation:print-week-menu")}></Button>
          </PrintWrapper>
          <PrintWrapper
            menus={menus}
            products={orderedProducts}
            week={week}
            isDigital
          >
            <Button label={t("translation:see-week-menu")}></Button>
          </PrintWrapper>
        </>
      )}

      <EmployeesOrders
        open={openOrdersHistory}
        setOpen={setOpenOrdersHistory}
      />
      {/* <PrintMenu open={openPrint} setOpen={setOpenPrint} /> */}
    </div>
  );
};

export default AdminOverview;
