import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  db,
  deadlineDisplay,
  deadlineFormatter,
  fn,
  getFlexBulkWeeklyOrders,
  getFlexStandard,
  hasAccessToModule,
  isDatePastDeadline,
  UseOffers,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  flexBulkOrder,
  OrderOrder,
} from "@kanpla/types";
import { ButtonSave } from "@kanpla/ui";
import { Button, message, Space } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import { useRouter } from "next/router";
import { default as React, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import AdminOverview from "./AdminOverview";
import Menu from "./Menu";

interface WeekOrders {
  [dayIndex: number]: OrderOrder;
}

interface WeekDocuments {
  [dayIndex: number]: flexBulkOrder;
}

const ContextState = () => {
  const { t } = useTranslation(["translation", "flex-bulk"]);
  const {
    school,
    week,
    child,
    userId,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    setInnerAppLoading,
  } = useContainer(AppContext);
  const router = useRouter();

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(false);
      router.replace(newUrl);
    }
  }, []);

  useEffect(() => {
    setTimeNavigation("calendarWeekly");
  }, []);

  // Standard
  const [standardOrders, setStandardOrders] = useState<WeekOrders>(null);
  const { standard } = getFlexStandard({
    moduleId,
    childId,
    userId,
    schoolId,
    isBulk: true,
    db,
  });

  const standardTrigger = JSON.stringify(standard);
  useEffect(() => {
    setStandardOrders(standard);
  }, [standardTrigger]);

  // Order
  const [weeklyOrders, setWeeklyOrders] = useState<WeekOrders>(null);
  const [orderDocuments, setOrderDocuments] = useState<WeekDocuments>(null);
  const [orderLoading, setOrderLoading] = useState<boolean>(true);

  useEffect(() => {
    setOrderLoading(true);
    getFlexBulkWeeklyOrders({
      schoolId,
      moduleId,
      db,
      week,
      standard,
    }).then(({ weekOrders, weekDocuments }) => {
      setOrderLoading(false);
      setWeeklyOrders(weekOrders);
      setOrderDocuments(weekDocuments);
    });
  }, [week[0].seconds]);

  // Standard View
  const [saved, setSaved] = useState(true);
  const [isStandardView, setStandardView] = useState(false);
  const orders = isStandardView ? standardOrders : weeklyOrders;
  const setOrders = (newValue: WeekOrders) => {
    setSaved(false);
    if (isStandardView) {
      setStandardOrders(newValue);
    } else {
      setWeeklyOrders(newValue);
    }
  };

  useEffect(() => {
    setSaved(true);
  }, [week?.[0]?.seconds]);

  // Offers
  const allDateSeconds = week.map((d) => d.seconds);
  const { items, holidayDates, deadlineInfo, mealOptions } = UseOffers({
    schoolId,
    moduleId,
    allDateSeconds,
    setLoading: setInnerAppLoading,
    groupName: child?.groupName,
  });

  // Deadline Jump
  useDeadlineJump({ defaultDate: deadlineInfo?.defaultDate });

  const isProductOrdered = (product: CombinedOfferItem) => {
    const productId = product.id;
    const isOrdered = Object.values(orders || {}).some(
      (order) => order?.[productId]?.amount > 0
    );
    return isOrdered;
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
    defaultSoftDate,
  } = deadlineInfo;
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  // Saving functions
  const saveStandard = async () => {
    try {
      message.loading(t("translation:message.loading.standards-are-saved"), 0);

      const submitToServer = fn.httpsCallable("submitFlexBulkStandard");
      await submitToServer({
        standard: standardOrders,
        schoolId,
        moduleId,
        childId,
      });

      message.destroy();
      message.success(t("translation:message.success.standards-updated"));
      setSaved(true);
    } catch (err) {
      console.error(err);

      message.destroy();
      message.error(t("translation:message.error.changes-could-not-be-saved"));
    }
  };

  const saveOrder = async () => {
    try {
      message.loading(t("flex-bulk:message.loading.orders-saved"), 0);

      const submitOrderToServer = fn.httpsCallable("submitFlexBulkOrder");

      const promises = week.map(async (date, dayIndex) => {
        const targetOrder = weeklyOrders[dayIndex];
        if (!targetOrder) return;

        const isOrderPastDeadline = isDatePastDeadline({
          date,
          deadline,
          deadlineExcludingWeekends,
          deadlineWeekRelative,
        });

        if (isOrderPastDeadline) return;

        await submitOrderToServer({
          schoolId,
          dateSeconds: date.seconds,
          order: targetOrder,
          moduleId,
          childId,
        });
      });

      await Promise.all(promises);

      message.destroy();
      message.success(t("flex-bulk:message.success.order-saved"));
      setSaved(true);
    } catch (err) {
      console.error(err);

      message.destroy();
      message.error(
        err?.message || t("flex-bulk:message.error.order-could-not-saved")
      );
    }
  };

  const daysHolidays = week.map((date, dayIndex: DayIndex) => {
    const dateSeconds = week[dayIndex]?.seconds;
    const holidayOrNull = holidayDates?.[dateSeconds];
    return !!holidayOrNull;
  });

  return {
    module,
    moduleId: module.id,
    items,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    defaultDate,
    softDeadline: deadlineSoft,
    defaultSoftDate,
    softDeadlineMaxAmount,
    deadlineFormatted,
    orders,
    orderDocuments,
    setOrders,
    isStandardView,
    daysHolidays,
    isProductOrdered,
    saved,
    saveStandard,
    saveOrder,
    setStandardView,
    mealOptions,
  };
};

export const FlexBulkContext = createContainer(ContextState);

const Index = () => {
  return (
    <FlexBulkContext.Provider>
      <View />
    </FlexBulkContext.Provider>
  );
};

const View = () => {
  const { t } = useTranslation(["flex-bulk", "translation"]);
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    isStandardView,
    saved,
    saveStandard,
    saveOrder,
    module,
    setStandardView,
  } = useContainer(FlexBulkContext);

  const { innerAppLoading } = useContainer(AppContext);

  return (
    <>
      <NavbarSecondary
        timeNavigation="weekly"
        deadlineFormatted={deadlineDisplay({
          deadline,
          deadlineWeekRelative,
          deadlineExcludingWeekends,
        })}
        extraContent={
          <ButtonSave
            size="large"
            saved={saved}
            className="w-full md:w-auto"
            onClick={() => (isStandardView ? saveStandard() : saveOrder())}
          />
        }
      />
      <div className="wrapper">
        <ModuleDescription align="left" module={module} />
        <ModuleLoadingWrapper loading={innerAppLoading}>
          <div className="mb-12 flex flex-col md:flex-row justify-between text-center items-center -ml-3"></div>
          {isStandardView && (
            <Space direction="vertical">
              <Button
                onClick={() => setStandardView(false)}
                className="-mt-3 mb-3"
              >
                <Space>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  {t("flex-bulk:back-to-order")}
                </Space>
              </Button>
              <p className="text-text-secondary max-w-2xl pb-3">
                <Trans t={t} i18nKey="flex-bulk:here-you-can">
                  Her kan du
                  <span className="font-semibold">
                    tilføje og redigere din automatiske standard bestilling
                  </span>
                  på vegne af din virksomhed. Vi anbefaler desuden, at du altid
                  gennemgår dine kommende bestillinger efter ændring i
                  standardvalget.
                </Trans>
              </p>
            </Space>
          )}

          <Menu />
          {!isStandardView && (
            <div className="lg:grid grid-cols-2 gap-4 px-3 lg:px-0">
              <div className="mb-4">
                <h4 className="h500 pb-2">
                  {t("flex-bulk:default-selection")}
                </h4>
                <p className="text-sm text-text-primary">
                  {t("flex-bulk:set-default-choice")}
                </p>
                <Button
                  type="primary"
                  className="mt-3"
                  onClick={() => setStandardView(true)}
                >
                  {t("translation:edit-default-selection")}
                </Button>
              </div>
              <div className="mb-4">
                <AdminOverview />
              </div>
            </div>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};

export default Index;
