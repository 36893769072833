import { callFunction } from "@kanpla/system";
import { Child, School } from "@kanpla/types";
import { DrawerOrModal, FormInput, SelectorsInput } from "@kanpla/ui";
import { Button, message, Popconfirm } from "antd";
import { isEmpty } from "lodash";
import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  child: Child;
  school: School;
}

const SalesplaceEdit: FC<Props> = ({ open, setOpen, child, school }) => {
  const [selectors, setSelectors] = useState<Child["selectors"]>(null);
  const [newReference, setNewReference] = useState<string>("");
  const [newCode, setNewCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { children, allModules } = useContainer(AppContext);

  const { t } = useTranslation(["translation", "settings"]);

  const hasReferencePlugin =
    allModules
      .filter((module) =>
        (module?.scope?.generatedSchoolIds || module?.schoolIds || []).includes(
          child?.school_ref?.id || child?.schoolId
        )
      )
      .filter((module) => module.plugins?.invoiceReference?.active).length > 0;

  const allAvailableCodes = [
    ...allModules
      .filter((module) =>
        module?.scope?.generatedSchoolIds?.includes(child?.school_ref?.id)
      )
      .map((module) => {
        if (!module || isEmpty(module)) return null;

        const schoolSpecificSettings = school?.modules?.[module.id] || {};

        return [
          ...(module.config?.codes || []),
          ...(module.config?.bulkCodes || []),
          ...((!schoolSpecificSettings.individualDisabled &&
            schoolSpecificSettings.codes) ||
            []),
          ...((schoolSpecificSettings.bulkEnabled &&
            schoolSpecificSettings.bulkCodes) ||
            []),
        ];
      })
      .flat(),
  ];

  const hasAccessCodes = allAvailableCodes.length > 0;

  const handleDeleteSalesplace = async () => {
    try {
      const { id: originalChildId } = children.find(
        (child) => child?.isMainChild
      );

      await callFunction("salesplaces-deleteChildSalesplace", {
        originalChildId,
        schoolId: school.id,
      });

      message.success(t("settings:message.success.salesplace-deleted"));
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setOpen(false);
    }
  };
  const onConfirm = async () => {
    try {
      setLoading(true);

      const { id: originalChildId } = children.find(
        (child) => child?.isMainChild
      );

      const childCodes = child?.codes || [];

      await callFunction("salesplaces-editChildSalesplace", {
        originalChildId,
        schoolId: school.id,
        defaultReference: newReference || null,
        selectors,
        codes: newCode ? [...childCodes, newCode] : childCodes,
      });

      message.success(t("settings:message.success.salesplace-updated"));
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <DrawerOrModal
      title={t("settings:edit-locations")}
      zIndex={40}
      open={open}
      setOpen={setOpen}
      actions={[
        {
          label: t("translation:confirm"),
          onClick: onConfirm,
          type: "primary",
          loading,
        },
      ]}
    >
      {school?.selectors && (
        <>
          <h3 className="h400 mb-2 text-text-primary">
            {t("translation:selectors")}
          </h3>
          <SelectorsInput
            school={school}
            selectors={selectors}
            setSelectors={setSelectors}
          />
        </>
      )}

      {hasReferencePlugin && (
        <FormInput
          label={t("translation:form.labels.default-reference")}
          note={t("translation:form.notes.default-reference-note")}
          value={newReference}
          setValue={(v) => setNewReference(v)}
          name="reference-id"
        />
      )}

      {hasAccessCodes && (
        <FormInput
          label={t("translation:form.labels.special-password")}
          note={t("translation:form.notes.grandet-access")}
          value={newCode}
          setValue={(v) => setNewCode(v)}
          name="special-password"
        />
      )}

      <h3 className="h400 mt-4 text-text-primary">
        {t("translation:other-options")}
      </h3>
      <Popconfirm
        title={t("translation:are-you-sure")}
        onConfirm={handleDeleteSalesplace}
        onCancel={() => true}
        okText={t("translation:yes")}
        cancelText={t("translation:cancel")}
      >
        <Button danger size="small" className="mt-2">
          {t("settings:delete-location")}
        </Button>
      </Popconfirm>
    </DrawerOrModal>
  );
};

export default SalesplaceEdit;
