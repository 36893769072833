import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateConfigTotal, configToDisplayString } from "@kanpla/system";
import { CombinedOfferItem, OrderConfig } from "@kanpla/types";
import { Image, TimeInputDisplay } from "@kanpla/ui";
import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  config: OrderConfig;
  productId: string;
  /** Base price of the product, without variants */
  price: number;
  name: string;
  isAdhoc?: boolean;
  hidePicture?: boolean;
  color?: "color" | "white" | "lightColor";
  /** Used to fetch picture */
  items?: CombinedOfferItem[];
}

const Item = ({
  productId,
  config,
  price,
  name,
  isAdhoc,
  hidePicture = false,
  color = "color",
  items = [],
}: Props) => {
  const { t, i18n } = useTranslation(["translation"]);
  const product =
    items?.find((p) => p.productId === productId) || ({} as CombinedOfferItem);
  const { photo, name: productName } = product || {};

  const totalConfigPrice = calculateConfigTotal(config);

  const totalPrice = config.amount * (price + totalConfigPrice);

  return (
    <div className="flex items-center py-2 w-full h-full relative">
      <div className="flex items-center md:w-full flex-shrink w-full">
        {!hidePicture && (
          <div
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="h-14 w-14 md:h-12 md:w-12 rounded-lg bg-background-secondary flex-shrink-0 relative overflow-hidden border border-divider-main"
          >
            {photo ? (
              <Image
                src={photo}
                alt={productName || name}
                className="absolute inset-0 min-w-full min-h-full h-full w-full object-cover object-center shadow-inner"
              />
            ) : (
              <div
                className="absolute w-full h-full bg-primary-main flex items-center justify-center"
                style={{ backgroundColor: product?.color }}
              >
                <FontAwesomeIcon icon={faCameraSlash} />
              </div>
            )}
          </div>
        )}
        <div
          className={`${hidePicture ? "" : "ml-4"} w-full mr-16 md:w-auto ${
            totalPrice >= 0
              ? color === "color"
                ? "text-text-primary"
                : "text-text-secondary"
              : "text-danger-main"
          }`}
        >
          <p className="font-medium" title={productName || name}>
            {config.amount}x {productName || name}
            {isAdhoc && (
              <Tooltip
                title={t("translation:bought-in-canteen")}
                placement="right"
              >
                <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-primary-dark">
                  <FontAwesomeIcon icon={faStore} />
                </span>
              </Tooltip>
            )}
          </p>
          {config.options && (
            <>
              <p className="text-sm mt-1 text-text-secondary">
                {configToDisplayString(config, false, i18n.language)}
              </p>
              {config?.deliveryTime && (
                <div className="mt-1 text-sm text-text-secondary">
                  <TimeInputDisplay timeInput={config.deliveryTime} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="h-full md:w-20 whitespace-nowrap pl-8 flex items-center absolute top-0 right-0 justify-end">
        <div className="text-primary-dark flex items-center py-1 text-right">
          <p className="text-right">
            {t("translation:amount-with-value", {
              value: totalPrice,
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Item;
