import { priceFormatter } from "@kanpla/system";
import { Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  /** Payment method */
  paymentMethod?: string;
  /** Total of the order */
  orderTotal?: number;
  /** Taxes */
  taxTotal?: number;
}

const TotalOrder = ({ paymentMethod, orderTotal, taxTotal }: Props) => {
  const { t, i18n } = useTranslation(["translation", "modals"]);

  const modifiedOrderTotal =
    paymentMethod === "billing" ? orderTotal : orderTotal - taxTotal;

  if (!paymentMethod) return <div />;

  return (
    <div className="text-text-secondary">
      <div>
        <span>{t("translation:excl-taxes")}:</span>{" "}
        <span className="font-semibold">
          {priceFormatter(modifiedOrderTotal, { language: i18n.language })}
        </span>
      </div>
      <div>
        <span className="text-sm">{t("translation:VAT")}:</span>{" "}
        <span className="font-semibold">
          {priceFormatter(taxTotal, { language: i18n.language })}
        </span>
      </div>
      <div className="flex justify-end">
        <Divider className="my-1 w-2/4 min-w-0" />
      </div>
      <div className="text-primary-main font-semibold">
        {t("translation:total")}:{" "}
        {priceFormatter(modifiedOrderTotal + taxTotal, {
          language: i18n.language,
        })}
      </div>
    </div>
  );
};

export default TotalOrder;
