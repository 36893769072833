import { Button } from "antd";
import { groupBy } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TimeDivider from "./TimeDivider";
import { Item as ItemType } from "./UseHistory";

interface Props {
  items: ItemType[];
}

interface RadioProps {
  value: string;
  label: string;
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  left?: boolean;
  right?: boolean;
}

const RadioButton = ({
  value,
  label,
  selected,
  setSelected,
  left,
  right,
}: RadioProps) => (
  <Button
    className={`${
      left
        ? "rounded-r-none"
        : `${right ? "rounded-l-none" : "rounded-none"} -ml-px`
    } overflow-hidden`}
    type={value === selected ? "primary" : "default"}
    size="small"
    onClick={() => setSelected(value)}
  >
    <span className="truncate-ellipsis">{label}</span>
  </Button>
);

const TypeDivider = ({ items }: Props) => {
  const { t } = useTranslation(["translation"]);
  const [itemTypeInView, setItemTypeInView] = useState<ItemType["itemType"]>();
  const itemsByType = groupBy(items, "itemType");
  const availableItemTypes = Object.entries(itemsByType)
    .filter(([key, value]) => value?.length > 0)
    .map(([key]) => key as ItemType["itemType"]);
  const hasTabs = availableItemTypes.length > 1;

  useEffect(() => {
    if (!itemTypeInView && hasTabs) setItemTypeInView(availableItemTypes[0]);
  }, [hasTabs]);

  const itemsInView = hasTabs ? itemsByType[itemTypeInView] : items;

  return (
    <>
      {hasTabs && (
        <div className="flex mb-4">
          <RadioButton
            value="credit"
            label={t("translation:private-purchase")}
            selected={itemTypeInView}
            setSelected={setItemTypeInView}
            left
          />
          <RadioButton
            value="billing"
            label={t("translation:company-acquisition")}
            selected={itemTypeInView}
            setSelected={setItemTypeInView}
          />
          <RadioButton
            value="subscription"
            label={t("translation:subscription")}
            selected={itemTypeInView}
            setSelected={setItemTypeInView}
            right
          />
        </div>
      )}
      <TimeDivider items={itemsInView} />
    </>
  );
};

export default TypeDivider;
