import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  link: string;
  linkText: string;
  overlayOpacity?: number;
}

const BannerThree = ({
  title,
  imageUrl,
  link,
  linkText,
  subTitle,
  overlayOpacity,
}: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg  bg-gray-900 `}
      style={{ minHeight: 200 }}
    >
      <BannerBackgroundImage
        src={imageUrl}
        alt={title}
        opacity={1 - overlayOpacity / 140}
      />
      <div
        className={`flex flex-col h-full relative inset-0 p-4 ${getProportionalHeightPadding(
          width,
          "bottom",
          true
        )} `}
        style={{ minHeight: 200 }}
      >
        <h3
          className={`h600 mt-auto text-white text-left ${getProportionalFontSize(
            width,
            "text-xl"
          )}`}
        >
          {title}
        </h3>
        <p className={`text-white text-left ${getProportionalFontSize(width)}`}>
          {ReactHtmlParser(subTitle)}
        </p>

        {link && linkText && (
          <div className="mt-1">
            <button
              className={`bg-primary-main inline-block rounded p-2 px-3 text-left text-primary-contrast hover:text-primary-contrast hover:bg-primary-dark transition mt-4 ${getProportionalFontSize(
                width
              )}`}
            >
              {linkText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerThree;
