import { db, fn, getFlexStandard, getProductsPerSchool } from "@kanpla/system";
import { Module } from "@kanpla/types";
import { EditStandardOrder } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Slide from "./Slide";
interface Props {
  module: Module;
}

const StandardOrder = ({ module }: Props) => {
  const { t } = useTranslation(["introduction"]);
  const { school, schoolId, userId } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const products = getProductsPerSchool({
    db,
    schoolId: school?.id,
    module,
  });
  const { childId } = useContainer(AppContext);

  const moduleId = "";
  const isBulk = false;

  const { standard } = getFlexStandard({
    schoolId,
    moduleId,
    childId,
    isBulk,
    userId,
    db,
  });

  return (
    <Slide
      backgroundImage="/images/introduction/salad.jpg"
      title={`${t("introduction:enter-default-order")} ✅`}
    >
      <p className="mb-3">
        {t("introduction:enter-normal-number-envelopes")}
        <Button className="mx-auto mt-8 block" onClick={() => setOpen(true)}>
          {t("introduction:enter-default-selection")}
        </Button>
        <EditStandardOrder
          fn={fn}
          schoolId={school.id}
          moduleId={moduleId}
          childId={childId}
          open={open}
          setOpen={setOpen}
          userId={userId}
          products={products}
          standard={standard}
          isBulk={isBulk}
        />
      </p>
    </Slide>
  );
};

export default StandardOrder;
