import { useLocationHostname } from "@kanpla/system";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NewKid from "../modals/newKid";
import Kid from "./kid";
import SalesplaceKid from "./salesplace/SalesplaceKid";

const Kids = () => {
  const { t } = useTranslation(["settings"]);
  const { children, isPosone, isChildSalesplace } = useContainer(AppContext);

  /** HARDCODED! */
  const domainsWithSingleUser = [
    "serwiz.kanpla.dk",
    "gastrokantiner.kanpla.dk",
    "zoffmannjensen.kanpla.dk",
  ];

  const hostname = useLocationHostname({});
  const singleUserApp = domainsWithSingleUser.includes(hostname);

  return (
    <div>
      {!isChildSalesplace ? (
        <div>
          <h2 className="h500 pb-2 border-b">{t("settings:user")}</h2>
          {children != null &&
            children.map((child) => (
              <Kid key={child.child_ref.id || child.id} child={child} />
            ))}
          {!isPosone && !singleUserApp && <NewKid />}
        </div>
      ) : (
        <div className="w-full -mt-2">
          <SalesplaceKid />
        </div>
      )}
    </div>
  );
};

export default Kids;
