import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
  getOrderWithPrices,
  mergeOrders,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  OrderOrder,
} from "@kanpla/types";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";

type Props = {
  product: CombinedOfferItem;
};

const useOnPurchase = ({ product }: Props) => {
  const {
    schoolId,
    module,
    basket,
    setBasket,
    setOpenBasket,
    activePlugins,
    school,
  } = useContainer(AppContext);
  const { forceUpdate, items } = useContainer(MealplanContext);
  const { productId } = product;

  // Holds the state from the variant/amount selectors, sets order upon submit
  const [nextOrder, setNextOrder] = useState(basket);
  const orderTrigger =
    calculateOrderTotal(basket) + calculateAmountOfOrderItems(basket);
  useEffect(() => {
    setNextOrder(basket);
  }, [orderTrigger]);

  const getNextOrder = ({ productAmount, optionChoices, order }) => {
    const newProductItem = {
      [product.productId]: {
        amount: productAmount,
        price: product.price,
        name: product.name,
        config: [
          {
            name: product.name,
            amount: productAmount,
            options: optionChoices || {},
          },
        ],
      },
    };

    /** special case for advanced variants */
    const newOrder = mergeOrders([order, newProductItem]);

    return newOrder;
  };

  const addWithAdvancedVariants = () => {
    const orderWithPrices = getOrderWithPrices({
      // order: mergeOrders([basket, nextOrder]),
      order: nextOrder,
      // order: addIndividualDeliveryTime(nextOrder),
      //@ts-ignore
      products: items.map((p) => ({
        ...p,
        id: p.productId,
      })),
      module: module,
      schoolId,
    });

    return orderWithPrices;
  };

  const onPurchase = async (
    prod: CombinedOfferItem,
    data: CustomOrderContent
  ) => {
    const { requiredProductOrder } = data;

    // ✅ Only ordering with basket
    // validateExtraFields();

    // TODO: Ask about
    // - removing individual product reference
    // - Individual time input
    try {
      const hasKanplaGo = activePlugins?.["kanplaGo"];
      const hasPayPerOrder = activePlugins?.["payPerOrder"];
      const activePaymentPlugin = hasKanplaGo
        ? module?.plugins?.kanplaGo
        : hasPayPerOrder
        ? module?.plugins?.payPerOrder
        : undefined;

      const orderUpdater = (oldOrder: OrderOrder) => {
        /** special case for advanced variants */
        const newOrder = activePlugins.advancedVariants
          ? addWithAdvancedVariants()
          : getNextOrder({ ...data, order: basket });

        const completeOrder: OrderOrder = {
          ...newOrder,
          ...requiredProductOrder,
        };

        return completeOrder;
      };
      setBasket(orderUpdater as any);
      if (activePaymentPlugin?.showBasketPopup) setOpenBasket(true);
      if (
        module?.type === "mealplan" &&
        !hasKanplaGo &&
        !hasPayPerOrder &&
        ["school", "schoolSupplier", "highSchool"].includes(school.type) &&
        calculateOrderTotal(basket) === 0
      )
        setOpenBasket(true);

      if (activePaymentPlugin?.showBasketPopup) setOpenBasket(true);
      if (
        module?.type === "mealplan" &&
        !hasKanplaGo &&
        !hasPayPerOrder &&
        ["school", "schoolSupplier", "highSchool"].includes(school.type) &&
        calculateOrderTotal(basket) === 0
      )
        setOpenBasket(true);

      setBasket(orderUpdater);

      forceUpdate();
    } catch (e) {
      throw new Error(e?.message);
    }
  };

  const orderButtonDisabled =
    activePlugins.advancedVariants && !(nextOrder?.[productId]?.amount > 0);

  return { onPurchase, orderButtonDisabled, nextOrder, setNextOrder };
};

export default useOnPurchase;
