import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { ButtonEdit } from "@kanpla/ui";
import EditKid from "../../modals/EditKid";
import NewSalesplace from "../../modals/NewSalesplace";
import SalesplaceKidRow from "./SalesplaceKidRow";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

const SalesplaceKid = () => {
  const { school, children, child } = useContainer(AppContext);

  const { t } = useTranslation(["settings"]);

  const [openEditKid, setOpenEditKid] = useState(false);

  const initials =
    child?.name
      ?.split(" ")
      .map((word) => word[0])
      .filter((ch) => /([A-Za-z])/.test(ch))
      .join("") || "-";

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full items-center justify-center">
        <div
          className={`border border-primary-main p-0.5 rounded-full w-14 h-14 flex-shrink-0 flex items-center justify-center`}
        >
          <div
            className={`text-background-primary bg-primary-main rounded-full flex justify-center items-center text-xl font-medium w-full h-full`}
          >
            {initials?.[0]}
            {initials?.[1]}
          </div>
        </div>
        <div className="flex items-center mt-4">
          <h3 className="text-text-primary h500 md:h600">
            {child?.displayName}
          </h3>
          <ButtonEdit
            size="small"
            className="ml-4"
            onClick={() => setOpenEditKid(true)}
          />
        </div>
        <h2 className="text-text-secondary text-lg">{school?.name}</h2>
      </div>
      <div className="mt-6 pb-2 border-b-2">
        <h2 className="h500">{t("settings:locations")}</h2>
      </div>
      {sortBy(children, "school_name").map((child) => (
        <SalesplaceKidRow
          key={child.id}
          child={child}
          schoolId={child.schoolId}
        />
      ))}

      <div className="mt-2">
        <NewSalesplace />
      </div>

      <EditKid
        open={openEditKid}
        setOpen={setOpenEditKid}
        child={child}
        isChildSalesplace={true}
      />
    </div>
  );
};

export default SalesplaceKid;
