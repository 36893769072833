import { ButtonWithLoading, Modal } from "@kanpla/ui";
import { Trans, useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AppContext } from "../contextProvider";

const SubscriptionPopup = () => {
  const { t } = useTranslation(["modals"]);
  const { school, modules } = useContainer(AppContext);
  const router = useRouter();
  const [open, setOpen] = useState(true);
  const [shown, setShown] = useLocalStorage("skolemilk_june_2020");

  const dismiss = () => {
    setOpen(false);
    setShown(true);
  };

  if (!school || !school.subscriptions || shown) return null;

  return (
    <Modal open={open} setOpen={dismiss} title={t("modals:order-school-milk")}>
      <p className="text-center mb-2">
        <Trans t={t} i18nKey="modals:kanpla-has-made-it-easy">
          Kanpla har gjort det nemt
          <br />
          at bestille skolemælk til din skole
        </Trans>
      </p>
      <img src="/images/skolemælk.jpg" alt="" />{" "}
      <ButtonWithLoading
        text={t("modals:create-your-subscription")}
        loading={false}
        onClick={() => {
          router.push(
            `/app/m/${modules.find((m) => m.type === "subscription")?.id}`
          );
          dismiss();
        }}
      />
    </Modal>
  );
};

export default SubscriptionPopup;
