import { useTranslation } from "react-i18next";
import React from "react";
import { AppContext } from "../../contextProvider";
import { useContainer } from "unstated-next";
import { hasAccessToModule } from "@kanpla/system";
import classnames from "classnames";

interface Props {
  totalProducts: number;
  totalPrice: number;
  onClick: () => void;
}

const BasketPreview = (props: Props) => {
  const { totalProducts, totalPrice, onClick } = props;
  const { t } = useTranslation(["mealplan2", "translation"]);

  const { modules, child, school } = useContainer(AppContext);

  const tabs = modules.reduce((acc, m) => {
    const hide = m?.displayOptions?.hideMobileTab;
    if (hide) return acc;

    if (m.type === "flex") {
      const hasAccess = hasAccessToModule({ module: m, school, child });

      if (hasAccess.individual) acc.push(m.name);
      if (hasAccess.bulk) acc.push(m.name);
    } else {
      acc.push(m.name);
    }

    return acc;
  }, []);

  if (totalProducts === 0) return null;

  return (
    <div
      className={classnames({
        "inset-x-2 mx-auto max-w-sm cursor-pointer fixed px-4 py-3 z-20 flex justify-between items-center rounded-md shadow-md bg-primary-main text-primary-contrast hover:opacity-80 transition-opacity ease-in-out":
          true,
        "bottom-24": tabs.length > 1,
      })}
      style={{
        bottom: tabs.length <= 1 && "calc(1rem + env(safe-area-inset-bottom))",
      }}
      onClick={onClick}
    >
      <span>
        {t("mealplan2:plural.products", {
          count: totalProducts,
          totalProducts,
        })}
      </span>
      <span className="font-semibold">
        {t("translation:amount-with-value", { value: totalPrice })}
      </span>
    </div>
  );
};

export default BasketPreview;
