import {
  configLabel,
  db,
  FieldValue,
  fn,
  getChildDisplayName,
  getChildGroupName,
  validateSelectors,
} from "@kanpla/system";
import { Child, School } from "@kanpla/types";
import { ButtonSubmit, FormInput, SelectorsInput } from "@kanpla/ui";
import { Form, Input, message } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import KanplaCard from "./child/KanplaCard";
import SelectSchool from "./SelectSchool";

interface Props {
  submit: (data: any) => void;
  edit?: boolean;
  child?: Child;
  isChildSalesplace?: boolean;
}

const ChildForm = ({
  submit,
  child,
  edit,
  isChildSalesplace = false,
}: Props) => {
  const { t } = useTranslation(["forms", "translation", "modals"]);
  const { children, customBranding, allModules, defaultReference, userId } =
    useContainer(AppContext);
  const [allSchools = []] = useCollectionDataOnce<School>(
    db.collection("schools"),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const schools = allSchools.filter((s) => {
    if (child?.schoolId === s.id) return true;
    return !s.hidden && !s.deleted;
  });

  const [name, setName] = useState((child && child.name) || "");
  const [newCode, setNewCode] = useState("");
  const [newReference, setNewReference] = useState<string>(null);
  const [school, setSchool] = useState<School>(null);

  useEffect(() => {
    setNewReference(defaultReference || null);
  }, [defaultReference]);

  const [selectors, setSelectors] = useState((child && child.selectors) || {});

  const [haveCard, setHaveCard] = useState(
    child && child.kanplaId ? (child.kanplaId >= 100000 ? true : false) : true
  );
  const [kanplaId, setKanplaId] = useState(
    (child && child.kanplaId >= 100000 && child.kanplaId) || ""
  );
  // const [picture, setPicture] = useState((child && child.picture) || '')
  const [loading, setLoading] = useState(false);

  const allAvailableCodes = [
    ...allModules
      .filter((module) =>
        module?.scope?.generatedSchoolIds?.includes(child?.school_ref?.id)
      )
      .map((module) => {
        if (!module || isEmpty(module)) return;

        const schoolSpecificSettings = school?.modules?.[module.id] || {};

        return [
          ...(module.config?.codes || []),
          ...(module.config?.bulkCodes || []),
          ...((!schoolSpecificSettings.individualDisabled &&
            schoolSpecificSettings.codes) ||
            []),
          ...((schoolSpecificSettings.bulkEnabled &&
            schoolSpecificSettings.bulkCodes) ||
            []),
        ];
      })
      .flat(),
  ];

  const hasReferencePlugin =
    allModules
      .filter((module) =>
        module?.scope?.generatedSchoolIds?.includes(
          child?.school_ref?.id || child?.schoolId
        )
      )
      .filter((module) => module.plugins?.invoiceReference?.active).length > 0;

  const hasAccessCodes = allAvailableCodes.length > 0;

  const schoolTrigger = schools.map((s) => s.id).join("-");
  const childrenTrigger = children.map((s) => s.id).join("-");

  // keep school list updated
  useEffect(() => {
    if (edit) {
      const targetSchool = schools.find((s) => s.id === child?.schoolId);
      if (targetSchool) setSchool(targetSchool);
    } else {
      const firstChildSchool =
        children && children[0]
          ? schools.filter(
              (s) => s.id === (child?.schoolId || children[0]?.school_ref?.id)
            )[0]
          : null;

      if (firstChildSchool) setSchool(firstChildSchool);
    }
  }, [schoolTrigger, childrenTrigger]);

  const validate = async () => {
    try {
      setLoading(true);

      // School ref
      const school_ref = school?.ref;
      if (!school_ref && !isChildSalesplace) {
        setLoading(false);
        message.error(t("forms:message.error.invalid-point-of-sale"));
        return;
      }

      // Access code
      let codes = child?.codes || [];
      if (newCode) {
        const codeIsValid = allAvailableCodes.includes(newCode);

        if (!codeIsValid) {
          message.error(
            t("translation:message.error.invalid-special-password")
          );
          setLoading(false);
          return;
        }

        if (!codes.includes(newCode)) codes.push(newCode);
      }

      // Adding/changing card (Check id)
      const newId = !child || child.kanplaId !== kanplaId;
      const changingCard = school.hasCards && haveCard && newId;

      if (changingCard) {
        // Check if exists...
        const checkId = fn.httpsCallable("idExistsEu");
        const exists = await checkId({
          kanplaId: parseInt(kanplaId + ""),
        }).then((r) => r.data);

        // If yes, abort
        if (exists) {
          message.error(t("translation:message.error.kanpla-id-already-used"));
          setLoading(false);
          return;
        }
      }

      // Removing card
      const removingCard =
        school.hasCards &&
        edit &&
        !haveCard &&
        child.kanplaId?.toString().length === 6;

      if (!validateSelectors(selectors, school?.selectors))
        throw new Error(t("modals:fill-fields"));

      // Create short name
      const displayName = getChildDisplayName(name);

      // Create group name
      const groupName = getChildGroupName(selectors);

      // Submit
      await submit({
        userId,
        name,

        selectors,
        ...(!child && {
          school_ref,
          school_name: school.name,
          schoolId: school?.id,
        }),
        ...(changingCard && { kanplaId: parseInt(kanplaId + "") }),
        ...(removingCard && {
          kanplaId: FieldValue.delete(),
          cardHEX: FieldValue.delete(),
        }),

        // Auto generated fields (for faster data processing)
        displayName,
        groupName,

        // Access code
        codes,

        // Default reference
        defaultReference: newReference,
      });
    } catch (e) {
      message.error(e?.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={validate}
      // To catch the submit event on a custom form
      onSubmitCapture={(event) => event.preventDefault()}
      requiredMark={false}
    >
      <Form.Item
        label={t("translation:user-name")}
        name="new-name-input"
        rules={[
          {
            required: true,
            message: t("translation:form.errors.required-a", {
              value: t("translation:values.user-name"),
            }),
          },
        ]}
        labelCol={configLabel({ span: 24 })}
        initialValue={name}
      >
        <Input
          placeholder={t("translation:user-name")}
          onChange={(e) => setName(e.target.value)}
          size="large"
        />
      </Form.Item>

      {!isChildSalesplace && (
        <Form.Item className="relative">
          <label htmlFor="school-input">
            <p className="mb-2">
              {customBranding?.partnerId
                ? t("translation:form.labels.corporation")
                : t("translation:form.labels.point-of-sale")}
            </p>
            <div className="text-left mb-6">
              <SelectSchool
                school={school}
                schools={schools}
                setSchool={setSchool}
                edit={edit}
                company={typeof customBranding?.partnerId !== "undefined"}
                allowedSchools={children?.map((child) => child?.schoolId)}
              />
            </div>
          </label>

          <SelectorsInput
            selectors={selectors}
            setSelectors={setSelectors}
            school={school}
          />
        </Form.Item>
      )}

      {school?.hasCards && (
        <KanplaCard
          haveCard={haveCard}
          setHaveCard={setHaveCard}
          kanplaId={kanplaId}
          setKanplaId={setKanplaId}
        />
      )}

      {hasReferencePlugin && (
        <FormInput
          label={t("translation:form.labels.default-reference")}
          note={t("translation:form.notes.default-reference-note")}
          value={newReference}
          setValue={(v) => setNewReference(v)}
          name="reference-id"
        />
      )}

      {hasAccessCodes && (
        <FormInput
          label={t("translation:form.labels.special-password")}
          note={t("translation:form.notes.grandet-access")}
          value={newCode}
          setValue={(v) => setNewCode(v)}
          name="special-password"
        />
      )}

      <Form.Item className="mb-0">
        <ButtonSubmit
          loading={loading}
          text={
            edit
              ? `${t("translation:save")} ${t("translation:changes")}`
              : t("forms:form.buttons.create-account")
          }
          loadingText={t("translation:loading")}
        />
      </Form.Item>
    </Form>
  );
};

export default ChildForm;
