import { Plugins } from "@kanpla/types";
import {
  isDateStringPattern,
  ReceiptWrapper,
  SafetyPaymentBackground,
  TextInputDisplay,
  TimeInputDisplay,
} from "@kanpla/ui";
import { Button } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import { AppContext } from "../contextProvider";
import LineWrapper from "./LineWrapper";
import StandardOrderButton from "./StandardOrderButton";

interface Props {
  setAllowOrder: Dispatch<SetStateAction<boolean>>;
}

const Receipt = ({ setAllowOrder }: Props) => {
  const { t } = useTranslation(["translation", "flex"]);
  const { displayDate, customBranding, week, dayIndex, activePlugins } =
    useContainer(AppContext);
  const {
    pastDate,
    items,
    hasOrdered,
    order,
    setSettingsOpen,
    orderInfo,
    module,
    deadlineFormatted,
  } = useContainer(FlexContext);
  const dateSeconds = week?.[dayIndex]?.seconds;

  // const displayDate = moment.unix(week[dayIndex]?.seconds).format("dddd");
  const isPattern = isDateStringPattern(displayDate);

  // If no lunch was ordered
  if (!hasOrdered) {
    return (
      <div className="mx-auto text-center mt-10 md:mt-20">
        <div className="text-center">
          <h2 className="h600 mb-1">
            {t("translation:no-more-ordering-or-order-left", {
              word: !isPattern
                ? t("translation:values.more-ordering")
                : t("translation:values.order-left"),
              displayDate,
            })}
          </h2>
          <p className="text-text-secondary pb-4">
            {t("flex:can-order-until", { value: deadlineFormatted })}
          </p>
        </div>

        <Button
          type="primary"
          size="large"
          className="mx-auto my-6"
          disabled={pastDate}
          onClick={() => setAllowOrder(true)}
        >
          {pastDate
            ? t("flex:too-late-to-order")
            : t("flex:order-until", { value: displayDate })}
        </Button>

        <div className="mt-16">
          <LineWrapper>
            <p className="text-sm text-text-secondary mt-3 inline-block w-2/3">
              {t("flex:otherwise")}
            </p>
            <div className="flex justify-center">
              <div className="max-w-sm w-full">
                <div className="flex flex-col items-center justify-center pt-6">
                  <Button onClick={() => setSettingsOpen(true)}>
                    {t("translation:set-default-selection")}
                  </Button>
                </div>
              </div>
            </div>
          </LineWrapper>
        </div>
      </div>
    );
  }

  const firstProduct = Object.entries(order)[0];

  const product = items?.find((m) => m.id === firstProduct[0]);
  const menu = product?.dates?.[dateSeconds]?.menu;

  const config = firstProduct[1]?.config;

  const textColor = tinycolor(product?.color || "rgb(237, 242, 247)").isLight()
    ? "text-text-secondary"
    : "text-background-primary";

  const customColor =
    customBranding?.custom?.palette?.background?.secondary || "#ffffff";

  return (
    <div className="-mx-3">
      <ReceiptWrapper center className="-mt-4" svgColor={customColor}>
        <div className="opacity-25">
          <SafetyPaymentBackground customBranding={customBranding} />
        </div>
        <div className="pb-3 md:pb-6 relative z-10">
          <h4 className="uppercase font-medium text-text-secondary text-xs">
            {t("translation:order-until", { value: displayDate })}
          </h4>
          <h2 className="font-semibold text-2xl md:text-3xl text-text-primary mt-4 flex items-center h800">
            <span>
              {menu?.name || product?.name || (
                <span className="text-xl md:text-xl leading-snug block">
                  <Trans t={t} i18nKey="flex:module-no-longer-available">
                    Modulet er ikke længere tilgengeligt.
                    <br />
                    Kontakt køkkenet for mere information.
                  </Trans>
                </span>
              )}
            </span>

            {menu?.name && (
              <span
                className={`rounded-full p-1 px-3 ${textColor} text-sm md:text-base font-semibold ml-2 md:ml-4`}
                style={{
                  backgroundColor: product?.color || "rgb(237, 242, 247)",
                }}
              >
                {product?.name}
              </span>
            )}
          </h2>
          {menu && (
            <p className="text-text-primary text-sm whitespace-pre-wrap my-2">
              {menu.description}
            </p>
          )}
          {config && config[0] && (
            <div>
              {/* <h3 className="mt-6 mb-2 font-semibold text-lg text-text-primary">
                Du har tilføjet følgende varianter:
              </h3> */}
              <ul>
                {Object.values(config[0]?.options || {}).map(
                  ({ amount, name, id }) => (
                    <li className="text-text-primary text-sm" key={id}>
                      - {amount > 1 && `${amount}x`} {name}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
        {(activePlugins.timeInput || activePlugins.textInput) && (
          <ReceiptWrapper.Footer>
            <div className="text-text-secondary text-xs">
              {activePlugins.timeInput && (
                <TimeInputDisplay timeInput={orderInfo?.timeInput} />
              )}
              <TextInputDisplay
                textInput={orderInfo?.textInput}
                plugin={module?.plugins?.textInput as Plugins.TextInput}
              />
            </div>
          </ReceiptWrapper.Footer>
        )}
      </ReceiptWrapper>

      <div className="px-6 md:px-0 max-w-sm w-full mx-auto">
        <p className="text-text-primary pb-4 text-center">
          {t("translation:can-change-order-until", {
            value: deadlineFormatted,
          })}
        </p>
        <Button
          disabled={pastDate}
          type="primary"
          size="large"
          className="w-full mt-4 mb-2"
          onClick={() => {
            setAllowOrder(true);
          }}
        >
          {pastDate
            ? t("flex:too-late-to-edit-order")
            : t("flex:edit-the-order-to", { value: displayDate })}
        </Button>
      </div>

      <StandardOrderButton setOpen={setSettingsOpen} />
    </div>
  );
};

export default Receipt;
