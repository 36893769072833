import { faChevronLeft, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppPadding } from "@kanpla/system";
import { Module, TimeNavigation } from "@kanpla/types";
import { Button, Space, Tooltip } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated } from "react-spring";
import { useContainer } from "unstated-next";
import { constructSlugsFromQuery } from "apps/frontend/lib/router";
import AnonymousMenuButton from "./anonymous/AnonymousMenuButton";
import AnonymousTabs from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";
import BalanceDisplay from "./design/balance";
import NavbarSwitches from "./NavbarSwitches";
import NotificationCenter from "./NotificationCenter";
import ReceiptButton from "./ReceiptButton";
import BackButton from "./router/BackButton";
import Tabs from "./Tabs";

interface Props {
  timeNavigation?: TimeNavigation;
  withTabs?: boolean;
  disableIfBasket?: boolean;
  module: Module;
}

const NavbarInner = () => {
  const { menuOpen, setMenuOpen, child, mobile, auth, userId } =
    useContainer(AppContext);

  if (mobile) {
    if (auth.loading) return <div className="loader"></div>;

    if (!auth?.user) return <AnonymousMenuButton />;

    return (
      <Space>
        <ReceiptButton userId={userId} />
        <button
          className="flex items-center mr-3 whitespace-nowrap text-3xl text-text-primary"
          id="action-menuOpen"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={faUserCircle} />
        </button>
      </Space>
    );
  }

  if (auth.loading) return <div className="loader"></div>;

  if (auth.user)
    return (
      <Space>
        <ReceiptButton userId={userId} />
        <button
          className={`focus:no-underline items-center justify-center flex hover:bg-background-secondary rounded-lg transition-colors py-2 whitespace-nowrap px-4 text-text-primary border`}
          id="action-menuOpen"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={faUserCircle} className="text-xl" />
          <div className="text-sm ml-2">
            {child?.displayName ? (
              <span className="font-semibold">{child.displayName}</span>
            ) : (
              "Min konto"
            )}
          </div>
        </button>
      </Space>
    );

  return <AnonymousMenuButton />;
};

const Navbar = (props: Props) => {
  const { t } = useTranslation(["components", "translation"]);

  const {
    appLoading,
    customBranding,
    posonePartnerData,
    mobile,
    withCredit,
    setPaymentOpen,
    auth,
  } = useContainer(AppContext);

  const { shouldAddPadding } = useAppPadding();

  const router = useRouter();

  const hasBackLinkQuery = !!router.query?.backLink;

  const back = () => {
    const newQuerySlugs = constructSlugsFromQuery({
      router,
      stringToReplace: "?backLink=",
    });

    const path = `${newQuerySlugs.join("/")}`;

    router.push(path);
  };

  return (
    <animated.div
      id="navbar"
      className="bg-background-primary relative lg:border-b"
      style={{
        transition: "0.3s ease-in-out",
        zIndex: 21,
      }}
    >
      {/* IOS statusbar overlay */}
      <div
        className=" bg-background-primary z-20"
        style={{
          height: `calc(var(--status-bar-height) - ${
            shouldAddPadding ? "6px" : "0px"
          } )`,
          transition: "0.3s ease-in-out",
          zIndex: 29,
        }}
      />

      <div
        className="px-2 lg:px-4 flex justify-between items-center bg-background-primary border-divider-main text-text-secondary container"
        style={{ height: 64 }} // increase height to 62 if using new switches on the navbar
      >
        {customBranding?.posone && (
          <Tooltip title={t("components:back-to-posone")} placement="right">
            <a
              href={`https://${
                posonePartnerData?.prefix || customBranding?.url
              }.smartbetaling.dk/#dashboard`}
              aria-label={t("components:back-to-posone")}
              title={t("components:back-to-posone")}
              className="mr-1.5"
            >
              <Button type="primary" style={{ backgroundImage: "none" }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            </a>
          </Tooltip>
        )}
        {!appLoading ? (
          hasBackLinkQuery ? (
            <BackButton
              label={t("translation:back")}
              onClick={back}
              buttonProps={{ type: "text" }}
              buttonClassName="font-semibold"
              labelClassName="text-text-primary"
              iconClassName="text-text-primary"
            />
          ) : (
            <NavbarSwitches />
          )
        ) : (
          <div className="h-8 w-28 rounded-sm loader" />
        )}

        <div className="ml-auto flex items-center">
          {withCredit && mobile && (
            <div className="mr-3">
              <BalanceDisplay setPaymentOpen={setPaymentOpen} />
            </div>
          )}
        </div>
        {!mobile && (auth.user ? <Tabs /> : <AnonymousTabs />)}
        {!appLoading && <NotificationCenter />}
        <NavbarInner />
      </div>
    </animated.div>
  );
};

export default Navbar;
