import { faBadgeCheck, faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLastFlexOrderChange, isBelowMinimum } from "@kanpla/system";
import { Button, message, Space } from "antd";
import { useTranslation } from "react-i18next";
import { default as React, Dispatch, SetStateAction, useEffect } from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import { AppContext } from "../contextProvider";
import UIHeader from "../Header";
import LineWrapper from "./LineWrapper";
import MenuModule from "./MenuModule";
import StandardOrderButton from "./StandardOrderButton";

interface Props {
  setAllowOrder: Dispatch<SetStateAction<boolean>>;
}

const MenuBulk = ({ setAllowOrder }: Props) => {
  const { t } = useTranslation(["flex", "translation"]);
  const { date, school } = useContainer(AppContext);
  const {
    items,
    pastDate,
    deadline,
    deadlineFormatted,
    softDeadlineMaxAmount,
    defaultDate,
    order,
    orderDocument,
    numberOfItems,
    hasOrdered,
    isBulk,
    setSettingsOpen,
    submit,
  } = useContainer(FlexContext);

  const { availableChanges, lastOrder } = getLastFlexOrderChange({
    order: orderDocument,
    date,
    deadline,
    localOrder: order,
    softDeadlineMaxAmount,
  });

  const setNoLunch = () => {
    submit({}, {});
  };

  // If minimum exists, it has to be more than that
  const isBelowMin =
    isBulk &&
    isBelowMinimum({
      school,
      localOrder: order,
    });

  // Check soft deadlines max order changes
  useEffect(() => {
    if (!isBulk || !pastDate) return;

    if (availableChanges < 0) message.error(t("flex:message.error.exceeded"));
  }, [order]);

  return (
    <div className="max-w-4xl mx-auto">
      <div className="pb-6 pt-1">
        <Button
          className=" mb-3"
          type="link"
          onClick={() => setAllowOrder(null)}
        >
          <Space>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t("flex:back-to-confirmation")}</span>
          </Space>
        </Button>
        <div className="mb-12 flex justify-between ">
          <UIHeader
            defaultDate={defaultDate}
            showPastDate={true}
            pastDate={pastDate}
            deadlineFormatted={deadlineFormatted}
            softMaxAmount={softDeadlineMaxAmount}
            className="mx-auto w-full"
            centerSubtitle={false}
          />
        </div>

        <div
          style={{
            columns: "2 300px",
            breakInside: "avoid",
            columnFill: "balance",
            WebkitColumnFill: "balance",
            MozColumnFill: "balance",
            pageBreakInside: "avoid",
            columnGap: 32,
            MozColumnGap: 32,
            WebkitColumnGap: 32,
          }}
        >
          {items.map((item, index) => {
            return (
              <div
                key={index}
                style={{ pageBreakInside: "avoid", breakInside: "avoid" }}
                className="pb-8 md:-mx-2 inline-block w-full text-text-primary"
              >
                <MenuModule
                  item={item}
                  availableChanges={availableChanges}
                  lastOrder={lastOrder}
                />
              </div>
            );
          })}
        </div>

        {!isBulk && (
          <div className="w-full flex justify-center">
            <div className="relative" style={{ width: "max-content" }}>
              <Button
                disabled={pastDate}
                type="primary"
                size="large"
                className={`block mt-4`}
                onClick={setNoLunch}
              >
                {t("translation:no-breakfast")}
              </Button>
              {!hasOrdered && (
                <span className="h-7 w-7 p-1 rounded-full absolute flex items-center justify-center -right-3 top-1 bg-background-secondary text-primary-main">
                  <FontAwesomeIcon icon={faBadgeCheck} className="z-10" />
                </span>
              )}
            </div>
          </div>
        )}

        {isBulk && (
          <LineWrapper>
            <p className="text-text-secondary pt-3 pb-4 items-center flex justify-center relative">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl" />
              {t("flex:ordering-has-been-confirmed", { value: numberOfItems })}
            </p>
          </LineWrapper>
        )}

        <StandardOrderButton setOpen={setSettingsOpen} />
      </div>
    </div>
  );
};

export default MenuBulk;
