import { FormInstance } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import OrderInfoFields from "../../OrderInfoFields";
import { OrderInfo } from "@kanpla/types";

interface Props {
  form: FormInstance;
  showOrderInfo: boolean;
  newOrderInfo: OrderInfo;
}

const OrderInfo = ({ form, showOrderInfo, newOrderInfo }: Props) => {
  const { t } = useTranslation(["mealplan2"]);
  if (!showOrderInfo) return null;

  return (
    <div className="mt-6 border-t pt-6">
      <h1 className="h500 pb-3">{t("mealplan2:order-info")}</h1>
      <OrderInfoFields form={form} initialValues={newOrderInfo} />
    </div>
  );
};

export default OrderInfo;
