import { PaymentProvider } from "@kanpla/types";
import { Alert, Checkbox } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import DisplayDefaultCard from "apps/frontend/components/payment/design/DisplayDefaultCard";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import PaidFromCredit from "../PaidFromCredit";

type PaymentProviderBasket = PaymentProvider | "credit";

interface Props {
  setRememberCard: Dispatch<SetStateAction<boolean>>;
  rememberCard: boolean;
  setSelectedPaymentMethod: Dispatch<SetStateAction<PaymentProviderBasket>>;
  selectedPaymentMethod: PaymentProviderBasket;
  basketPrice: number;
  requiresBillingGo?: boolean;
  hasMoney: boolean;
}

interface WrapperProps {
  title: string;
}

const PaymentWrapper: FC<WrapperProps> = ({ title, children }) => (
  <div className="flex pt-1 md:pt-2 flex-col">
    <h1 className="h500 pb-3">{title}</h1>
    {children}
  </div>
);

const Payment = ({
  setRememberCard,
  rememberCard,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  basketPrice,
  requiresBillingGo,
  hasMoney,
}: Props) => {
  const { t } = useTranslation(["mealplan2", "translation"]);
  const { activePlugins, balance, card, module, posoneBalance } =
    useContainer(AppContext);

  const hasOnlyCredit = !activePlugins.kanplaGo && !activePlugins.payPerOrder;
  const paysFromCredit =
    (hasOnlyCredit || hasMoney) && module.paymentMethod === "credit";

  const paysFromPosone = module.paymentMethod === "posOne";

  const title = t("mealplan2:payment");

  if (paysFromCredit)
    return (
      <PaymentWrapper title={title}>
        <PaidFromCredit.Alert balance={balance} cost={basketPrice} />
      </PaymentWrapper>
    );

  if (paysFromPosone)
    return (
      <PaymentWrapper title={title}>
        <PaidFromCredit.Alert balance={posoneBalance} cost={basketPrice} />
      </PaymentWrapper>
    );

  if (requiresBillingGo || module.paymentMethod === "billing")
    return (
      <PaymentWrapper title={title}>
        <Alert showIcon message={t("mealplan2:order-invoiced")} />
      </PaymentWrapper>
    );

  return (
    <PaymentWrapper title={title}>
      <DisplayDefaultCard
        card={card}
        basketPrice={basketPrice}
        onSelect={(method: PaymentProviderBasket) =>
          setSelectedPaymentMethod(method)
        }
        defaultPaymentMethod={selectedPaymentMethod}
      />
      {selectedPaymentMethod === "card" && !card && (
        <div className="flex justify-center mt-2">
          <Checkbox
            onChange={() => setRememberCard(!rememberCard)}
            checked={rememberCard}
            className="text-text-primary"
          >
            {t("translation:save-card-for-later-payment")}
          </Checkbox>
        </div>
      )}
    </PaymentWrapper>
  );
};

export default Payment;
