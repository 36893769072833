import { useTranslation } from "react-i18next";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Slide from "./Slide";

const Welcome = ({ services }) => {
  const { t } = useTranslation(["introduction"]);
  const { child, school } = useContainer(AppContext);

  const providerName = school?.contact?.name || "Kanpla";

  let content = (
    <>
      <p className="mb-3">
        {t(
          "introduction:clarifications.default.welcome-component.first-description"
        )}
      </p>
      <p>
        {t(
          "introduction:clarifications.default.welcome-component.second-description"
        )}
      </p>
    </>
  );

  // Admin
  if (services.hasFlexBulk)
    content = (
      <>
        <p className="mb-3">
          {t(
            "introduction:clarifications.flex-bulk.welcome-component.first-description",
            { value: providerName }
          )}
        </p>
        <p>
          {t(
            "introduction:clarifications.flex-bulk.welcome-component.second-description"
          )}
        </p>
      </>
    );

  // Medarbejder FoodCompany
  if (services.hasFlexViewOnly)
    content = (
      <>
        <p className="mb-3">
          {t(
            "introduction:clarifications.flex-view-only.welcome-component.first-description"
          )}
        </p>
        <p>
          {" "}
          {t(
            "introduction:clarifications.flex-view-only.welcome-component.second-description"
          )}
        </p>
      </>
    );

  return (
    <Slide
      // backgroundImage="/images/introduction/cooking.jpg"
      title={t("introduction:welcome-to-your-school", {
        childName: child?.name,
        schoolNameOrYour: services.hasFlexBulk
          ? t("introduction:values.school", { value: school.name })
          : t("introduction:values.your"),
      })}
    >
      {content}
    </Slide>
  );
};

export default Welcome;
