import { faEmptySet, faLoader } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronRight, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  db,
  getDaysInRange,
  getTodayTimestamp,
  Timestamp,
  useCollectionListener,
  UseOffers,
} from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import { DatePanelPicker, DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import ModuleDescription from "apps/frontend/components/ModuleDescription";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment, { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "..";
import { MealplanContext } from "../..";
import OrderCard from "../OrderCard";

const Overview = () => {
  const { t, i18n } = useTranslation(["translation", "mealplan2", "design"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { setCurrentView, setEditingOrder } = useContext(
    MealplanMeetingContext
  );

  const { childId, userId, week, dayIndex, schoolId, child } =
    useContainer(AppContext);

  const { defaultDate, moduleId, module, handleDateChange } =
    useContainer(MealplanContext);

  const [daysInCalendar, setDaysInCalendar] = useState<Array<number>>([]);
  const [loadingHolidays, setLoadingHolidays] = useState(false);

  const { holidayDates } = UseOffers({
    schoolId,
    moduleId,
    allDateSeconds: daysInCalendar,
    setLoading: setLoadingHolidays,
    groupName: child?.groupName,
  });

  const [newOrderOpen, setNewOrderOpen] = useState(false);

  const today = getTodayTimestamp({ Timestamp }).seconds;

  const [selectedDate, setSelectedDate] = useState(
    moment(week?.[dayIndex].toDate())
  );

  const isDisabledDate = useCallback(
    (date: Moment) => {
      const isPastDeadline = defaultDate?.seconds > date.unix();
      if (isPastDeadline) return true;

      const isWeekend = date.get("day") === 0 || date.get("day") === 6;
      if (isWeekend) return true;

      const isHoliday = holidayDates?.[date.unix()];
      if (isHoliday) return true;

      return false;
    },
    [defaultDate?.seconds, holidayDates]
  );

  useEffect(() => {
    const disabledSelected = isDisabledDate(selectedDate);
    if (!disabledSelected) return;
    setSelectedDate((d) => moment.unix(d.unix() + 86400));
  }, [selectedDate, isDisabledDate]);

  const [allOrders = [], nextThreeOrdersLoading] =
    useCollectionListener<OrderMealplan>(
      db
        .collection("orders")
        .where("childId", "==", childId)
        .where("userId", "==", userId)
        .where("moduleId", "==", moduleId)
        .where("dateSeconds", ">=", today)
        .orderBy("dateSeconds")
        .limit(15)
    );

  const nextThreeOrders = allOrders
    .filter((o) => !o.deleted && !isEmpty(o.order))
    .slice(0, 3);

  const getVisibleCalendarDates = (date: Moment) => {
    const firstInCalendar = moment(date)
      .utc()
      .startOf("month")
      .startOf("isoWeek")
      .subtract(1, "week");

    const lastInCalendar = moment(date)
      .utc()
      .endOf("month")
      .endOf("isoWeek")
      .add(1, "week");

    const daysInBetween = getDaysInRange(
      firstInCalendar,
      lastInCalendar,
      "unix"
    ) as Array<number>;

    setDaysInCalendar(daysInBetween);
  };

  useEffect(() => {
    getVisibleCalendarDates(selectedDate);
  }, []);

  // TODO: get the orders of the user

  return (
    <div className="w-full h-full flex justify-center items-center flex-col pt-10 p-4 md:p-14 md:pt-14">
      <div className="flex flex-col justify-center">
        <ModuleDescription align="center" module={module} />

        <div className="mt-2 md:mt-4 mx-auto">
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setNewOrderOpen(true);
            }}
            data-cy="new-order-btn"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("translation:new-order")}
          </Button>
          <DrawerOrModal
            title={t("translation:new-order")}
            subtitle={t("mealplan2:what-day-should")}
            open={newOrderOpen}
            setOpen={setNewOrderOpen}
            actions={[
              {
                label: t("translation:continue"),
                onClick: () => {
                  handleDateChange(selectedDate);
                  setCurrentView("ordering");
                  setEditingOrder(null);
                },
                type: "primary",
                dataCy: "action-new-order",
              },
            ]}
          >
            <div className="pt-3 -mx-2 flex justify-center">
              <DatePanelPicker
                className="opacity-0"
                popupStyle={{ transition: "none" }}
                loading={loadingHolidays}
                loadingText={t("design:loading", {
                  value: t("design:loading-value.closing-days"),
                })}
                dropdownClassName={classnames({
                  "loader pointer-events-none": loadingHolidays,
                })}
                value={selectedDate}
                onChange={setSelectedDate}
                onPanelChange={(value) => getVisibleCalendarDates(value)}
                disabledDate={(date) => isDisabledDate(date)}
              />
            </div>
          </DrawerOrModal>

          {/* {!nextThreeOrdersLoading && isEmpty(nextThreeOrders) && (
            <OrderHereArrow />
          )} */}
        </div>
      </div>
      <div className="w-full mt-20 md:mt-32 max-w-6xl mx-auto">
        <h1 className="h800 text-text-secondary text-xl lg:text-2xl font-semibold">
          {t("mealplan2:my-orders")}
        </h1>
        {/* If no orders:
        <p className="text-text-secondary mt-2">
          Når du har bestilt, vil dine ordrer blive vist her
        </p> 
        */}
        <div className="flex flex-col w-full">
          <div className="mt-2 md:mt-6 flex flex-col md:flex-row w-full">
            {nextThreeOrdersLoading && (
              <div className="w-full py-16 text-text-secondary text-lg text-center shadow-inner flex flex-col items-center rounded-lg bg-background-secondary">
                <FontAwesomeIcon
                  icon={faLoader}
                  size="4x"
                  className="fa-spin"
                />
                <p className="m-6 font-semibold">
                  {t("mealplan2:loading-orders")}
                </p>
              </div>
            )}
            {!nextThreeOrdersLoading &&
              nextThreeOrders.map((orderDocument, index) => (
                <OrderCard key={index} index={index} order={orderDocument} />
              ))}
            {!nextThreeOrdersLoading && nextThreeOrders.length === 0 && (
              <div className="w-full py-16 text-text-secondary text-lg text-center shadow-inner flex flex-col items-center rounded-lg bg-background-secondary">
                <FontAwesomeIcon icon={faEmptySet} size="4x" />
                <p className="m-6 font-semibold">
                  {t("mealplan2:no-orders-yet")}
                </p>
              </div>
            )}
          </div>
          <div className="w-full mt-10 flex justify-center">
            <Button onClick={() => setCurrentView("history")}>
              {t("mealplan2:see-all")}
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
