import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonNext, CarouselContext } from "pure-react-carousel";
import React, { useContext, useEffect, useState } from "react";

interface Props {
  onClose: () => void;
}

const ButtonLast = ({ onClose }: Props) => {
  const { t } = useTranslation(["translation"]);
  const carouselContext = useContext(CarouselContext);

  const [isLastSlide, setIsLastSlide] = useState(false);

  useEffect(() => {
    function onChange() {
      setIsLastSlide(
        carouselContext.state.currentSlide + 1 ===
          carouselContext.state.totalSlides
      );
    }
    onChange();
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  if (isLastSlide)
    return <Button onClick={onClose}>{t("translation:done")}</Button>;

  return (
    <ButtonNext>
      <Button>{t("translation:next")}</Button>
    </ButtonNext>
  );
};

export default ButtonLast;
