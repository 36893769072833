import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React from "react";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import useMeasure from "../../../hooks/useMeasure";

const Slider = ({ submit, checkoutLoading, label = "", disabled = false }) => {
  const { t } = useTranslation(["translation", "mealplan2"]);
  const [{ transform, opacity }, set] = useSpring(() => ({
    transform: `translateX(0px)`,
    opacity: 1,
  }));

  const [measuredEl, measuredProps] = useMeasure();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const containerWidth = measuredProps?.width;
  const maxDrag = containerWidth - 64;

  const bind = useGesture({
    onDrag: ({ down, movement: [x, y] }) => {
      if (x < 0) x = 0;
      if (x > maxDrag) x = maxDrag;
      const percentage = x / maxDrag;
      set({
        transform: down ? `translateX(${x}px)` : `translateX(0px)`,
        // opacity: down ? 1 - percentage : 1,
        opacity: down ? 1 - percentage : 1,
        immediate: down,
      });
    },
    onDragEnd: ({ down, movement: [x, y] }) => {
      const percentage = x / maxDrag;

      if (percentage > 0.9) submit();
    },
  });

  const bgColor = disabled ? "bg-background-secondary" : "bg-primary-main";

  return (
    <div
      className="p-1 h-14 relative whitespace-nowrap overflow-clip flex"
      {...measuredEl}
    >
      <animated.div
        className={`w-full h-full rounded-lg absolute inset-0 ${
          checkoutLoading ? "loader" : bgColor
        }`}
      />
      <animated.div
        className={`font-white absolute whitespace-nowrap font-medium overflow-clip text-lg self-center justify-self-center select-none w-full text-center ${
          disabled ? "text-text-secondary" : "sliding-gradient-animation"
        } ${checkoutLoading ? "" : "text-primary-contrast"}`}
        style={{
          opacity,
        }}
      >
        {checkoutLoading
          ? t("translation:loading")
          : label || t("mealplan2:check-out")}
      </animated.div>
      <animated.div
        {...bind()}
        style={{
          transform,
        }}
        className={`w-12 h-12 rounded-lg flex items-center justify-center ${
          disabled
            ? "text-text-secondary pointer-events-none"
            : "text-primary-contrast animate-pulse"
        } relative ${checkoutLoading && "opacity-0"}`}
      >
        <FontAwesomeIcon icon={faAngleRight} size="lg" />
      </animated.div>
    </div>
  );
};

export default Slider;
