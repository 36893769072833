import { Child, School } from "@kanpla/types";
import { useEffect, useState } from "react";

interface ServiceList {
  hasFlex: boolean;
  hasFlexBulk: boolean;
  hasFlexViewOnly: boolean; // flex allowed on school, but the individual has not edit rights
  hasMeeting: boolean;
  hasKanplaGo: boolean;
  hasProducts: boolean;
  subscriptions: boolean;
}

interface Props {
  school: School;
  child: Child;
}

export const useServices = (props: Props) => {
  const { school, child } = props;
  const defaultServices = {
    hasFlex: false,
    hasFlexBulk: false,
    hasFlexViewOnly: false,
    hasMeeting: false,
    hasKanplaGo: false,
    hasProducts: false,
    subscriptions: false,
  };

  const [services, setServices] = useState<ServiceList>(defaultServices);
  const [servicesLoading, setServicesLoading] = useState(true);

  // Helper function checking access code
  const hasAccess = (schoolCodes: Array<string>) => {
    const noCodeNeeded = !schoolCodes || schoolCodes.length === 0;
    const childCodes = child?.codes || [];
    const valid =
      noCodeNeeded || !!childCodes.find((code) => schoolCodes.includes(code));

    return valid;
  };

  //  When child or the school changes, check for available services
  const trigger = school?.id;
  useEffect(() => {
    setServicesLoading(true);
    setServices(defaultServices);

    if (!school) return;

    const hasFlexRights = hasAccess(school?.codes?.flex);
    const hasFlexBulkRights = hasAccess(school?.codes?.flexBulk);

    const hasFlex = school.hasFlex && hasFlexRights;
    const hasFlexBulk = school.hasFlexBulk && hasFlexBulkRights;
    const hasFlexViewOnly =
      school.hasFlexBulk &&
      !hasFlexBulkRights &&
      (!school.hasFlex || !hasFlexRights); // either doesn't have individual flex, or doesn't have access

    const hasMeeting = school.hasMeeting && hasAccess(school?.codes?.meeting);

    const hasKanplaGo = school.hasKanplaGo || false;

    const subscriptions = school.subscriptions || false;
    const hasProducts = school.hasProducts || false;

    setServices({
      hasFlex,
      hasFlexBulk,
      hasFlexViewOnly,
      hasMeeting,
      hasKanplaGo,
      subscriptions,
      hasProducts,
    });
    setServicesLoading(false);
  }, [trigger]);

  return { services, servicesLoading };
};
