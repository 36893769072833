import {
  ActionsAuthenticationModal,
  DataAuthenticationModal,
} from "@kanpla/types";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const AnonymousMenuButton = () => {
  const { t } = useTranslation(["translation", "anonymous"]);
  const {
    setOpenAuthenticationModal,
    setDataAuthenticationModal,
    fromAPrivateModule,
  } = useContainer(AppContext);

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal
  ) => {
    setDataAuthenticationModal(() => {
      const newState: DataAuthenticationModal = {
        title,
        subtitle,
        action,
      };
      return newState;
    });
    setOpenAuthenticationModal(true);
  };

  const getAction = (type: ActionsAuthenticationModal) => {
    setDataForAuthenticationModalAndOpenModal(
      type === "login"
        ? t("translation:welcome-back")
        : t("translation:sign-up"),
      type === "login"
        ? t("anonymous:modal.subtitles.info-authenticate-login")
        : t("anonymous:modal.subtitles.info-authenticate-signup"),
      type
    );
  };

  return (
    <>
      <Button
        onClick={() => {
          getAction("login");
        }}
        type="primary"
        className="mr-2"
        data-cy="login-btn"
        disabled={fromAPrivateModule}
      >
        {t("translation:log-in")}
      </Button>
      <Button
        onClick={() => {
          getAction("signup");
        }}
        disabled={fromAPrivateModule}
        data-cy="signup-btn"
      >
        {t("translation:sign-up")}
      </Button>
    </>
  );
};

export default AnonymousMenuButton;
