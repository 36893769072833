import { faLeaf } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  capitalizeFirstLetter,
  detectProductPrice,
  isDatePastDeadline,
  scroll2Elem,
  useIsUserScrolling,
} from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Category } from "@kanpla/ui";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserverRef } from "rooks";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import { Deadline } from "./DeadlineInfo";
import Product from "./Product";

const TOP_OFFSET = 250;

const getThreshold = (length = 10) => {
  return Array(length)
    .fill(0)
    .map((e, index) => (index + 1) * (1 / length));
};

interface Props {
  name: string;
  items: Array<CombinedOfferItem>;
  index: number;
  showChildrenAnyway?: boolean;
  isHidden?: boolean;
  overrideVisible?: boolean;
}

const ProductCategory = ({
  name,
  index,
  items = [],
  showChildrenAnyway = false,
  isHidden = false,
  overrideVisible = false,
}: Props) => {
  const { t } = useTranslation(["translation"]);
  const { dayIndex, week, schoolId, module, navbarHasContent } =
    useContainer(AppContext);
  const {
    hasRequiredProduct,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
    selectedCategoryData,
    setSelectedCategoryData,
  } = useContainer(MealplanContext);

  const scrollRef = useRef<HTMLDivElement>();
  const isScrolling = useIsUserScrolling();

  /** Select category in horizontal menu when the category is in viewport. */
  const interceptCallback = useCallback(
    ([entry]) => {
      if (!isScrolling || selectedCategoryData.index === index) return;
      const {
        boundingClientRect: { top, bottom },
      } = entry;

      if (TOP_OFFSET <= bottom && TOP_OFFSET >= top) {
        setSelectedCategoryData({ index, trusted: true });
      }
    },
    [index, setSelectedCategoryData, selectedCategoryData, isScrolling]
  );

  const [interceptionRef] = useIntersectionObserverRef(interceptCallback, {
    threshold: getThreshold(20),
    rootMargin: `-${TOP_OFFSET}px 0px 0px ${TOP_OFFSET}px`,
  });

  /** Handle smooth scrolling when category is selected. */
  useEffect(() => {
    if (selectedCategoryData.trusted || selectedCategoryData.index !== index)
      return;

    scroll2Elem(scrollRef.current, { offsetY: TOP_OFFSET - 1 });
  }, [selectedCategoryData, index]);

  const allPastDeadline = isDatePastDeadline({
    date: week[dayIndex],
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  const filteredItems = items.filter((item) => {
    // Hide if it's a required product
    const hiddenRequredProduct =
      hasRequiredProduct &&
      module?.plugins?.requiredProduct?.productId === item.productId;
    if (hiddenRequredProduct) return null;

    return !hiddenRequredProduct;
  });

  if (!filteredItems.length) return null;

  // Seeing if there is a common deadline
  const commonDeadline: number | boolean = (() => {
    const productDeadlines = filteredItems.map((item) =>
      typeof item.individualDeadline === `number`
        ? item.individualDeadline
        : deadline
    );

    const uniqueProductDeadlines: any = [...new Set(productDeadlines)];
    return uniqueProductDeadlines.length === 1
      ? uniqueProductDeadlines[0]
      : false;
  })();

  const isSameAsMainDeadline = commonDeadline === deadline;

  const header = commonDeadline && !isSameAsMainDeadline && (
    <Deadline
      date={week[dayIndex]}
      individualDeadline={commonDeadline}
      className="ml-3 mr-auto inline-block"
    />
  );

  const displayName =
    capitalizeFirstLetter(name) ||
    module?.text?.["noCategory/text"] ||
    t("translation:dish-of-the-day");

  return (
    <div className="boundary-element -mx-1" ref={interceptionRef}>
      <div ref={scrollRef} />
      <Category
        name={
          <>
            {displayName || ""}
            {displayName === "Leftovers" ? (
              <FontAwesomeIcon
                icon={faLeaf}
                color="#298f54"
                className="ml-2"
                size="sm"
              />
            ) : (
              ""
            )}
            {header || ""}
          </>
        }
        top={!navbarHasContent && 0}
      >
        {filteredItems.map((item) => {
          const isPastDeadline = isDatePastDeadline({
            date: week[dayIndex],
            deadline: item?.individualDeadline,
            deadlineExcludingWeekends,
            deadlineWeekRelative,
          });
          return (
            <Product
              product={{
                ...item,
                price: detectProductPrice(item, module, schoolId),
              }}
              key={`${item.productId}-${week[dayIndex]?.seconds}`}
              commonDeadline={commonDeadline}
              pastDeadline={
                (isHidden && !showChildrenAnyway) ||
                (allPastDeadline && !item.individualDeadline) ||
                isPastDeadline
              }
              overrideVisible={overrideVisible}
              showChildrenAnyway
            />
          );
        })}
      </Category>
    </div>
  );
};

export default ProductCategory;
