import { ReceiptWrapper } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "../.";
import { AppContext } from "../../contextProvider";
import Item from "./Item";

const Index = () => {
  const { t } = useTranslation(["subscription"]);
  const { subscriptions } = useContext(SubscriptionContext);
  const { customBranding } = useContainer(AppContext);

  const customColor =
    customBranding?.custom?.palette?.background?.primary || "#ffffff";

  return (
    <ReceiptWrapper withPadding={true} svgColor={customColor}>
      <h3 className="h500 mb-4 font-medium">
        {t("subscription:my-subscriptions")}
      </h3>
      {subscriptions.map((subscription) => (
        <Item subscription={subscription} />
      ))}
    </ReceiptWrapper>
  );
};

export default Index;
