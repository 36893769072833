import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBagShopping,
  faMoneyCheck,
  faMoneySimpleFromBracket,
  faWarning,
} from "@fortawesome/pro-duotone-svg-icons";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import {
  calculateOrderTotal,
  db,
  fetchCollection,
  fetchMultipleDocuments,
  priceFormatter,
} from "@kanpla/system";
import {
  FlexOrder,
  Module,
  OrderMealplan,
  OrderPersonal,
  Payment,
  Payout,
  School,
  SubscriptionOrder,
} from "@kanpla/types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { uniq } from "lodash";

export interface Item {
  icon: IconProp;
  title: string;
  dateSeconds: number;
  salesplaceName?: string;
  paymentAmount: number;
  paymentDescription: string;
  order?: OrderPersonal;
  module?: Module;
  /** Used to sort items into tabs */
  itemType: "credit" | "billing" | "subscription";
}

const UseHistory = () => {
  const { t, i18n } = useTranslation(["modals", "translation"]);
  const { userId } = useContainer(AppContext);
  const [items, setItems] = useState<Item[]>([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const fetchItems = async () => {
    setItemsLoading(true);
    // 1. Payment

    if (!userId) return;

    const payments = await fetchCollection<Payment>(
      db.collection("payments").where("userId", "==", userId)
    );

    const paymentItems: Array<Item> = payments
      .filter((payment) => !payment.hasTemporaryOrder || !payment.orderValid)
      .map((payment) => ({
        icon: payment.valid ? faMoneyCheck : faWarning,
        title: payment.valid
          ? t("modals:deposit")
          : t("translation:payment-failed"),
        dateSeconds: payment?.dateCreated?.seconds,
        paymentAmount: payment.amount,
        paymentDescription: t("modals:card-payment-to-balance"),
        itemType: "credit",
      }));

    // 2. Subscriptions

    const subscriptionOrders = await fetchCollection<SubscriptionOrder>(
      db.collection("subscriptionOrders").where("userId", "==", userId)
    );
    const subscriptionItems: Array<Item> = subscriptionOrders
      .filter((subscriptionOrder) => subscriptionOrder.valid)
      .map((subscriptionOrder) => ({
        icon: faArrowsRotate,
        title: t("modals:name-to-display-name", {
          name: subscriptionOrder.name,
          displayName: subscriptionOrder.displayName,
        }),
        dateSeconds: subscriptionOrder?.dateCreated?.seconds,
        paymentAmount: subscriptionOrder.fullPrice,
        paymentDescription: t("modals:card-payment-to-balance"),
        itemType: "subscription",
      }));

    // 3. Payouts
    const payouts = await fetchCollection<Payout>(
      db.collection("payouts").where("userId", "==", userId)
    );
    const payoutItems: Array<Item> = payouts.map((payout) => ({
      icon: faMoneySimpleFromBracket,
      title: t("modals:payment"),
      dateSeconds: payout.createdAtSeconds,
      paymentAmount: payout.amountSubtracted,
      paymentDescription: `${priceFormatter(payout.fee || 0, {
        language: i18n.language,
      })}`,
      itemType: "credit",
    }));

    // 4. Orders
    const orders = await fetchCollection<OrderMealplan>(
      db.collection("orders").where("userId", "==", userId)
    );

    const flexOrders = await fetchCollection<FlexOrder>(
      db.collection("flexOrders").where("userId", "==", userId)
    );

    const allOrders = [...orders, ...flexOrders];

    // Modules
    const allModuleIds = allOrders.map((o) => o.moduleId);
    const uniqueModuleIds = uniq(allModuleIds);

    const modules = await fetchMultipleDocuments<Module>(
      "modules",
      uniqueModuleIds
    );

    // Salesplaces
    const allSalesplaceIds = allOrders.map((o) => o.schoolId);
    const uniqueSalesplaceIds = uniq(allSalesplaceIds);

    const schools = await fetchMultipleDocuments<School>(
      "schools",
      uniqueSalesplaceIds
    );

    const orderItems: Array<Item> = allOrders.map((order) => {
      const targetModule = modules.find((m) => m?.id === order.moduleId);
      const targetSchool = schools.find((m) => m?.id === order.schoolId);

      return {
        icon: faBagShopping,
        title: `${
          targetModule?.displayName ||
          targetModule?.name ||
          t("translation:order-long")
        }`,
        dateSeconds: order.dateSeconds,
        paymentAmount: calculateOrderTotal(order.order || {}),
        paymentDescription:
          targetModule?.paymentMethod === "billing"
            ? t("modals:paid-via-invoice")
            : t("modals:paid-from-balance"),
        order,
        module: targetModule,
        itemType:
          targetModule?.paymentMethod === "billing" ? "billing" : "credit",
        salesplaceName:
          targetSchool?.name || targetSchool?.id || order.schoolId,
      };
    });

    // 5. Refunds
    // const refunds = await fetchCollection(
    //   userId
    //     ? db.collection("refunds").where("userId", "==", userId || "—")
    //     : null,
    //   true
    // );

    // -> Put it all together
    const items: Array<Item> = [
      ...paymentItems,
      ...subscriptionItems,
      ...payoutItems,
      ...orderItems,
    ];

    const sortedItems = items.sort((a, b) => b.dateSeconds - a.dateSeconds);

    setItems(sortedItems);
    setItemsLoading(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return { items, fetchItems, itemsLoading };
};

export default UseHistory;
