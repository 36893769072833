import { db, useCollectionListener } from "@kanpla/system";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";

const useNotifications = () => {
  const { school } = useContainer(AppContext);

  const notificationQuery = db
    .collection("notifications")
    .where("schools", "array-contains", school?.ref || school?.id || "")
    .orderBy("dateCreated", "desc")
    .limit(3);

  // Get data
  const [notifications = [], loading, error] =
    useCollectionListener(notificationQuery);

  return { notifications };
};

export default useNotifications;
