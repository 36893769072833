import React from "react";

interface Props {
  backgroundImage?: string;
  title: string;
  children: any;
}

const Slide = (props: Props) => {
  const { backgroundImage, title, children } = props;

  return (
    <div className="py-20 h-full relative px-6">
      {backgroundImage && (
        <img
          src={backgroundImage}
          alt=""
          className="absolute inset-0 opacity-25 w-full h-full object-cover"
        />
      )}
      <div className="h-full max-w-md mx-auto relative flex flex-col justify-center text-primary-contrast">
        <h2 className="h600 text-primary-contrast leading-8 mb-4">{title}</h2>
        {children}
      </div>
    </div>
  );
};

export default Slide;
