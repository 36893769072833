import { Child, Module } from "@kanpla/types";
import { Button, Space } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  orderingModule: Module;
  subscriptionModule: Module;
  child: Child;
}

const OrderButton = ({ orderingModule, subscriptionModule, child }: Props) => {
  const { t } = useTranslation(["translation"]);
  const { setChild, setMenuOpen } = useContainer(AppContext);
  const router = useRouter();

  const onClick = (moduleId: string) => {
    setChild(child);
    const url = constructNewUrl(child.schoolId, moduleId);
    router.push(url);
    setMenuOpen(false);
  };

  if (!orderingModule && subscriptionModule)
    return (
      <Button type="primary" onClick={() => onClick(subscriptionModule.id)}>
        {t("translation:subscription")}
      </Button>
    );
  if (orderingModule && !subscriptionModule)
    return (
      <Button type="primary" onClick={() => onClick(orderingModule.id)}>
        {t("translation:order-short")}
      </Button>
    );

  if (orderingModule && subscriptionModule)
    return (
      <Space>
        <Button type="primary" onClick={() => onClick(orderingModule.id)}>
          {t("translation:order-short")}
        </Button>
        <Button onClick={() => onClick(subscriptionModule.id)}>
          {t("translation:subscription")}
        </Button>
      </Space>
    );

  return <div></div>;
};

export default OrderButton;
