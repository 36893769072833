import { useState, useCallback, useEffect } from "react";
import { useContainer } from "unstated-next";
import { Dictionary, get, groupBy, isEmpty } from "lodash";
import { CombinedOfferItem } from "@kanpla/types";
import { AppContext } from "../contextProvider";
import { MenuPreviewContext } from "./MenuPreviewContext";

export const useCategoriesDictionary = (
  propsDayIndex?: number
): Dictionary<CombinedOfferItem[]> => {
  const { items } = useContainer(MenuPreviewContext);
  const {
    dayIndex: contextDayIndex,
    schoolId,
    week,
  } = useContainer(AppContext);
  const dayIndex = propsDayIndex ?? contextDayIndex;
  const [categories, setCategories] = useState({});

  const filterAvailableItems = useCallback(
    (item: CombinedOfferItem) => {
      const menuNotAvailable = isEmpty(
        get(item, ["dates", week[dayIndex].seconds, "menu"])
      );
      const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
      const schoolDayDisabled = get(item, [
        "schools",
        schoolId,
        "days",
        dayIndex,
        "disabled",
      ]);

      return !dayDisabled && !schoolDayDisabled && !menuNotAvailable;
    },
    [dayIndex, schoolId, week]
  );

  useEffect(() => {
    const availableMenus = items.filter((i: CombinedOfferItem) =>
      filterAvailableItems(i)
    );
    const groupedCategories = groupBy(
      availableMenus,
      (product) => product.category
    );
    setCategories(groupedCategories);
  }, [items, filterAvailableItems]);

  return categories;
};
