import { calculateOrderTotal, getOrderConfigs } from "@kanpla/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Item as ItemType } from "./../../UseHistory";

interface Props {
  item: ItemType;
}

const PaymentDescription = ({ item }: Props) => {
  const { t } = useTranslation(["translation", "mealplan2"]);

  const configs = getOrderConfigs(item.order?.order || {});
  const orderTotal = calculateOrderTotal(item.order?.order || {});

  const hasDetails = item.order;

  const isRefunded = orderTotal === 0 && !configs.length && hasDetails;

  if (isRefunded)
    return (
      <div className="text-sm mt-2 text-text-secondary">
        {t("mealplan2:this-order-is-refunded")}
      </div>
    );

  return (
    <div className="text-sm mt-2 text-text-secondary">
      {t("translation:amount-with-value", {
        value: item.paymentAmount ?? "—",
      })}
      {item.paymentDescription ? `, ${item.paymentDescription}` : ""}
    </div>
  );
};

export default PaymentDescription;
