import {
  db,
  deadlineFormatter,
  fn,
  getFlexOrder,
  getFlexStandard,
  getOrderConfigs,
  hasAccessToModule,
  isDatePastDeadline,
  UseOffers,
} from "@kanpla/system";
import { OrderInfo, OrderOrder, OrderOrderProduct } from "@kanpla/types";
import { message } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import ModuleLoadingWrapper from "../ModuleLoadingWrapper";
import NavbarSecondary from "../NavbarSecondary";
import FlexLoader from "./FlexLoader";
import Menu from "./Menu";
import Receipt from "./Receipt";
import StandardSettings from "./StandardSettings";

const ContextState = () => {
  const { t } = useTranslation(["flex", "translation"]);
  const {
    school,
    week,
    dayIndex,
    child,
    userId,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    setInnerAppLoading,
  } = useContainer(AppContext);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [allowOrder, setAllowOrder] = useState(false);
  const router = useRouter();

  const hasVariantsLimitPlugin = module?.plugins?.variantsLimit?.active;
  const [hasVariantsLimit, setHasVariantsLimit] = useState(false);

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.individual && ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(true);
      router.replace(newUrl);
    } else if (!ha.individual && !ha.bulk) {
      setIsBulk(false);
      router.replace("/app");
    }
  }, []);

  // Offers
  const dateSeconds = week[dayIndex]?.seconds;
  const { items, holidayDates, deadlineInfo, mealOptions } = UseOffers({
    schoolId,
    moduleId,
    allDateSeconds: [dateSeconds],
    setLoading: setInnerAppLoading,
    groupName: child?.groupName,
  });
  // Deadline Jump
  useDeadlineJump({ defaultDate: deadlineInfo?.defaultDate });

  // Standard
  const { standard, standardLoading } = getFlexStandard({
    moduleId,
    childId,
    userId,
    schoolId,
    isBulk: false,
    db,
  });

  // Order
  const { order, orderLoading, orderDocument, orderInfo } = getFlexOrder({
    schoolId,
    moduleId,
    childId,
    isBulk: false,
    db,
    date: date || null,
    standard,
  });

  const numberOfItems = Object.values(order).reduce(
    (a: number, c: OrderOrderProduct) => a + c.amount,
    0
  ) as number;
  const hasOrdered = numberOfItems > 0;

  useEffect(() => {
    setTimeNavigation("todaySwitch");
  }, []);

  // Submit order
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(
    () =>
      saving
        ? message.loading(t("flex:message.loading.order-being-saved"), 0)
        : message.destroy(),
    [saving]
  );

  useEffect(() => {
    if (hasVariantsLimitPlugin && order) {
      const orderConfigs = getOrderConfigs(order);
      const variantsLimit = module?.plugins?.variantsLimit?.limit;

      if (orderConfigs.length === variantsLimit) {
        setHasVariantsLimit(true);
      } else {
        setHasVariantsLimit(false);
      }
    }
  }, [order]);

  const submit = async (newOrder: OrderOrder, info: OrderInfo) => {
    if (!schoolId) return;

    // const orderData = isBulk ? newOrder : newOrder;
    const orderData = newOrder;

    try {
      setSaving(true);

      // Check if some text inputs are required
      if (module?.plugins?.textInput?.active) {
        const { textInput } = orderInfo;
        const getRequiredField = module.plugins?.textInput?.fields.find(
          (field) => field?.required && isEmpty(textInput?.[field.key])
        );
        const shouldThrowError = !textInput || !!getRequiredField;

        if (shouldThrowError) {
          throw new Error(
            t("translation:form.errors.is-required", {
              value: getRequiredField.title,
            })
          );
        }
      }

      if (hasVariantsLimitPlugin) {
        const orderConfigs = getOrderConfigs(orderData);
        const variantsLimit = module?.plugins?.variantsLimit?.limit;

        if (orderConfigs.length > variantsLimit)
          throw new Error(
            t("translation:plural.limitOrder", {
              value: variantsLimit,
              count: variantsLimit,
            })
          );
      }

      const submitOrderToServer = fn.httpsCallable("submitFlexOrder");
      await submitOrderToServer({
        schoolId,
        dateSeconds: date.seconds,
        order: orderData,
        orderInfo: info,
        moduleId,
        childId,
      });

      setAllowOrder(false);
    } catch (err) {
      console.error(err);
      message.error(err?.message || t("translation:something-went-wrong"));
    } finally {
      setSaving(false);
    }
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
  } = deadlineInfo || {};
  const pastDate = isDatePastDeadline({
    date,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  const showReceipt = !allowOrder;
  const loading = orderLoading || standardLoading;

  const activeHoliday = holidayDates?.[dateSeconds];

  return {
    module,
    items,
    moduleId: module.id,

    deadline,
    deadlineWeekRelative,
    defaultDate,
    pastDate,
    softDeadline: deadlineSoft,

    softDeadlineMaxAmount,
    deadlineFormatted,

    standard,
    standardLoading,

    orderDocument,
    order,
    numberOfItems,
    hasOrdered,
    submit,
    saving,
    setSaving,
    isBulk: false,
    settingsOpen,
    setSettingsOpen,

    hasVariantsLimit,
    setHasVariantsLimit,

    orderInfo,
    showReceipt,
    loading,
    activeHoliday,
    setAllowOrder,
    mealOptions,
  };
};

export const FlexContext = createContainer(ContextState);

const Index = () => (
  <FlexContext.Provider>
    <View />
  </FlexContext.Provider>
);

const View = () => {
  const {
    activeHoliday,
    defaultDate,
    deadlineFormatted,
    loading,
    showReceipt,
    setAllowOrder,
    module,
  } = useContainer(FlexContext);

  const { innerAppLoading } = useContainer(AppContext);

  if (activeHoliday)
    return (
      <>
        <NavbarSecondary
          timeNavigation="daily"
          deadlineFormatted={deadlineFormatted}
        />
        <CanteenClosed
          defaultDate={defaultDate}
          holidayDesc={activeHoliday.design}
        />
      </>
    );

  return (
    <>
      <NavbarSecondary
        timeNavigation="daily"
        deadlineFormatted={deadlineFormatted}
      />
      <div className="py-3 md:pb-12 wrapper select-none overflow-hidden">
        <ModuleDescription align="center" module={module} />
        <ModuleLoadingWrapper loading={innerAppLoading}>
          {loading ? (
            <FlexLoader />
          ) : showReceipt ? (
            <Receipt setAllowOrder={setAllowOrder} />
          ) : (
            <Menu setAllowOrder={setAllowOrder} />
          )}
          <StandardSettings />
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};

export default Index;
