import { faUpLong } from "@fortawesome/pro-duotone-svg-icons";
import { faShoppingBasket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MeetingIllustration } from "@kanpla/ui";
import { Button, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import { MealplanMeetingContext } from ".";
import { BasketList } from "../BasketList";
import { useBasketResult } from "../useBasketResult";
import { BasketResult } from "./BasketResult";
import ConfirmButton from "./ConfirmButton";

interface MobileProps {
  open: boolean;
}

export const MobileBasket = (props: MobileProps) => {
  const { open } = props;
  const { t } = useTranslation(["mealplan2", "translation"]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { getCollapseProps } = useCollapse({ isExpanded });

  const [openBasket, setOpenBasket] = useState<boolean>(open);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setOpenBasket(open);
  }, [open]);

  const { setIsConfirming, isConfirming } = useContext(MealplanMeetingContext);
  const { totalAmount, totalPrice } = useBasketResult();

  return (
    <BottomSheet
      open={openBasket}
      blocking={isExpanded}
      scrollLocking={false}
      onDismiss={() => {
        if (isExpanded) {
          setIsExpanded(false);
        }
      }}
    >
      {isExpanded && (
        <style>
          {`
            [data-rsbs-overlay] {
              z-index: 999;
            }
          `}
        </style>
      )}
      <Space direction="vertical" className="w-full p-4">
        <Button
          className="w-full py-4 flex justify-between items-center cursor-pointer"
          onClick={() => {
            setIsExpanded((prevExpanded) => !prevExpanded);
          }}
          size="large"
        >
          <p className="font-semibold">
            <FontAwesomeIcon
              size="1x"
              icon={faShoppingBasket}
              className="mr-2"
            />
            {t("mealplan2:hide-see-order", {
              value: isExpanded
                ? t("mealplan2:values.hide")
                : t("mealplan2:values.see"),
            })}
          </p>
          <p>
            {t("mealplan2:plural.products", {
              count: totalAmount,
              totalProducts: totalAmount,
            })}
          </p>
        </Button>
        {isExpanded && (
          <div {...getCollapseProps()}>
            <div className="py-4">
              <BasketList editMode={editMode} setEditMode={setEditMode} />
              <BasketResult amount={totalAmount} price={totalPrice} />
            </div>
          </div>
        )}

        {!isConfirming && (
          <ConfirmButton
            text={
              isConfirming
                ? t("translation:confirm")
                : t("mealplan2:proceed-to-confirmation")
            }
            className="mt-3 w-full"
            onClick={() => {
              !isConfirming ? setIsConfirming(true) : setOpenBasket(false);
            }}
            style={{
              width: "100%",
            }}
          />
        )}
      </Space>
    </BottomSheet>
  );
};

export const EmptyBasket = () => {
  const { t } = useTranslation(["mealplan2"]);

  return (
    <div className="flex flex-col items-center justify-center m-4">
      <div className="lg:w-1/2 w-full flex justify-center items-start">
        <MeetingIllustration />
      </div>
      <h1 className="text-primary-main text-2xl mt-8 font-semibold h800">
        {t("mealplan2:basket-empty")}
      </h1>
      <p
        className="text-lg text-text-secondary text-center mt-2"
        style={{ width: "200px" }}
      >
        {t("mealplan2:starting-by-adding-items")}
      </p>
      <div className="w-1/3 mt-12 flex justify-center items-end text-primary-main transform -rotate-90">
        <FontAwesomeIcon
          icon={faUpLong}
          color="currentColor"
          className="animate-bounce text-5xl"
        />
      </div>
    </div>
  );
};
