import { CombinedOfferItem, DayIndex, OrderConfig } from "@kanpla/types";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Input from "./Input";

interface Props {
  getValue: (dayIndex: DayIndex) => number;
  disabled?: boolean;
  expandProduct?: () => void;
  choice: OrderConfig["options"];
  product: CombinedOfferItem;
}

const WeekInputs = ({
  getValue,
  disabled = false,
  choice,
  expandProduct = () => null,
  product,
}: Props) => {
  const { week } = useContainer(AppContext);

  return (
    <>
      {week.map((date, dayIndex: DayIndex) => {
        const amount = getValue(dayIndex);
        const dateSeconds = week[dayIndex]?.seconds;

        const disabledDay =
          product?.[dayIndex]?.disabled ||
          product?.[dayIndex]?.disabled ||
          product?.dates?.[dateSeconds]?.menu?.name === "X";

        return (
          <div key={date.seconds} className={`flex-1 text-center`} style={{}}>
            <Input
              dayIndex={dayIndex}
              product={product}
              choice={choice}
              expandProduct={expandProduct}
              value={amount}
              disabled={disabledDay || disabled}
            />
          </div>
        );
      })}
    </>
  );
};

export default WeekInputs;
