import { callFunction } from "@kanpla/system";
import { OrderInfo, OrderOrder, Payment, PaymentProvider } from "@kanpla/types";

interface Props {
  /** The amount of the order to be paid */
  amount: number;
  /** The payment method choosen by the user */
  paymentMethod: PaymentProvider;
  /** The kanplaGo order */
  order: OrderOrder;
  /** Additional order info */
  orderInfo: OrderInfo;
  /** Payment is processed in external window */
  callbackUrl: string;
  isRecurring?: boolean;
  moduleId: string;
  schoolId: string;
  childId: string;
  dateSeconds: number;
}

const kanplaGoPayment = async (props: Props) => {
  const {
    paymentMethod,
    isRecurring,
    order,
    amount,
    orderInfo,
    moduleId,
    schoolId,
    childId,
    callbackUrl,
    dateSeconds,
  } = props;

  try {
    const paymentOrder: Payment["order"] = {
      order,
      dateSeconds,
      moduleId,
      schoolId,
      childId,
    };

    // Handle all other payment methods
    const data = await callFunction("reepayPaymentOnce", {
      amount,
      recurring: isRecurring,
      paymentMethod,
      isWindowSession: true,
      mode: "order",
      callbackUrl,
      order: paymentOrder,
    });

    return data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export default kanplaGoPayment;
