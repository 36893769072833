/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@kanpla/system";
import { Category } from "@kanpla/ui";
import { Modal } from "antd";
import { groupBy, sortBy } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from "../..";
import { MealplanContext } from "../../..";
import Product from "../../../Product";
import StepWrapper from "../../Wrapper";
const { confirm } = Modal;

const ProductSelection = () => {
  const { t } = useTranslation(["translation", "mealplan2"]);
  const { setCurrentView } = useContext(MealplanMeetingContext);
  const { items, module } = useContainer(MealplanContext);

  const sorted = sortBy(items, (i) => i.category !== "");
  const categories = groupBy(sorted, "category");

  return (
    <StepWrapper
      pageTitle={t("translation:select-products")}
      onBack={() => {
        confirm({
          title: t("mealplan2:order-will-be-canceled"),
          icon: <ExclamationCircleOutlined />,
          content: t("mealplan2:sure-continue"),
          onOk() {
            setCurrentView("overview");
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }}
    >
      <>
        {Object.entries(categories).map(([name, items]) => {
          if (name === "skjult") return null;

          return (
            <div className="boundary-element" key={name}>
              <Category
                name={
                  capitalizeFirstLetter(name) ||
                  module?.text?.["noCategory/text"] ||
                  t("translation:dish-of-the-day")
                }
                noMargin
                noMarginTop
                top={0}
              >
                <div className="mt-2 flex flex-col">
                  {items.map((offerProduct) => {
                    return <Product product={offerProduct} hidePriceInLabel />;
                  })}
                </div>
              </Category>
            </div>
          );
        })}
      </>
    </StepWrapper>
  );
};

export default ProductSelection;
