import { hasAccessToModule } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { ModalWrapper, NewLogo } from "@kanpla/ui";
import { useServices } from "apps/frontend/lib/useServices";
import Router, { useRouter } from "next/router";
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";
import React, { useEffect, useRef, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ButtonLast from "./ButtonLast";
import StandardOrder from "./StandardOrder";
import Welcome from "./Welcome";

// Helpers
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

const Introduction = () => {
  // Available services (mealplan, meetings, flex)
  const { children, modules, school, child } = useContainer(AppContext);
  const { services } = useServices({ school, child });
  const sliderRef = useRef<any>();
  const prevChildren: Array<Child> = usePrevious(children);
  const [shouldOpen, setShouldOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();
  // Triggered from url (from FoodCompany signup flow)

  // Succes messages
  if (router.query.showIntroductionCarousel) {
    setOpen(true);
    const newQuery = router.query;
    delete newQuery.showIntroductionCarousel;
    Router.push({
      pathname: router.pathname,
      query: newQuery,
    });
  }

  const isCompany = services.hasFlex || services.hasFlexBulk;

  useEffect(() => {
    const beforeHadNoKids = prevChildren != null && prevChildren.length === 0;
    const nowHasOneKid = children !== null && children.length === 1;

    if (beforeHadNoKids && nowHasOneKid) setShouldOpen(true);
  }, [children, prevChildren, isCompany]);

  useEffect(() => {
    if (isCompany && shouldOpen) {
      setOpen(true);
      setShouldOpen(false);
    }
  }, [shouldOpen, isCompany]);

  // Martin said to remove this
  // let slides = [<Welcome key="welcome" />, <Explanation key="explain" />];
  let slides = [<Welcome key="welcome" services={services} />];

  const availableFlexModule = modules.find(
    (m) =>
      m.type === "flex" &&
      m.flow !== "menuPreview" &&
      hasAccessToModule({
        child,
        school,
        module: m,
      })?.individual
  );

  if (availableFlexModule)
    slides.push(<StandardOrder key="standard" module={availableFlexModule} />);
  // if (!services.hasFlexBulk) {
  // slides.push(<BuyMore key="more" />);
  // slides.push(<AddPayment key="payment" onClose={() => setOpen(false)} />);
  // }
  // if (services.hasMeeting) slides.push(<Meeting key="meeting" />);

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      containerClassName="md:py-16"
      innerClassName="lg:rounded-lg lg:shadow-xl relative w-full max-w-2xl m-auto h-full text-background-primary overflow-hidden bg-gradient-to-br from-primary-main to-primary-dark"
    >
      <CarouselProvider
        totalSlides={slides.length}
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        className="h-full"
      >
        <Slider
          classNameTrayWrap="h-full"
          classNameTray="flex h-full transition"
          className="h-full"
          ref={sliderRef}
        >
          {slides.map((slide, index) => (
            <Slide
              style={{ padding: 0 }}
              className="h-full flex-1 p-0"
              innerClassName="h-full"
              key={`slide-${slide.key}-${index}`}
              index={index}
            >
              {slide}
            </Slide>
          ))}
        </Slider>

        <div
          className="right-0 bottom-0 absolute mb-4 mr-6 md:mr-4 z-max"
          style={{ paddingBottom: `env(safe-area-inset-bottom)` }}
        >
          <ButtonLast onClose={() => setOpen(false)} />
        </div>

        <div className="absolute inset-x-0 top-0 flex justify-center my-8">
          <NewLogo type="inverted" monotone />
        </div>

        <nav className="z-60 absolute inset-x-1 bottom-5 flex items-center justify-center">
          {Array(slides.length)
            .fill("-")
            .map((_, index) => (
              <Dot
                slide={index}
                key={index}
                className="mx-1 h-6 w-6 rounded-full flex items-center justify-center opacity-30 disabled:opacity-100 transition-opacity"
                style={{ outline: "none" }}
              >
                <span className="w-1.5 h-1.5 bg-primary-contrast rounded-full"></span>
              </Dot>
            ))}
        </nav>
      </CarouselProvider>
    </ModalWrapper>
  );
};
export default Introduction;
