import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { isEmpty } from "lodash";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  disabled?: boolean;
}

const useBasketPreventClick = ({ disabled = false }: Props) => {
  const { t } = useTranslation(["components"]);
  const { basket, setBasket } = useContainer(AppContext);

  const confirm = async () => {
    const promise = new Promise((resolve, reject) => {
      // Skip if basket is empty, or if explicitly disabled
      const shouldSkip = isEmpty(basket) || disabled;
      if (shouldSkip) {
        resolve(true);
        return;
      }

      Modal.confirm({
        title: t("components:cancel-order"),
        icon: <ExclamationCircleOutlined />,
        content: t("components:order-will-be-canceled"),
        okText: t("components:cancel-order-ok-text"),
        cancelText: t("components:cancel-order-cancel-text"),
        onOk() {
          setBasket({});
          setTimeout(() => setBasket({}), 200);
          resolve(true);
        },
        onCancel() {
          reject("Cancelled");
        },
      });
    });

    return await promise;
  };

  return confirm;
};

export default useBasketPreventClick;
