import { faArrowLeftLong } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOrderConfigs } from "@kanpla/system";
import { Plugins } from "@kanpla/types";
import { Button } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { isEmpty } from "lodash";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { BasketProduct, MealplanMeetingContext } from "../..";
import { BasketList } from "../../../BasketList";
import { useBasketResult } from "../../../useBasketResult";
import { BasketResult } from "../../BasketResult";
import ConfirmButton from "../../ConfirmButton";
import { EmptyBasket } from "../../MobileBasket";

interface Props {
  hideButton?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

const BasketStep = ({ hideButton = false, editMode, setEditMode }: Props) => {
  const { t } = useTranslation(["mealplan2"]);
  const { basket, module } = useContainer(AppContext);
  const { setIsConfirming } = useContext(MealplanMeetingContext);

  const configs = useMemo(() => getOrderConfigs(basket || {}) || [], [basket]);
  const { totalAmount, totalPrice } = useBasketResult();

  const selectedProducts: BasketProduct[] = useMemo(
    () =>
      configs.map(({ price, productId, config }) => {
        return {
          amount: config.amount,
          price,
          productId,
          config,
        };
      }),
    [configs]
  );

  // Ignore required product
  const plugins = module?.plugins || ({} as Plugins.Array);
  const requiredProductId = plugins?.requiredProduct?.productId;

  const showEmptyBasket =
    !hideButton &&
    isEmpty(selectedProducts.filter((p) => requiredProductId !== p.productId));

  return showEmptyBasket ? (
    <EmptyBasket />
  ) : (
    <div className="flex flex-col">
      <BasketList editMode={editMode} setEditMode={setEditMode} />
      <div className="h-4" />
      <BasketResult amount={totalAmount} price={totalPrice} />
      {!hideButton ? (
        <ConfirmButton
          text={t("mealplan2:proceed-to-confirmation")}
          className="mt-4"
          onClick={() => setIsConfirming(true)}
          withIcon
          dataCy="continue-confirmation"
        />
      ) : (
        <Button
          size="large"
          className="mt-4"
          onClick={() => setIsConfirming(false)}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} className="mr-4" />
          {t("mealplan2:order-more-products")}
        </Button>
      )}
    </div>
  );
};

export default BasketStep;
