import { calculateAmountOfOrderItems, timestampToMoment } from "@kanpla/system";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import { AppContext } from "../contextProvider";
import GlobalVariant from "./GlobalVariant";
import Product from "./Product";
import moment from "moment";

export const firstColumnStyles = "w-full md:w-5/12 flex-0 pb-3 md:pb-0";

const Menu = () => {
  const { t, i18n } = useTranslation(["translation", "flex-bulk"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { week } = useContainer(AppContext);
  const { items, orders, isStandardView, isProductOrdered } =
    useContainer(FlexBulkContext);
  const [showingAllProducts, setShowingAllProducts] = useState(false);

  const hasExtraProducts = items.some((p) => !isProductOrdered(p));

  return (
    <div className="mb-16">
      {/* Dates */}
      <div className="flex flex-wrap mb-2 px-4">
        <div className={`${firstColumnStyles}`}></div>
        {week.map((date) => (
          <div
            key={date.seconds}
            className="flex-1 text-sm font-medium text-center"
          >
            {isStandardView
              ? timestampToMoment(date).format(`[${t("flex-bulk:every")}] ddd`)
              : timestampToMoment(date).format("ddd D.M.")}
          </div>
        ))}
      </div>

      {/* Totals */}
      <div className="flex flex-wrap items-center bg-background-secondary px-4 py-2 rounded-lg mb-2">
        <div className={`${firstColumnStyles} h300`}>
          {t("translation:total")}:
        </div>
        {week.map((date, dayIndex) => {
          const total = calculateAmountOfOrderItems(orders?.[dayIndex] || {});

          return (
            <div key={date.seconds} className="flex-1 text-center">
              {total}
            </div>
          );
        })}
      </div>
      {items.length === 0 && (
        <>
          <div className="loader h-16 rounded-lg mb-2" />
          <div className="loader h-16 rounded-lg mb-2" />
          <div className="loader h-16 rounded-lg mb-2" />
        </>
      )}

      <GlobalVariant />
      {/* Products */}
      {items
        .filter((p) => showingAllProducts || isProductOrdered(p))
        .map((item) => (
          <Product
            item={item}
            firstColumnStyles={firstColumnStyles}
            key={item.id}
          />
        ))}
      {hasExtraProducts && (
        <Button
          className="mx-auto mt-3"
          onClick={() => setShowingAllProducts(!showingAllProducts)}
        >
          {showingAllProducts
            ? t("flex-bulk:hide-extra-products")
            : t("flex-bulk:show-all-products")}
        </Button>
      )}
    </div>
  );
};

export default Menu;
