import { getChildGroupName } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ChildActions from "../ChildActions";
import SalesplaceEdit from "./SalesplaceEdit";

interface Props {
  child: Child;
  schoolId: string;
}

const SalesplaceKidRow = ({ child, schoolId }: Props) => {
  const { schoolId: activeSchoolId, childSalesplaceSchools } =
    useContainer(AppContext);

  const active = schoolId === activeSchoolId;
  const school = childSalesplaceSchools.find((s) => s.id === schoolId);

  return (
    <div className="mt-2 pb-2 border-b">
      <div className="flex justify-between items-center w-full py-2">
        <div className="flex flex-col">
          <h1
            className={`h400 ${
              active ? "text-primary-main font-semibold" : ""
            }`}
          >
            {child.school_name}
          </h1>
          <h3 className="text-text-disabled mt-0">
            {getChildGroupName(child?.selectors || {})}
          </h3>
        </div>
        <div className="flex">
          <ChildActions
            child={child}
            selectedChild={active}
            EditPopup={({ open, setOpen, child }) => (
              <SalesplaceEdit
                open={open}
                setOpen={setOpen}
                child={child}
                school={school}
              />
            )}
          />
          {/* <Button
            type="primary"
            disabled={active}
            onClick={async () => {
              try {
                setSchoolId(school?.id);
                const modules = await getModulesByChildSchoolId(child);
                const sortedModules = sortModules(modules);
                const redirectToUrl = constructNewUrl(
                  child.schoolId,
                  sortedModules?.[0]?.id
                );
                router.push(redirectToUrl);
              } catch (e) {
                console.error(e);
                message.error(e?.message);
              }
            }}
          >
            {t("translation:order-short")}
          </Button>
          <ButtonEdit
            label={t("translation:edit")}
            className="ml-2"
            onClick={() => setOpen(true)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SalesplaceKidRow;
