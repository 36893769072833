import { db, rules } from "@kanpla/system";
import { ButtonSubmit, Modal } from "@kanpla/ui";
import { Form, Input, message as antdMessage } from "antd";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { default as React, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const ProductSuggestion = () => {
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { school, userId } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const submit = async () => {
    try {
      setSending(true);

      await db.collection("suggestions").doc().set({
        userId,
        message,
        schoolId: school.ref.id,
        dateCreated: moment().toDate(),
      });

      setSending(false);
      antdMessage.success(t("mealplan2:message.success.the-proposal"));
      setOpen(false);
      setMessage("");
    } catch (err) {
      setSending(false);
      antdMessage.error(t("mealplan2:message.error.shipping-failed"));
    }
  };

  return (
    <div className="pt-32 text-center">
      <h2 className="font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
        {t("mealplan2:missing-something")}
      </h2>
      <p className="text-text-secondary">
        <Trans t={t} i18nKey="mealplan2:suggest-dish-or-product">
          Du er velkommen til at
          <a onClick={() => setOpen(true)}>foreslå en ret/produkt</a>
        </Trans>
      </p>

      <Modal
        title={t("mealplan2:modal.titles.suggest-dish-or-product")}
        subtitle={t("mealplan2:modal.subtitles.proposal-will-be-sent")}
        open={open}
        setOpen={setOpen}
      >
        <Form
          onFinish={submit}
          // To catch the submit event on a custom form
          onSubmitCapture={(event) => event.preventDefault()}
        >
          <Form.Item
            name="suggestion"
            rules={[
              rules({
                rule: "required",
                ruleValue: true,
                message: t("translation:form.errors.required-a", {
                  value: t("mealplan2:values.proposals"),
                }),
              }),
            ]}
            initialValue={message}
          >
            <Input.TextArea
              autoFocus
              className="block w-full"
              placeholder={`${t(
                "mealplan2:form.placeholders.suggest-something"
              )}...`}
              rows={3}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <ButtonSubmit loading={sending} text="Send" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductSuggestion;
