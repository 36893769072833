import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomNavigation, getTabIcons } from "@kanpla/ui";
import { hasAccessToModule } from "@kanpla/system";
import { Module, ProductType } from "@kanpla/types";
import { isArray, isEmpty } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { useContainer } from "unstated-next";
import { constructNewUrl } from "../lib/constructNewUrl";
import useBasketPreventClick from "./BasketPreventClick";
import { AppContext } from "./contextProvider";

const moduleDefaultIcons: { [type in ProductType]: [IconPrefix, IconName] } = {
  mealplan: ["fad", "plate-utensils"],
  homescreen: ["fad", "home"],
  flex: ["fad", "pot-food"],
  subscription: ["fad", "bullseye-pointer"],
  // shop: ["fad", "shop"],
};

const Tabs = () => {
  const { modules, school, child, module, mobile } = useContainer(AppContext);

  if (isEmpty(module)) return null;

  const tabs = modules.reduce((acc, m) => {
    const hide = m?.displayOptions?.hideDesktopTab;
    if (hide) return acc;

    if (m.type === "flex") {
      const hasAccess = hasAccessToModule({ module: m, school, child });

      if (hasAccess.individual)
        acc.push(
          <TabLink
            key={`${m.id}-individual`}
            label={`${m?.displayName || m.name}`}
            m={m}
          />
        );
      if (hasAccess.bulk)
        acc.push(
          <TabLink
            key={`${m.id}-admin`}
            moduleVariant="admin"
            label={`Admin`}
            m={m}
          />
        );
    } else {
      acc.push(<TabLink key={m.id} label={m?.displayName || m.name} m={m} />);
    }

    return acc;
  }, []);

  if (tabs.length <= 1) return null;

  if (mobile)
    return <BottomNavigation>{tabs.map((tab) => tab)}</BottomNavigation>;

  return (
    <div className="overflow-x-auto mx-4 rounded-lg lg:mx-0">
      <div className="hidden md:flex pb-1 pt-1 text-center text-text-primary flex-none relative z-10 lg:justify-end">
        {tabs.map((tab) => (
          <>{tab}</>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

interface LinkProps {
  label: string;
  moduleVariant?: "admin" | "";
  m: Module;
}

export const TabLink = ({ label, moduleVariant = "", m }: LinkProps) => {
  const { module, isBulk, schoolId, mobile } = useContainer(AppContext);
  const router = useRouter();

  const active =
    module.type === "flex"
      ? moduleVariant === "admin"
        ? m.id === module.id && isBulk
        : m.id === module.id && !isBulk
      : m.id === module.id;

  const confirm = useBasketPreventClick({ disabled: active });
  const handleClick = async () => {
    console.log("before");
    await confirm();

    console.log("done");

    const url = constructNewUrl(schoolId, m.id, {
      isBulk: moduleVariant === "admin",
    });
    router.push(url);
  };

  const iconKey = m?.displayIcon || moduleDefaultIcons[m.type];

  return (
    <button
      onClick={handleClick}
      id={`navbar_${m.id}`}
      className={`focus:no-underline items-center justify-center flex ${
        mobile
          ? "flex-col flex-1 w-0 px-0"
          : "rounded-lg mr-1 group relative overflow-hidden px-4"
      } transition-colors py-2 whitespace-nowrap font-medium ${
        active ? "text-primary-dark" : "text-text-primary"
      }`}
    >
      {/* Background */}
      {!mobile && (
        <div
          className={`z-0 ${
            active ? "text-primary-main" : "text-background-secondary"
          }`}
        >
          <div
            className={`${
              active ? "group-hover:opacity-10" : "group-hover:opacity-100"
            } opacity-0 transition-opacity bg-current absolute inset-0 rounded-lg overflow-hidden`}
          />
        </div>
      )}
      <div
        className={`relative flex items-center ${
          mobile ? "flex-col flex-1 w-full" : "mr-1"
        }`}
      >
        {isArray(iconKey) ? (
          <FontAwesomeIcon icon={iconKey} />
        ) : (
          getTabIcons(mobile ? "regular" : "small", "currentColor").find(
            (icon) => icon.key === iconKey
          )?.icon
        )}
        <span
          className={`${
            mobile ? "mt-1 w-full overflow-hidden" : "ml-2"
          } text-sm`}
          style={{ fontSize: mobile && 11 }}
        >
          {label}
        </span>
      </div>
    </button>
  );
};
