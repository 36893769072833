import { faCheck, faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@kanpla/system";
import { useTranslation } from "react-i18next";
import React from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";
import { animated, useSpring } from "react-spring";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  children: any;
  hasOrdered: boolean;
  numberOfItems: number;
  hidden?: boolean;
}

const ConfirmationBox = ({
  children,
  hasOrdered,
  numberOfItems,
  hidden = false,
}: Props) => {
  const { t } = useTranslation(["translation", "components"]);
  const { isSaving, setPulseCounter, modules, school, module } =
    useContainer(AppContext);
  const { width: screenWidth } = useWindowSize();

  /** Check how many available modules there are  */
  const availableTabTypes =
    module?.type === "subscription" ? ["subscription"] : ["mealplan", "flex"];

  const activeModules = modules?.filter(
    (module) =>
      module?.scope?.generatedSchoolIds?.includes(school?.id) &&
      availableTabTypes.includes(module.type)
  );

  // Track scroll
  const [ref, { entry }] = useIntersectionObserver({
    rootMargin: "-10% 0px -85% 0px",
  });
  const isVisible = true;

  const open = (hasOrdered || isSaving) && isVisible;
  const loading = isSaving || false;

  const isMobile = screenWidth < 768;
  const hasMultipleModules = activeModules.length > 1;

  const props = useSpring({
    transform: open ? "translate(-50%,38%)" : "translate(-50%,300%)",
    // opacity: open ? 1 : 0,
    pointerEvents: open ? "all" : "none",
    config: { mass: 1, tension: 500, friction: 150 },
  });

  const showOrder = () => {
    setPulseCounter((count) => count + 1);
    if (typeof window === "undefined") return;

    let scrollContainer: Element = document.querySelector("html");

    // @ts-ignore
    if (window.isRenderedInWebView) {
      // window.alert(document.querySelector("body").scrollTop);
      document.querySelector("body").scroll(0, 0);

      // document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
      // document.body.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (scrollContainer)
      scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <>
      <div ref={ref}>{children}</div>
      {!hidden && (
        <animated.button
          onClick={showOrder}
          className={`fixed bottom-0 w-auto mb-2 lg:mb-8 text-primary-contrast rounded-full shadow-2xl px-6 ${
            isMobile && hasMultipleModules ? "pb-4" : "pb-12"
          } pt-4 lg:py-4 whitespace-nowrap select-none flex items-center justify-center z-20 bg-primary-main`}
          style={{
            left: "50%",
            ...(props as any),
            //   transformOrigin: 'center center',
            // transform: 'translateX(-50%)',
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            bottom: isMobile
              ? hasMultipleModules
                ? "calc(6.6rem + env(safe-area-inset-bottom))"
                : "env(safe-area-inset-bottom)"
              : "0px",
          }}
        >
          {loading && (
            <>
              <FontAwesomeIcon
                icon={faCircleNotch}
                style={{ fontSize: "1.2em" }}
                className="text-primary-contrast animate-spin mr-3 z-max"
              />
              {t("translation:order-processed")}
            </>
          )}
          {!loading && (
            <>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ fontSize: "1.2em" }}
                className="mr-3"
              />
              {t("components:order-confirmed", { value: numberOfItems })}
            </>
          )}
        </animated.button>
      )}
    </>
  );
};

export default ConfirmationBox;
