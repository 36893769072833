import { Timestamp } from "@kanpla/types";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  defaultDate: Timestamp;
  holidayDesc?: {
    title: string;
    text: string;
    image?: string;
  };
}

const CanteenClosed = ({ defaultDate, holidayDesc }: Props) => {
  const { t } = useTranslation(["translation", "components"]);
  return (
    <section className="max-w-screen-xl mx-auto pt-20 md:pt-32 lg:pt-48 pb-12 text-center">
      {isEmpty(holidayDesc) && (
        <h2 className="h600 font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
          {t("components:canteen-closed")}
        </h2>
      )}
      {holidayDesc && !isEmpty(holidayDesc) && holidayDesc?.title && (
        <>
          <h2 className="h600 font-semibold text-xl md:text-2xl lg:text-3xl mb-2 text-text-secondary">
            {holidayDesc?.title || t("components:canteen-closed")}
          </h2>
          {holidayDesc?.text && (
            <p className="text-text-secondary max-w-prose mb-4 mx-auto">
              {holidayDesc?.text}
            </p>
          )}
        </>
      )}

      {/* <p className="text-text-secondary">
        {defaultDate ? (
          <>
            {t("components:first-available-day")}{" "}
            <UIJumpLink defaultDate={defaultDate} />
          </>
        ) : (
          t("translation:there-are-no-meal-plans")
        )}
      </p> */}
      {/* <p className="text-text-secondary">
        Første tilgængelige dag er <UIJumpLink defaultDate={defaultDate} />
      </p> */}
      <img
        src={`/images/${holidayDesc?.image || "no_mealplan"}.svg`}
        alt=""
        className="w-full mx-auto mt-8 md:mt-6 lg:-mt-20 pointer-events-none"
      />
    </section>
  );
};

export default CanteenClosed;
