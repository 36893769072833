import {
  CombinedOfferItem,
  CustomOrderContent,
  OrderInfo,
  OrderOrder,
} from "@kanpla/types";
import { Product } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import { AppContext } from "../contextProvider";

interface Props {
  availableChanges: number;
  lastOrder: OrderOrder; //  For comparing soft deadline changes
  item: CombinedOfferItem;
}

const MenuModule = ({ item }: Props) => {
  const { school, customBranding, week, dayIndex } = useContainer(AppContext);
  const { module, order, submit, orderInfo, mealOptions } =
    useContainer(FlexContext);

  const { color = "#718096", productId } = item;

  const onPurchase = async (
    product: CombinedOfferItem,
    data: CustomOrderContent
  ) => {
    const newOrder: OrderOrder = {
      [productId]: {
        amount: 1,
        price: product.price,
        name: product.name,
        config: [
          {
            name: product.name,
            amount: 1,
            options: data.optionChoices,
          },
        ],
      },
    };

    const newInfo: OrderInfo = {};

    await submit(newOrder, newInfo);
  };

  return (
    <Product
      customBranding={customBranding}
      customClassName="w-full"
      product={item}
      previewProps={{
        view: "menu",
      }}
      order={order}
      onPurchase={onPurchase}
      mealOptions={mealOptions}
      module={module}
      orderInfo={orderInfo}
      schoolId={school.id}
      hideAmount={true}
      noImage={true}
      currentDayTimestamp={week?.[dayIndex]?.seconds}
    />
  );
};

export default MenuModule;
