import { calculateOrderTotal } from "@kanpla/system";
import { OrderOrder } from "@kanpla/types";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  items: OrderOrder;
}

const ReceiptFooter = (props: Props) => {
  const { items } = props;
  const { t } = useTranslation(["translation"]);
  const orderTotalPrice = calculateOrderTotal(items);

  const showRipple = (e: React.MouseEvent<HTMLElement>) => {
    const btn = e.currentTarget;
    const x = e.pageX - btn.offsetLeft;
    const y = e.pageY - btn.offsetTop;

    const duration = 1200;
    let animationFrame, animationStart;

    const animationStep = (timestamp) => {
      if (!animationStart) {
        animationStart = timestamp;
      }

      const frame = timestamp - animationStart;
      if (frame < duration) {
        const easing = (frame / duration) * (2 - frame / duration);

        const circle = `circle at ${x}px ${y}px`;
        const color = "rgba(0, 0, 0, " + 0.1 * (1 - easing) + ")";
        const stop = 90 * easing + "%";

        btn.style.backgroundImage = `radial-gradient(${circle}, ${color} ${stop}, transparent ${stop})`;

        animationFrame = window.requestAnimationFrame(animationStep);
      } else {
        btn.style.backgroundImage = "none";
        window.cancelAnimationFrame(animationFrame);
      }
    };

    animationFrame = window.requestAnimationFrame(animationStep);
  };

  return (
    <div
      className="w-full px-12 py-8 pb-12 bg-gradient-to-b from-background-secondary to-background-primary border-t border-divider-main flex justify-between items-center cursor-pointer"
      onClick={showRipple}
    >
      <span className="text-text-primary text-lg">
        {t("translation:total")}:{" "}
      </span>
      <span className="h700 text-text-primary">
        {t("translation:amount-with-value", { value: orderTotalPrice })}
      </span>
    </div>
  );
};

export default ReceiptFooter;
