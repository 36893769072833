import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { NewLogo } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import CardsManage from "../modals/payment/CardsManage";
import NewPayment from "../modals/payment/index";
import { Button as ButtonMenu } from "./userInfo";

const Credit = () => {
  const { t, i18n } = useTranslation(["translation", "settings"]);
  const [open, setOpen] = useState(false);
  const { customBranding } = useContainer(AppContext);

  const {
    balance,
    modules,
    card,
    isPosone,
    posonePartnerData,
    posoneBalance,
    posoneBalanceLoading,
  } = useContainer(AppContext);
  const [manageOpen, setManageOpen] = useState(false);

  const hasCredit = modules.filter(
    (m) =>
      m.paymentMethod === "credit" &&
      !m.plugins?.kanplaGo?.active &&
      !m.plugins?.payPerOrder?.active &&
      m.type === "mealplan"
  );

  const tankUpCredit = hasCredit?.length;
  const showCredit = balance > 0 || tankUpCredit;

  // An option to edit your cards, even if you have no module with credit
  if (card && !showCredit)
    return (
      <div>
        <h2 className="h500 pb-2 border-b">{t("translation:payment")}</h2>
        <ButtonMenu
          label={t("settings:saved-debit-cards")}
          onClick={() => setManageOpen(true)}
        />
        <CardsManage open={manageOpen} setOpen={setManageOpen} />
      </div>
    );

  if (!showCredit) return null;

  return (
    <div>
      <h2 className="h500 pb-2">{t("translation:balance")}</h2>
      <div className="bg-gradient-to-b from-primary-main to-primary-dark rounded-lg px-12 py-12 text-background-primary flex justify-between items-center relative shadow-md">
        <p
          className={`text-2xl font-medium ${
            isPosone && posoneBalanceLoading ? "animate-pulse" : ""
          } z-10 relative`}
        >
          {priceFormatter(isPosone ? posoneBalance : balance, {
            language: i18n.language,
          })}
        </p>
        {tankUpCredit ? (
          <Button
            onClick={() => !isPosone && setOpen(true)}
            type="primary"
            href={
              isPosone &&
              `//${
                posonePartnerData?.prefix || customBranding?.url
              }.smartbetaling.dk/#addfunds`
            }
            target={isPosone && "_blank"}
            className="rounded-full text-primary-dark bg-primary-main m-3 px-5 h-12 flex justify-center items-center hover:bg-background-secondary focus:bg-background-secondary focus:shadow-lg pb-0"
            style={{ paddingTop: "0.5rem !important" }}
          >
            <FontAwesomeIcon icon={faPlus} size="1x" />
            <span className="h300 ml-2">{t("settings:refuel")}</span>
          </Button>
        ) : null}

        <div
          className="text-primary-light absolute bottom-0 left-0 my-2 mx-3 opacity-25 pointer-events-none z-0"
          style={{ transform: "scale(.7)", transformOrigin: "left bottom" }}
        >
          <NewLogo monotone />
        </div>
      </div>

      {!isPosone && <NewPayment open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Credit;
