import React from "react";

interface Props {
  subtitle?: string;
}

const Subtitle = ({ subtitle }: Props) => {
  if (!subtitle) return <span></span>;

  return <p className="text-text-secondary text-sm">{subtitle}</p>;
};

export default Subtitle;
