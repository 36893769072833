import { useState, useEffect } from "react";
import { Modal } from "@kanpla/ui";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { getChildDisplayName } from "@kanpla/system";
import { message, Form, Input } from "antd";
import { isEmpty } from "lodash";
import { useForm } from "antd/lib/form/Form";

const EmptyNamePopup = () => {
  const { child } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    if (!child) return;
    if (child.displayName && child.name && child.displayName !== " ") return;
    setOpen(true);
  }, [child?.name, child?.displayName]);

  const submit = async ({ name }) => {
    setLoading(true);

    try {
      if (!name) throw new Error("Udfulyd navnet");
      await (child.ref || child.child_ref).update({
        name,
        displayName: getChildDisplayName(name),
      });
      message.success(`Oplysninger om din bruger er gemt`);
      setLoading(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
      message.error(e.message);
      setLoading(false);
    }
  };

  if (!child || isEmpty(child)) return null;

  return (
    <Modal
      open={open}
      setOpen={() => {
        message.info(`Tilføj venligst oplysninger om din bruger`);
      }}
      title={"Manglende navn på brugeren"}
      subtitle={`Det ser ud at din bruger ${
        child?.school_name ? `på ${child.school_name}` : ""
      } mangler et navn. Angiv venligst dit navn.`}
      actions={[
        {
          label: "Gem",
          type: "primary",
          onClick: () => form.submit(),
          loading,
        },
      ]}
    >
      <Form form={form} onFinish={submit}>
        <Form.Item
          name="name"
          rules={[{ required: true, type: "string", message: "Angiv navnet" }]}
        >
          <Input placeholder="Angiv navn" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmptyNamePopup;
