import { Portal } from "react-portal";
import { LottieWalkingAvocado } from "@kanpla/ui";
import { Space, Button } from "antd";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { useLocalstorage } from "rooks";
import { useTranslation } from "react-i18next";

const SkipQueueBanner = () => {
  const { t } = useTranslation(["mealplan2"]);
  const [skipQueueBannerHidden, setSkipQueueBannerHidden] = useLocalstorage(
    "skip-queue-banner-hidden",
    false
  );

  const [hideForNow, setHideForNow] = useState(false);

  if (skipQueueBannerHidden || hideForNow) return null;

  return (
    <Portal>
      <div
        className="fixed inset-0 bg-primary-main p-10 flex flex-col items-center justify-center overflow-y-auto"
        style={{ zIndex: 99999999 }}
      >
        <h2 className="h800 font-black text-primary-contrast uppercase leading-tight text-center">
          {t("mealplan2:skip-queue")}
        </h2>
        <p className="text-center mt-2">{t("mealplan2:just-take-the-food")}</p>

        <Lottie animationData={LottieWalkingAvocado} className="-my-4" />

        <Space direction="vertical" align="center" className="w-full">
          <Button
            title={t("mealplan2:show-receipt")}
            onClick={() => setHideForNow(true)}
          />
          <div className="my-4 flex justify-center">
            <Button
              type="primary"
              className="text-center mx-auto"
              onClick={() => setSkipQueueBannerHidden(true)}
            >
              {t("mealplan2:do-not-show-this-again")}
            </Button>
          </div>
        </Space>
      </div>
    </Portal>
  );
};

export default SkipQueueBanner;
