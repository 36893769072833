import {
  faCreditCard,
  faEdit,
  faExchangeAlt,
  faTimes,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldValue } from "@kanpla/system";
import { SubscriptionPeriod, SubscriptionProduct } from "@kanpla/types";
import { Image } from "@kanpla/ui";
import { Button, Dropdown, Menu, message, Space } from "antd";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { SubscriptionContext } from "../.";
import { useConfirmation } from "../../confirmProvider";
import ChangeCard from "./ChangeCard";
import ChooseProduct from "./ChooseProduct";

const Item = ({ subscription }) => {
  const { t } = useTranslation(["subscription", "translation"]);
  const confirm = useConfirmation();
  const { name, autoRenew, renewedToValid } = subscription;
  const [changingCardOpen, setChangingCardOpen] = useState(false);

  // Data
  const { periods } = useContext(SubscriptionContext);

  const [subsequentPeriod, setSubsequentPeriod] = useState<
    Partial<SubscriptionPeriod>
  >({});
  const subsequentProducts = subsequentPeriod.products;

  // Find next period
  useEffect(() => {
    const { type, to } = subscription;

    const subsequentPeriods = periods[type]
      ? periods[type]
          .filter((p) => {
            return p.from.seconds > to.seconds;
          })
          .sort((a, b) => a.from.seconds - b.from.seconds)
      : [];

    const nextPeriod = subsequentPeriods ? subsequentPeriods[0] : {};
    if (nextPeriod) setSubsequentPeriod(nextPeriod);
  }, [periods, subscription]);

  // Format data from subscription
  const startDate = subscription.startDate
    ? DateTime.fromSeconds(subscription.startDate.seconds).toFormat("D")
    : "—";
  const to = DateTime.fromSeconds(subscription.to.seconds).toFormat("D");

  const toggleAutoRenew = () => {
    confirm({
      title: autoRenew
        ? t("subscription:sure-you-want-to-unsubscribe", { name, to })
        : t("subscription:renew", { name, to }),
      children: (
        <p className="text-text-primary text-center mb-4">
          {t("subscription:can-change-preferences-later")}
        </p>
      ),
    }).then(() => {
      subscription.ref.update({
        autoRenew: !autoRenew,
      });

      message.success(
        t("subscription:message.success.subscription-terminated-or-activated", {
          value: autoRenew
            ? t("subscription:values.terminated")
            : t("subscription:values.activated"),
        })
      );
    });
  };

  const changeProdukt = async () => {
    const nextStart = DateTime.fromSeconds(
      subsequentPeriod.to.seconds
    ).toFormat("D");

    const selectedProduct = (await confirm({
      title: t("subscription:change-to"),
      children: (
        <ChooseProduct
          order={subscription}
          subsequentProducts={subsequentProducts}
          nextStart={nextStart}
        />
      ),
    })) as SubscriptionProduct;

    await subscription.ref.update({
      changingProductId:
        selectedProduct.id === subscription.productId
          ? FieldValue.delete()
          : selectedProduct.id,
    });

    message.success(
      t("subscription:message.success.subscription-product-changed")
    );
  };

  const changingProduct =
    autoRenew &&
    subscription.changingProductId &&
    subsequentProducts &&
    subsequentProducts.find((p) => p.id === subscription.changingProductId);

  // Hide if has been renewed
  if (renewedToValid) return null;

  const menu = (
    <Menu>
      {subsequentProducts && (
        <Menu.Item onClick={changeProdukt}>
          <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
          {t("subscription:change-product")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => setChangingCardOpen(true)}>
        <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
        {t("subscription:select-payment-card")}
      </Menu.Item>
      <Menu.Item onClick={toggleAutoRenew} danger>
        <FontAwesomeIcon icon={faTimes} className="mr-2" />
        {t("subscription:supervision")}
      </Menu.Item>
    </Menu>
  );

  // Hide if is over
  const isOver = subscription.to.seconds < new Date().getTime() / 1000;
  const actions = isOver ? (
    <Button size="small" disabled className="h300 ml-auto cursor-default mr-2">
      {t("subscription:ended")}
    </Button>
  ) : (
    <div className="ml-auto">
      {autoRenew && (
        <Dropdown overlay={menu} trigger={["click", "contextMenu"]}>
          <Button size="small">
            <Space>
              <FontAwesomeIcon icon={faEdit} />
              {t("translation:edit")}
            </Space>
          </Button>
        </Dropdown>
      )}

      {!autoRenew && (
        <Button
          onClick={toggleAutoRenew}
          className={`h300 ml-auto text-primary-dark mr-2`}
        >
          {t("subscription:redraw")}
        </Button>
      )}
    </div>
  );

  return (
    <div className="border-b py-3 flex flex-wrap justify-start items-center">
      <div className="h-16 w-16 rounded overflow-hidden">
        <Image
          src={subscription.photo}
          alt={subscription.name}
          className="w-full h-full object-cover object-center"
        />
      </div>

      <div className="p-3">
        <p className="font-bold text-text-primary">{subscription.name}</p>
        <p className="text-sm text-text-secondary">
          {startDate} -{" "}
          <span className={`${!autoRenew && "text-danger-main"}`}>{to}</span>{" "}
          {changingProduct &&
            t("subscription:changed-to", { value: changingProduct.name })}
        </p>
      </div>

      {actions}

      <ChangeCard
        open={changingCardOpen}
        setOpen={setChangingCardOpen}
        subscription={subscription}
      />
    </div>
  );
};

export default Item;
