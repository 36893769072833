import {
  getBasePrice,
  getDefaultDateSeconds,
  getTaxPercentage,
} from "@kanpla/system";
import { CombinedOfferItem, Plugins } from "@kanpla/types";
import { Product as ProductTemplate, VariantsContent } from "@kanpla/ui";
import moment from "moment";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import DeadlineInfo from "./DeadlineInfo";
import OrderForAmountOfPeople from "./meeting/OrderForAmountOfPeople";
import useOnPurchase from "./useOnPurchase";

interface Props {
  product: CombinedOfferItem;
  commonDeadline?: number | boolean;
  pastDeadline?: boolean;
  /** Makes the products not grayscale even after deadline */
  overrideVisible?: boolean;
  /** Shows also products after deadline */
  showChildrenAnyway?: boolean;
  hidePriceInLabel?: boolean;
}

const Product = (props: Props) => {
  const {
    product,
    commonDeadline = true,
    pastDeadline = false,
    overrideVisible,
    showChildrenAnyway,
    hidePriceInLabel,
  } = props;
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    defaultReference,
    user,
    child,
    school,
    schoolId,
    activePlugins,
    dateSeconds,
    customBranding,
    module,
    dayIndex,
    week,
  } = useContainer(AppContext);
  const {
    orderInfo,
    order,
    hasRequiredProduct,
    defaultDate,
    mealOptions,
    productId: openProductId,
    setProductId,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  } = useContainer(MealplanContext);

  const currentDayTimestamp = week[dayIndex]?.seconds;

  const { onPurchase, nextOrder, setNextOrder, orderButtonDisabled } =
    useOnPurchase({ product });

  if (!product) return null;
  const { productId } = product;

  const plugins = module.plugins || ({} as Plugins.Array);

  const isDish = product?.category === "" || false;

  let stock = product.dates?.[dateSeconds]?.stock;
  const disabled = (pastDeadline || stock <= 0) && !overrideVisible;

  /** Signup offer plugin */
  const isSignupOfferProduct =
    activePlugins.signupOffer && plugins?.signupOffer?.productId === productId;
  const signupOfferExpired =
    isSignupOfferProduct && user?.pluginData?.signupOffer?.taken === true;
  if (signupOfferExpired) return null;
  if (isSignupOfferProduct && stock > 1) stock = 1;

  // Add base price for (before) taxes
  product.basePrice = getBasePrice(product, module, school, child);

  // Handle taxes
  if (
    activePlugins.taxSettings &&
    module?.plugins?.taxSettings?.productPrice !== "includesAndKeeps"
  ) {
    product.taxPercentage = getTaxPercentage(module, school, child);
  }

  const productDeadline =
    typeof product.individualDeadline === "number"
      ? product.individualDeadline
      : deadline;
  const productDefaultDateSeconds = getDefaultDateSeconds({
    deadline: productDeadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });
  const nextAvailableDate = moment.unix(productDefaultDateSeconds);

  return (
    <ProductTemplate
      disabled={disabled}
      open={openProductId === productId}
      setOpen={(open) =>
        open ? setProductId(productId) : setProductId(undefined)
      }
      isBig={isDish || product?.isLeftover}
      product={product}
      stock={stock}
      onPurchase={onPurchase}
      purchaseLabel={t("translation:add-to-basket")}
      fromAdmin={true}
      schoolId={schoolId}
      plugins={plugins}
      hasRequiredProduct={hasRequiredProduct}
      order={order}
      orderInfo={orderInfo}
      mealOptions={mealOptions}
      hideAmount={activePlugins.advancedVariants}
      module={module}
      maxAmount={isSignupOfferProduct ? 1 : stock}
      defaultReference={defaultReference}
      customBranding={customBranding}
      orderButtonDisabled={orderButtonDisabled}
      hidePriceInLabel={hidePriceInLabel}
      previewProps={{
        view: module?.flow === "meeting" ? "table" : "grid",
        children: (
          <>
            {isSignupOfferProduct && (
              <div className="absolute whitespace-nowrap -ml-2 flex flex-col items-start -mt-2">
                <div className="text-xs text-background-primary pb-1 bg-primary-dark font-medium px-2 pt-1 rounded-lg bg-opacity-60">
                  {plugins?.signupOffer?.promoText ||
                    t("translation:registration-offer")}
                </div>
              </div>
            )}
            {/* Hide for signup offers (too much info) */}
            {!isSignupOfferProduct && (
              <DeadlineInfo
                individualDeadline={product.individualDeadline}
                hidden={commonDeadline}
                showChildrenAnyway={showChildrenAnyway}
                nextAvailableDate={nextAvailableDate}
                outOfStock={stock < 1}
                isPastDeadline={pastDeadline}
                hideNextDay={product?.isLeftover || false}
              />
            )}
          </>
        ),
      }}
      customInputs={
        activePlugins.orderForAmountOfPeople ? (
          <OrderForAmountOfPeople />
        ) : activePlugins.advancedVariants ? (
          <VariantsContent
            product={product}
            options={mealOptions}
            order={nextOrder}
            setOrder={setNextOrder}
          />
        ) : undefined
      }
      currentDayTimestamp={currentDayTimestamp}
    />
  );
};

export default Product;
