import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import ChangeEmail from "../modals/changeEmail";
import ChangeLanguage from "../modals/ChangeLanguage";
import ChangePassword from "../modals/changePassword";
import Notifications from "../modals/Notifications";
import SuperadminSwitch from "../SuperadminSwitch";

const UserInfo = () => {
  const { t } = useTranslation(["translation", "settings"]);
  const router = useRouter();
  const {
    setChild,
    setChildId,
    setMenuOpen,
    isPosone,
    user,
    auth,
    setHistoryOpen,
  } = useContainer(AppContext);
  const [emailOpen, setEmailOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  // const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const providerData = auth?.user?.providerData?.[0];

  return (
    <div>
      {!isPosone && (
        <div className="pb-8">
          <h2 className="h500 border-b-2 border-divider-main pb-2">
            {t("settings:orders")}
          </h2>
          <Button
            label={t("settings:history")}
            onClick={() => setHistoryOpen(true)}
            dataCy="history-btn"
          />
        </div>
      )}

      <h2 className="h500 border-b-2 border-divider-main pb-2">
        {t("settings:account-info")}
      </h2>

      {!isPosone && (
        <Button
          label={t("settings:change-email", {
            value: auth.user ? " (" + user?.email + ")" : " her",
          })}
          onClick={() => setEmailOpen(true)}
          disabled={providerData?.providerId === "microsoft.com"}
          dataCy="change-email"
        />
      )}
      {!isPosone && (
        <Button
          label={t("translation:change-password")}
          onClick={() => setPasswordOpen(true)}
          dataCy="change-password"
        />
      )}

      <Button
        label={t("translation:mail-preferences")}
        onClick={() => setNotificationsOpen(true)}
      />
      <Button
        label={t("settings:language-preference")}
        onClick={() => setLanguageOpen(true)}
      />
      <Button
        id="action-logOut"
        label={t("translation:log-out")}
        onClick={async () => {
          try {
            setChild(null);
            setChildId(null);
            setMenuOpen(false);
            await auth.signOut();
            router.push("/login");
          } catch (e) {
            console.error(e);
            message.error(
              e?.message || t("settings:message.error.problem-logging-out")
            );
          }
        }}
        icon={faSignOutAlt}
        // gray
        // className="text-text-secondary"
      />

      {/* Modals */}
      <ChangeEmail open={emailOpen} setOpen={setEmailOpen} />
      <ChangePassword open={passwordOpen} setOpen={setPasswordOpen} />
      <Notifications open={notificationsOpen} setOpen={setNotificationsOpen} />
      <ChangeLanguage open={languageOpen} setOpen={setLanguageOpen} />
      <SuperadminSwitch />
    </div>
  );
};

export default UserInfo;

export const Button = ({
  onClick = () => null,
  label,
  className = "",
  icon = null,
  id = null,
  disabled = false,
  dataCy = "",
}) => {
  return (
    <button
      disabled={disabled}
      id={id && id}
      className={`py-4 px-4 block w-full text-left border-b transition duration-200 bg-background-primary border-divider-main ${
        disabled
          ? "disabled:cursor-not-allowed text-text-disabled"
          : "hover:bg-background-secondary"
      } ${className}`}
      onClick={onClick}
      data-cy={dataCy}
    >
      {label}
      {icon && <FontAwesomeIcon icon={icon} size="sm" className="ml-3" />}
    </button>
  );
};
