import { Timestamp } from "@kanpla/types";
import { Header, isDateStringPattern } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import UIJumpLink from "./JumpLink";
import KanplaGoIntro from "./modals/KanplaGoIntro";

interface Props {
  /** additional styles (tailwind classes) */
  className?: string;
  deadlineFormatted?: string;
  /** If yes, showing soft deadline with maximum changes */
  inSoftMode?: boolean;
  /** To display the maximum number of changes allowed (softMode) */
  softMaxAmount?: number;
  /** Display different text if already has an order that day */
  hasOrdered?: boolean;
  /** To explicitly show that there is not order */
  emptyOrder?: boolean;
  kanplaGo?: boolean;
  /** To change header when after deadline */
  pastDate?: boolean;
  /** Shows the header even is it's past deadline */
  showPastDate?: boolean;
  /** used for jumpLink */
  defaultDate?: Timestamp;
  /** Center the subtitle */
  centerSubtitle?: boolean;
}

const UIHeader = (props: Props) => {
  const {
    deadlineFormatted = "",
    inSoftMode = false,
    softMaxAmount,
    hasOrdered = false,
    emptyOrder = false,
    kanplaGo = false,
    pastDate = false,
    showPastDate = false,
    centerSubtitle = false,
    defaultDate = null,
  } = props;
  const { t } = useTranslation(["translation", "components"]);
  const { displayDate, module } = useContainer(AppContext);
  const [kanplaGoIntroOpen, setKanplaGoIntroOpen] = useState(false);

  const standardTitle = t("translation:order-to-at", {
    word: !isDateStringPattern(displayDate)
      ? t("translation:values.more-ordering")
      : t("translation:values.order-left"),
    displayDate,
  });

  const texts = module?.["text"];

  const hideSkipQueue =
    module?.plugins?.kanplaGo && module.plugins.kanplaGo.hideSkipQueue;

  if (kanplaGo)
    return (
      <Header
        title={
          texts?.["kanplaGoHeader/title"] ||
          t("translation:order-in-the-canteen")
        }
        subtitle={
          <>
            {texts?.["kanplaGoHeader/subtitle"] ||
              t("translation:buy-food-single-swipe")}{" "}
            <Button type="text" onClick={() => setKanplaGoIntroOpen(true)}>
              {texts?.["kanplaGoHeader/readMoreButton"] ||
                t("translation:read-more")}
            </Button>
            <KanplaGoIntro
              open={kanplaGoIntroOpen}
              setOpen={setKanplaGoIntroOpen}
              hideSkipQueue={hideSkipQueue}
              module={module}
            />
          </>
        }
        className="mx-auto w-full max-w-full"
        centerSubtitle={centerSubtitle}
      />
    );

  // Show heading when it's after deadline
  if (pastDate && showPastDate)
    return (
      <Header
        title={t("components:too-late-to-order")}
        subtitle={
          <>
            {t("components:next-available-day")}{" "}
            <UIJumpLink defaultDate={defaultDate} />
          </>
        }
        className="mx-auto w-full max-w-full"
        centerSubtitle={centerSubtitle}
      />
    );

  if (pastDate) {
    return null;
  }

  if (emptyOrder) {
    <Header
      title={t("translation:no-more-ordering-or-order-left", {
        word: !isDateStringPattern(displayDate)
          ? t("translation:values.more-ordering")
          : t("translation:values.order-left"),
        displayDate,
      })}
      subtitle={t("translation:can-change-order-until", {
        value: deadlineFormatted,
      })}
      className="mx-auto w-full max-w-full"
      centerSubtitle={centerSubtitle}
    />;
  }

  if (hasOrdered)
    return (
      <Header
        title={
          <span className="hidden lg:block">
            {t("components:addition-to", { value: displayDate })}
          </span>
        }
        subtitle={
          <h2 className="text-text-secondary relative text-sm">
            <span className="hidden lg:inline-block">
              {t("components:order-until", { value: deadlineFormatted })}
            </span>
            <span className="inline-block lg:hidden">
              {t("components:purchase-more-until", {
                value: deadlineFormatted,
              })}
            </span>
            <svg
              width="15"
              viewBox="0 0 24 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline-block ml-2 -mb-3 -mr-2"
            >
              <path
                d="M0.96034 0.741312C0.685068 0.763217 0.479673 1.00413 0.501577 1.2794C0.523481 1.55467 0.764391 1.76007 1.03966 1.73816L0.96034 0.741312ZM19.4142 28.3535C19.6094 28.5488 19.926 28.5488 20.1213 28.3536L23.3033 25.1717C23.4986 24.9764 23.4986 24.6598 23.3034 24.4646C23.1081 24.2693 22.7915 24.2693 22.5963 24.4645L19.7678 27.2929L16.9394 24.4644C16.7442 24.2691 16.4276 24.2691 16.2323 24.4644C16.037 24.6596 16.037 24.9762 16.2323 25.1715L19.4142 28.3535ZM1.03966 1.73816C8.65119 1.13248 13.5407 1.7196 16.6163 3.07633C18.1446 3.75052 19.2173 4.61138 19.9601 5.60637C20.7037 6.6024 21.1357 7.75838 21.3442 9.04997C21.765 11.6569 21.2686 14.7494 20.6394 18.053C20.0173 21.3196 19.2678 24.7818 19.2677 28L20.2677 28C20.2678 24.9007 20.9904 21.5553 21.6218 18.2401C22.2461 14.9618 22.7847 11.6982 22.3314 8.89061C22.1029 7.47495 21.6207 6.15908 20.7614 5.00814C19.9014 3.85616 18.6828 2.89493 17.0199 2.16139C13.713 0.702652 8.62059 0.13175 0.96034 0.741312L1.03966 1.73816Z"
                fill="#8792A2"
              />
            </svg>
          </h2>
        }
        centerSubtitle={centerSubtitle}
      ></Header>
    );

  if (inSoftMode) {
    return (
      <Header
        title={standardTitle}
        subtitle={t("components:modal.subtitles.you-can-change", {
          softMaxAmount,
          deadlineFormatted,
        })}
        className="mx-auto w-full max-w-full"
        centerSubtitle={centerSubtitle}
      />
    );
  }

  return (
    <Header
      title={standardTitle}
      subtitle={t("components:order-until", { value: deadlineFormatted })}
      className="mx-auto w-full max-w-full"
      centerSubtitle={centerSubtitle}
    />
  );
};

export default UIHeader;
