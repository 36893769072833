import { FieldValue, rules, Timestamp } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { DrawerOrModal } from "@kanpla/ui";
import { Form, Input, message } from "antd";
import { constructNewUrl } from "apps/frontend/lib/constructNewUrl";
import { Trans, useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  child: Child;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteKid = ({ child, open, setOpen }: Props) => {
  const { t } = useTranslation(["translation", "modals"]);
  const { setChild, userId, children, modules } = useContainer(AppContext);
  const [name, setName] = useState<string>(null);
  const router = useRouter();

  const valid = name === child.name.trim();

  const handleSubmit = async () => {
    if (!valid) return;

    const otherChildren = children.filter((c) => c.id !== child.id);
    const newChild = otherChildren?.[0];

    if (newChild) {
      setChild(newChild);
    } else {
      setChild(null);
    }

    (child.child_ref || child.ref)
      .update({
        kanplaId: FieldValue.delete(),
        cardHEX: FieldValue.delete(),
        deleted: true,
        deletedAt: Timestamp.now().seconds,
        deletedBy: userId,
      })
      .then(() => {
        message.info(
          t("translation:was-deleted", { value: child.name.trim() })
        );
        setOpen(false);

        if (otherChildren.length === 0) {
          router.push("/app");

          return;
        }

        const newModuleId = modules.find((m) =>
          m?.scope?.generatedSchoolIds?.includes(newChild.schoolId)
        )?.id;
        const url = constructNewUrl(newChild.schoolId, newModuleId);
        router.push(url);
      })
      .catch(function (error) {
        console.error(error);
        message.error(error.message);
      });
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:delete-dynamic", { value: child.name })}
      actions={[
        {
          label: t("translation:remove"),
          onClick: () => handleSubmit(),
          danger: true,
          disabled: !valid,
        },
      ]}
    >
      <Form layout="vertical" autoComplete="off" requiredMark={false}>
        <p className="text-text-primary text-center mb-6">
          <Trans t={t} i18nKey="modals:type-to-confirm">
            skriv{" "}
            <span className="font-semibold">
              {{ value: child.name.trim() }}
            </span>
            for at bekræfte
          </Trans>
        </p>
        <Form.Item
          name="child-name"
          label={t("translation:user-name")}
          className="mb-0"
          rules={[
            rules({
              rule: "required",
              ruleValue: true,
              message: t("translation:form.errors.required", {
                value: t("translation:values.child-name"),
              }),
            }),
          ]}
        >
          <Input
            id="name-input"
            placeholder={child.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="large"
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default DeleteKid;
