import React, { useEffect, useState } from "react";
import { ButtonAdd, DrawerOrModal, SelectorsInput } from "@kanpla/ui";
import { Child, School, SignupDomain } from "@kanpla/types";
import SelectSchool from "../forms/SelectSchool";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { isEmpty } from "lodash";
import {
  fetchCollection,
  fetchMultipleDocuments,
  db,
  callFunction,
  KanplaError,
} from "@kanpla/system";
import { useTranslation } from "react-i18next";
import { Divider, message } from "antd";
import ReactCodeInput from "react-code-input";

const NewSalesplace = () => {
  const { customBranding, partnerId, child, children } =
    useContainer(AppContext);

  const { t } = useTranslation(["modals", "translation", "signup-flow"]);

  const [school, setSchool] = useState<School>(null);
  const [schoolFromCanteenId, setSchoolFromCanteenId] = useState<School>(null);

  const [schools, setSchools] = useState<School[]>([]);
  const [open, setOpen] = useState(false);
  const [selectors, setSelectors] = useState<Child["selectors"]>({});

  const [code, setCode] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const salesplacesAlreadyAdded = children.map((child) => child.schoolId);

  const getCompanySchools = async () => {
    try {
      // 1. Get signup domains of the partner
      const signupDomains = await fetchCollection<SignupDomain>(
        db.collection("signupDomains").where("partnerId", "==", partnerId)
      );

      // 2. Find same domains (will be displayed in the school selection)
      const multipleDomain = signupDomains.find((domain, index) => {
        return (
          signupDomains.findIndex((dom) => dom.domain === domain.domain) !==
          index
        );
      });

      if (isEmpty(multipleDomain)) {
        setSchools([]);
        return;
      }

      const schoolsIds = signupDomains
        .filter((dom) => dom.domain === multipleDomain.domain)
        .flatMap((domain) => domain.schoolId)
        .filter((schoolId) => !salesplacesAlreadyAdded.includes(schoolId));

      if (isEmpty(schoolsIds)) {
        setSchools([]);
        return;
      }

      const getSchools = await fetchMultipleDocuments<School>(
        "schools",
        schoolsIds
      );

      setSchools(getSchools);
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      const targetSchool = schoolFromCanteenId ? schoolFromCanteenId : school;

      if (targetSchool?.selectors && isEmpty(selectors))
        throw new Error("Chose a selector!");

      await callFunction("salesplaces-createSalesplaceChild", {
        partnerId,
        newSchoolId: targetSchool?.id || targetSchool?.ref?.id,
        originalChildId: child.id,
        selectors,
      });

      setOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const submitCanteenCode = async () => {
    try {
      setLoading(true);
      if (!code)
        throw new KanplaError("kanpla/fill-canteen-id", "Fill canteen ID out!");

      const partnerId = customBranding?.partnerId || null;
      const ref = partnerId
        ? db
            .collection("schools")
            .where("canteenId", "==", parseInt(code))
            .where("partnerId", "==", partnerId)
        : db.collection("schools").where("canteenId", "==", parseInt(code));

      const allSchools = await fetchCollection<School>(ref, true);

      const targetSchool = allSchools?.[0];
      if (children.find((c) => c.schoolId === targetSchool?.id))
        throw new Error(t("modals:message.error.child-has-location-already"));

      if (targetSchool) {
        message.success(t("signup-flow:message.success.code-is-correct"));
        setSchoolFromCanteenId(targetSchool);
      } else {
        throw new KanplaError("kanpla/wrong-canteen-id", "Wrong canteen ID");
      }
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSchool(null);
    setSchoolFromCanteenId(null);
    setCode("");
  }, [open]);

  useEffect(() => {
    if (code.length < 4) return;
    submitCanteenCode();
  }, [code]);

  useEffect(() => {
    getCompanySchools();
  }, [children.length]);

  return (
    <>
      <ButtonAdd
        label={t("modals:add-another-location") as string}
        className="w-full mt-2"
        onClick={() => setOpen(true)}
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("modals:add-another-location") as string}
        actions={[
          {
            label: t("translation:confirm"),
            onClick: submit,
            type: "primary",
            loading,
            disabled: !school && !schoolFromCanteenId,
          },
        ]}
      >
        {!isEmpty(schools) && (
          <>
            <label htmlFor="school-input">
              <label className="form-label text-text-secondary mb-1">
                {t("modals:add-salesplaces-from-email")}
              </label>
              <div className="text-left mt-2 mb-6">
                <SelectSchool
                  school={school}
                  schools={schools}
                  setSchool={setSchool}
                  company={!isEmpty(customBranding?.logo)}
                />
              </div>
            </label>

            <SelectorsInput
              selectors={selectors}
              setSelectors={setSelectors}
              school={school}
            />

            <div className="relative my-2">
              <Divider />
              <div className="absolute -top-3 left-1/2 -ml-6 text-sm p-1 px-4 bg-background-primary text-text-secondary">
                {t("translation:or")}
              </div>
            </div>
          </>
        )}

        <label className="form-label text-text-secondary mb-1">
          {t("modals:add-salesplaces-from-canteen-id")}
        </label>
        <div className="flex justify-center mb-4">
          <ReactCodeInput
            type="number"
            className="w-full grid grid-cols-4"
            inputStyle={{
              fontFamily: "inherit",
              padding: 12,
              border: "1px solid",
              borderRadius: 6,
              margin: 6,
              textAlign: "center",
              fontSize: 22,
              fontWeight: "600",
            }}
            style={{
              display: "grid !important",
            }}
            value={code}
            onChange={(newValue) => {
              setCode(newValue);
            }}
            fields={4}
            name="emailCode"
            inputMode="numeric"
          />
        </div>

        <SelectorsInput
          selectors={selectors}
          setSelectors={setSelectors}
          school={schoolFromCanteenId}
        />
      </DrawerOrModal>
    </>
  );
};

export default NewSalesplace;
